import React, { useState } from 'react';

// Modules

// App

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from '@mui/material';

export default function LogoPicker(props) {
  const [logo, setLogo] = useState(props.slideLogo ? props.slideLogo : '');
  const logos = props.logos ? props.logos : [''];
  const srcFolder = props.isLockup ? 'lockups' : 'logos';


  const onLogoSelected = _logo => {
    setLogo(_logo);
    props.onLogoSelected(_logo);
  }

  return (
    <div className='settings-wrap logos'>
      <h6 className='ss-subtitle pb-2'>{props.title}</h6>
      <Row>
        <Col>
          <div className='main-wrapper'>
            <Row className='m-0'>
              <Col className='px-0'>
                <div className='logo-container'
                // className='inner-wrapper'
                >
                  <div
                    key={`logo-col-nlg`}
                    className={`logo-wrap ${logo === '' ? 'selected' : ''} no-lg col-4`}>
                    <Button onClick={() => {
                      onLogoSelected('');
                    }}>
                      <span className='nlg'>{props.isLockup ? 'No lockup' : 'No logo'}</span>
                    </Button>
                  </div>
                  {logos.map((image, index) => {
                    const isSelected = image === logo ? true : false;
                    const hasImage = image !== '' ? true : false;
                    let buttonClass = isSelected ? 'selected' : '';
                    buttonClass += !hasImage ? ' no-lg' : '';

                    return (
                      <div
                        key={`logo-col-${index}`}
                        className={`logo-wrap ${buttonClass} col-4`}>
                        <Button onClick={() => {
                          onLogoSelected(image);
                        }}>
                          {image === '' ? (
                            <span className='nlg'>{props.isLockup ? 'No lockup' : 'No logo'}</span>
                          ) : (
                            <img style={{ height: '100%', width: '100%', objectFit: 'contain' }} alt={`lg-${image}`} key={`${index}-image`} src={`${props.themeDirectory}/dist/images/${srcFolder}/${image}`} />
                          )}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
