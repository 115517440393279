import React, { useState, useEffect } from "react";

// Modules
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// App
import { oAuthConfig } from "../../config";
import PageHeader from "../../partials/pageHeader";
import isLoggedIn from "../../core/isLoggedIn";
import getUser from "../../core/getUser";

// UI
import { Box, Button, Alert, CircularProgress } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { InfoOutlined } from "@mui/icons-material";
import { Row, Col } from "react-bootstrap";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default function Oauth(props) {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const oauth = oAuthConfig();

  useEffect(() => {
    isLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn) {
        fetchUser();
      } else {
        setLoading(false);
      }
    });
  }, []);

  const fetchUser = () => {
    getUser().then((data) => {
      if (data) {
        dispatch({ type: "SAVE_SESSION", payload: data });
        navigate("/");
      } else {
        dispatch({ type: "REMOVE_SESSION", payload: {} });
        setLoading(false);
      }
    });
  };

  const login = () => {
    let url = `${oauth.url}?response_type=code&client_id=${oauth.client_id}&redirect_uri=${oauth.redirect_uri}&scope=${oauth.scope}&state=${oauth.state}&xapp=true&xlogin=sso&xclient=${oauth.client_id}`;

    const parsed = decodeURIComponent(url);
    window.location.href = parsed;
  };

  const LoginButton = () => {
    return (
      <Box>
        <Button
          onClick={login}
          variant="contained"
          color="clientPrimary"
          sx={{ color: "#fff" }}
        >
          Login
          <CustomWidthTooltip
            title="This button will take you to an external webpage to log in. Once you've logged in successfully, you will be re-directed back to the app."
            arrow
          >
            <InfoOutlined sx={{ marginLeft: "5px" }} fontSize="14" />
          </CustomWidthTooltip>
        </Button>
      </Box>
    );
  };

  const Error = () => {
    return (
      <>
        {isError && (
          <>
            {errorMessage && (
              <Col xs="auto" className="mb-4">
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              </Col>
            )}
          </>
        )}
      </>
    );
  };

  const Loading = () => {
    return (
      <Row>
        <Col className="text-center">
          <CircularProgress size={20} />
        </Col>
      </Row>
    );
  };

  return (
    <>
      {props.type === "modal" ? (
        <div className="mt-3">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Row>
                <Col>{isError ? <Error /> : <LoginButton />}</Col>
              </Row>
            </>
          )}
        </div>
      ) : (
        <main className={`login screen d-flex flex-column align-items-center`}>
          <Row className={`g-0 ${props.type === "modal" ? "" : "w-50 mb-3"}`}>
            <PageHeader pageName="Log in" filters={false} />
          </Row>

          {isLoading ? (
            <Loading />
          ) : (
            <Row className={`g-0 ${props.type === "modal" ? "" : "w-50 mb-3"}`}>
              <Col>{isError ? <Error /> : <LoginButton />}</Col>
            </Row>
          )}
        </main>
      )}
    </>
  );
}
