import './index.css';
import { IconLink, IconUnlink } from '@codexteam/icons'
import { PopupLinkIcon, UnlinkIcon } from '../../../icons/icons';

export default class PopupLink {

  static get CSS() {
    return 'cdx-popup-link';
  };
  
  static get title(){
    return 'Link to a popup window'
  }

  constructor({ api, config }) {
    this.api = api;
    this.config = config;
    this.colors = config.options || '';

    this.button = null;
    this.input = null;
    this.range = null;
    this.selectedColor = null;
    this.tag = 'A';
    this.onKeyDown = this.onKeyDown.bind(this);
    this.existingHref = null;
    this.enableFocusOut = false;

    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive
    };
  }

  static get isInline() {
    return true;
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.classList.add(this.iconClasses.base);
    this.button.innerHTML = this.toolboxIcon;

    return this.button;
  }

  surround(range) {
    if (!range) {
      return;
    }

    let termWrapper = this.api.selection.findParentTag(this.tag, PopupLink.CSS) || this.api.selection.findParentTag(this.tag);

    if (termWrapper) {
      this.unwrap(termWrapper);
    } else {
      this.range = range;
      this.showActions(range);
    }
  }

  renderActions() {
    this.actionContainer = document.createElement('div');
    this.input = document.createElement('input');

    this.actionContainer.classList.add('hyperlink-input-container');
    this.input.classList.add('hyperlink-input');
    this.input.placeholder = "Add popup ID & choose colour"
    this.input.addEventListener('keydown', this.onKeyDown);
    this.input.addEventListener('focusin', () => { this.enableFocusOut = true; });

    this.actionContainer.appendChild(this.input);
    this.actionContainer.addEventListener('focusout', () => { if (this.input.value && this.enableFocusOut) { this.wrap(this.range);} });

    this.colorSelector = document.createElement('div');
    this.colorSelector.classList.add('hyperlink-color-selector');
    this.colorSelector.addEventListener('mouseover', () => { this.enableFocusOut = false; });
    this.colorSelector.addEventListener('mouseout', () => { this.enableFocusOut = true; });

    let nullBtn = document.createElement('button');
    nullBtn.classList.add('hyperlink-color-item-btn');
    nullBtn.classList.add('null-btn');
    nullBtn.classList.toggle('colorCircleActive', this.selectedColor === null);
    nullBtn.addEventListener('click', () => {
      this.selectedColor = null;
      this.toggleClass();
    });
    this.colorSelector.appendChild(nullBtn);

    // deepcode ignore ArrayMethodOnNonArray: <please specify a reason of ignoring this>
    this.colors.map(color => {
      const button = document.createElement('button');

      button.classList.add('hyperlink-color-item-btn')
      button.type = 'button';
      button.style.backgroundColor = color;

      this.colorSelector.appendChild(button);


      button.classList.toggle('colorCircleActive', color === this.selectedColor);
      this.colorSelector.appendChild(button);
      return button
    }).forEach((element, index, elements) => {
      element.addEventListener('click', () => {
        this.selectedColor = this.colors[index];
        if (this.input.value) {
          this.enableFocusOut = false;
          this.wrap(this.range, this.termTag ? false : true);
        }
        elements.forEach((el, i) => {
          el.classList.toggle('colorCircleActive', this.colors[i] === this.selectedColor);
          nullBtn.classList.toggle('colorCircleActive', this.selectedColor === null);
        });
      });
      element.addEventListener('mouseover', () => { this.enableFocusOut = false });
      element.addEventListener('mouseout', () => { this.enableFocusOut = true });
    });

    this.actionContainer.appendChild(this.colorSelector);
    this.actionContainer.hidden = this.termTag ? false : true;
    return this.actionContainer;
  }

  toggleClass() {
    let colorItems = this.colorSelector.querySelectorAll('.hyperlink-color-item-btn');
    if (colorItems?.length > 0) {
      colorItems[0].classList.toggle('colorCircleActive', this.selectedColor === null);
      for (let i = 1; i < colorItems.length; i++) {
        colorItems[i].classList.toggle('colorCircleActive', this.colors[i] === this.selectedColor);
      }
    }
  }

  showActions(range) {
    this.range = range;
    this.actionContainer.hidden = false;
  }

  hideActions() {
    this.actionContainer.hidden = true;
  }

  onKeyDown(event) {
    if (event.key === 'Enter') {
      this.wrap(this.range, true);
      event.stopPropagation();
      event.preventDefault();
    }
  }

  wrap(range, autoClose) {
    let hyperlink = this.termTag ? this.termTag : document.createElement(this.tag);

    hyperlink.classList.add(PopupLink.CSS);
    hyperlink.setAttribute('onclick', `event.preventDefault(); showModal('${this.input.value}')`);
    hyperlink.setAttribute('data-modal-id', `${this.input.value}`);
    if (this.selectedColor !== null) {
      hyperlink.style.color = this.selectedColor;
    }
    hyperlink.appendChild(range.extractContents());
    range.insertNode(hyperlink);

    this.api.selection.expandToTag(hyperlink);

    if (autoClose) {
      this.hideActions();
    }
  }

  unwrap(termWrapper) {
    this.api.selection.expandToTag(termWrapper);

    let sel = window.getSelection();
    let range = sel.getRangeAt(0);

    let unwrappedContent = range.extractContents();

    termWrapper.parentNode.removeChild(termWrapper);

    range.insertNode(unwrappedContent);

    sel.removeAllRanges();
    sel.addRange(range);
    this.input.value = '';
    this.hideActions();
  }

  checkState() {
    const termTag = this.api.selection.findParentTag(this.tag, PopupLink.CSS);
    this.termTag = termTag;
    
    if (termTag) {
      this.button.innerHTML = UnlinkIcon;
      this.input.value = termTag.getAttribute('data-modal-id');
      this.showActions(this.range);
    } else {
      this.button.innerHTML = PopupLinkIcon;
    }
    this.button.classList.toggle(this.iconClasses.active, !!termTag);
  }


  get toolboxIcon() {
    return PopupLinkIcon;
  }

  static get sanitize() {
    return {
      a: true
    };
  }
}

