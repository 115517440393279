import { PersonIcon, DeleteIcon } from '../../icons/icons';
import contactImgDefault from '../../../assets/contact-img-default.jpg'
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

/**
 * Build styles
 */
require('./index.css').toString();

export default class Contact {

  static get toolbox() {
    return {
      icon: PersonIcon,
      title: 'Contact',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get enableLineBreaks() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-contact-container',
      wrapper: 'cdx-contact',
      btn: 'cdx-media-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      input: 'cdx-contact-input',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
      name: data.name || '',
      title: data.title || '',
      phone: data.phone || '',
      email: data.email || '',
      linkedin: data.linkedin || '',
      twitter: data.twitter || '',
      address: data.address || '',
    };

    this._imageElement = this.getImageElement();
    this._nameElement = this.getNameElement();
    this._titleElement = this.getTitleElement();
    this._phoneElement = this.getPhoneElement();
    this._emailElement = this.getEmailElement();
    this._linkedinElement = this.getLinkedinElement();
    this._twitterElement = this.getTwitterElement();
    this._addressElement = this.getAddressElement();
    this._defaultImgElement = this.defaultImgElement();
  }

  getImageElement() {
    /**
     * Create element for current Block's level
     */
    let img;

    if (this._data.url) {
      img = document.createElement('img');
      img.classList.add(this._CSS.wrapper);
      img.setAttribute('src', this._data.url);
      img.setAttribute('data-mid', this._data.mid);
      img.setAttribute('data-x', this._data.x);
      img.setAttribute('data-y', this._data.y);
      img.setAttribute('data-width', this._data.width);
      img.setAttribute('data-height', this._data.height);
      img.setAttribute('data-maxwidth', this._data.maxwidth);
      img.setAttribute('data-maxheight', this._data.maxheight);
    }

    return img;
  }


  defaultImgElement() {
    let img;
    img = document.createElement('img');
    img.classList.add(this._CSS.wrapper);
    img.setAttribute('src', contactImgDefault);
    img.setAttribute('data-mid', this._data.mid);
    img.setAttribute('data-x', this._data.x);
    img.setAttribute('data-y', this._data.y);
    img.setAttribute('data-width', this._data.width);
    img.setAttribute('data-height', this._data.height);
    img.setAttribute('data-maxwidth', this._data.maxwidth);
    img.setAttribute('data-maxheight', this._data.maxheight);
    return img;
  }

  getNameElement() {
    const tag = document.createElement('h4');
    tag.dataset.placeholder = `Name`;
    tag.innerHTML = this._data.name || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('name');

    tag.contentEditable = this.readOnly ? 'false' : 'true';

    return tag;
  }

  getTitleElement() {
    const tag = document.createElement('h6');
    tag.dataset.placeholder = `Title`;
    tag.innerHTML = this._data.title || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('title');

    tag.contentEditable = this.readOnly ? 'false' : 'true';

    return tag;
  }

  getPhoneElement() {
    const tag = document.createElement('p');
    tag.dataset.placeholder = `Contact number`;
    tag.innerHTML = this._data.phone || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('contact-number');

    tag.contentEditable = this.readOnly ? 'false' : 'true';
    tag.oninput = () => { if (tag.innerHTML.trim() === '<br>') tag.innerHTML = '' }

    return tag;
  }

  getEmailElement() {
    const tag = document.createElement('p');
    tag.dataset.placeholder = `Contact email`;
    tag.innerHTML = this._data.email || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('contact-email');

    tag.contentEditable = this.readOnly ? 'false' : 'true';
    tag.oninput = () => { if (tag.innerHTML.trim() === '<br>') tag.innerHTML = '' }

    return tag;
  }

  getLinkedinElement() {
    const tag = document.createElement('p');
    tag.dataset.placeholder = `Linkedin full URL`;
    tag.innerHTML = this._data.linkedin || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('linkedin');

    tag.contentEditable = this.readOnly ? 'false' : 'true';
    tag.oninput = () => { if (tag.innerHTML.trim() === '<br>') tag.innerHTML = '' }

    return tag;
  }

  getTwitterElement() {
    const tag = document.createElement('p');
    tag.dataset.placeholder = `Twitter username`;
    tag.innerHTML = this._data.twitter || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('twitter');

    tag.contentEditable = this.readOnly ? 'false' : 'true';
    tag.oninput = () => { if (tag.innerHTML.trim() === '<br>') tag.innerHTML = '' }

    return tag;
  }

  getAddressElement() {
    const tag = document.createElement('p');
    tag.dataset.placeholder = `Address`;
    tag.innerHTML = this._data.address || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('address');

    tag.contentEditable = this.readOnly ? 'false' : 'true';
    tag.oninput = () => { if (tag.innerHTML.trim() === '<br>') tag.innerHTML = '' }

    return tag;
  }

  render() {
    this._container = document.createElement('div');
    this._container.classList.add(this._CSS.container);
    let img = this._imageElement;
    let defaultImg = this._defaultImgElement;

    let contactContainer = document.createElement('div');
    let emailContainer = document.createElement('div');
    let linkedInContainer = document.createElement('div');
    let twitterContainer = document.createElement('div');
    let addressContainer = document.createElement('div');

    let contactIcon = document.createElement('p');
    let emailIcon = document.createElement('p');
    let linkedInIcon = document.createElement('p');
    let twitterIcon = document.createElement('p');
    let addressIcon = document.createElement('p');

    contactContainer.classList.add('contact-number-container');
    emailContainer.classList.add('contact-email-container');
    linkedInContainer.classList.add('linkedin-container');
    twitterContainer.classList.add('twitter-container');
    addressContainer.classList.add('address-container');

    contactIcon.classList.add('contact-number-icon');
    emailIcon.classList.add('contact-email-icon');
    linkedInIcon.classList.add('linkedin-icon');
    twitterIcon.classList.add('twitter-icon');
    addressIcon.classList.add('address-icon');

    contactContainer.appendChild(contactIcon);
    emailContainer.appendChild(emailIcon);
    linkedInContainer.appendChild(linkedInIcon);
    twitterContainer.appendChild(twitterIcon);
    addressContainer.appendChild(addressIcon);

    contactContainer.appendChild(this._phoneElement);
    emailContainer.appendChild(this._emailElement);
    linkedInContainer.appendChild(this._linkedinElement);
    twitterContainer.appendChild(this._twitterElement);
    addressContainer.appendChild(this._addressElement);

    if (!this.readOnly) {
      let btn = document.createElement('button');
      let btnRemove = document.createElement('button');
      BtnTooltip(btn, EdjsTooltips.image.main);
      BtnTooltip(btnRemove, EdjsTooltips.image.remove);

      if (this._data.url) {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
        btn.innerHTML = `${Contact.toolbox.icon}`;
        btnRemove.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear, this._CSS.xlIcon);
        btnRemove.innerHTML = `${DeleteIcon}`;
      } else {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
        btn.innerHTML = `${Contact.toolbox.icon}`;
      }

      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        const block = this.api.blocks.getBlockByIndex(blockIndex);
        this._settings.showMediaLibrary({
          details: block,
          data: this._data,
          bundle: 'image',
        });
      };

      btnRemove.onclick = () => {
        this._data.url = '';
        this._data.mid = '';
        this._data.width = '';
        this._data.height = '';
        this._data.x = '';
        this._data.y = '';
        this._data.maxWidth = '';
        this._data.maxHeight = '';

        this._imageElement.remove();
        this._container.insertBefore(defaultImg, this._container.firstChild)
        btnRemove.remove();
      }


      this._container.appendChild(defaultImg);
      this._container.appendChild(btn);
      if (this._data.url !== '') {
        defaultImg.remove()
        this._container.appendChild(img);
        this._container.appendChild(btnRemove);
      }
      
    } else {
      if (typeof img !== 'undefined') {
        this._container.appendChild(img);
      }
    }

    this._container.appendChild(this._nameElement);
    this._container.appendChild(this._titleElement);
    this._container.appendChild(contactContainer);
    this._container.appendChild(emailContainer);
    this._container.appendChild(linkedInContainer);
    this._container.appendChild(twitterContainer);
    this._container.appendChild(addressContainer);

    return this._container;
  }

  save() {
    if (typeof this._imageElement !== 'undefined' &&this._data.url !== '') {
      this._data.url = this._imageElement.getAttribute('src') || '';
      this._data.mid = this._imageElement.getAttribute('data-mid') || '';
      this._data.x = this._imageElement.getAttribute('data-x') || '';
      this._data.y = this._imageElement.getAttribute('data-y') || '';
      this._data.width = this._imageElement.getAttribute('data-width') || '';
      this._data.height = this._imageElement.getAttribute('data-height') || '';
      this._data.maxWidth = this._imageElement.getAttribute('data-maxwidth') || '';
      this._data.maxHeight = this._imageElement.getAttribute('data-maxheight') || '';
    } else {
      this._data.url = '';
      this._data.mid = '';
      this._data.width = '';
      this._data.height = '';
      this._data.x = '';
      this._data.y = '';
      this._data.maxWidth = '';
      this._data.maxHeight = '';
    }

    this._data.name = this._nameElement.innerHTML;
    this._data.title = this._titleElement.innerHTML;
    this._data.phone = this._phoneElement.innerHTML;
    this._data.email = this._emailElement.innerHTML;
    this._data.linkedin = this._linkedinElement.innerHTML;
    this._data.twitter = this._twitterElement.innerHTML;
    this._data.address = this._addressElement.innerHTML;

    return this._data;
  }
}