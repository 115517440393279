import { Box, Button } from "@mui/material/";
import { KeyboardBackspace } from "@mui/icons-material";

export default function BackButton(props) {
  return (
    <Button className={`back-btn ${props.className}`} onClick={() => props.onCancel()}>
      <Box>
        <KeyboardBackspace />
      </Box>
      {props.label ? props.label : 'Back to library'}
    </Button>
  );
}
