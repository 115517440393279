import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// App
import { apiEndpoints } from "../../config";
import NewsItem from "./newsItem";
import { getOnlineNode } from "../../core/getNode";
import Pagination from "../../partials/pagination";
import PageHeader from "../../partials/pageHeader";
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import NoData from "../../partials/noData";
import { getFavoritesByContentType } from "../../utils/helpers";
import ScreenCategories from "../../partials/screenCategories";
import ClientTheme from "../../core/clientTheme";
import { favoritesEnabled } from "../../config";

// UI components
import Row from "react-bootstrap/Row";
import CompanyappLoader from "../../partials/companyappLoader";

const pageSize = 12;

export default function News(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(0);
  const [categoryOpen, setCategoryOpen] = useState(false);

  const user = useSelector((state) => state).authReducer.user;
  const favorites = useSelector((state) => state).favoritesReducer;
  const contentType = "news"
  const favStrings = getFavoritesByContentType(favorites, contentType, true);



  const clientTheme = { ...ClientTheme() };

  useEffect(() => {
    if (categories.length <= 1) {
      getCategories();
    }
    if ((activeIndex === 1 && favStrings !== "") || activeIndex === 0) {
      getContent(1);
    }
    else {
      setData([]);
      setIsLoading(false);
    }
  }, [selectedCategoryItem, activeIndex]);

  
  useEffect(() => {
    if (activeIndex === 1) {
      if (favStrings !== "") {
        getContent(1);
      }
      else {
        setData([]);
        setIsLoading(false);
      }
    }
  }, [favStrings]);

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };


  /**
   * @function getContent
   * @description Retrieves the data from an API
   */
  const getContent = (_currentPage) => {
    let path = `${apiEndpoints.news}?status=1&promote=1&items_per_page=${pageSize}&page=${_currentPage - 1}`;

    const tid = selectedCategory;

    if (activeIndex === 1) {
      path = `${apiEndpoints.news}/all/${favStrings}?status=1&promote=1&items_per_page=${pageSize}&page=${_currentPage - 1}`;

      if (tid !== 0) {
        path = `${apiEndpoints.news}/${tid}/${favStrings}?status=1&promote=1&items_per_page=${pageSize}&page=${_currentPage - 1}`;
      }
    }
    else {
      if (tid !== 0) {
        path = `${apiEndpoints.news}/${tid}/all?status=1&promote=1&items_per_page=${pageSize}&page=${_currentPage - 1}`;
      }
    }

    getOnlineNode(path, user.access_token)
      .then((response) => {
        setData(response.data.rows);
        setTotalItems(response.data.pager.total_items);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(true, _error.response.status, _error.response.statusText);
        } else if (_error.request) {
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };


  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  const getCategories = () => {
    getOnlineNode(apiEndpoints.newsCategories, user.access_token)
      .then((response) => {
        renderCategories(response.data);
      })
      .catch((_error) => {
        // if (_error.response) {
        //   _setError(true, _error.response.status, _error.response.statusText);
        // } else if (_error.request) {
        //   _setError(true, 0, alertMessages.requestError.message);
        // } else {
        //   _setError(true, 0, alertMessages.unkownError.message);
        // }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);
    setCurrentPage(_index);
    getContent(_index);
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  const renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: "All News",
      },
    ];

    _data.forEach((item) => {
      categories.push({
        value: item?.tid[0].value,
        label: item?.name[0].value,
      });
    });

    setCategories(categories);
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  const handleCategorySelection = (value) => {
    setIsLoading(true);

    setIsError(false);
    setCurrentPage(1);
    setSelectedCategory(parseInt(value, 10));
    setSelectedCategoryItem(value);
    setCategoryOpen(false);
  };

  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {
      return (
        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={data.length < pageSize ? true : false}
          currentPage={currentPage}
          onPrevious={() => {
            onPaginate(currentPage - 1);
          }}
          onNext={() => {
            onPaginate(currentPage + 1);
          }}
        />
      );
    }
  };

  const getCategoryName = (id) => {
    let categoryName = categories.find((e) => e.value === id);
    return categoryName ? categoryName.label : "";
  };

  /**
   * @function render
   * @description Default render method
   */

  return (
    <>
      {isError ? (
        <Error status={errorStatus} message={errorMessage} />
      ) : (
        <main className="news  screen">
          <PageHeader
            pageName="Latest News"
            filters={true}
            owner="client"
            pageNameColor="clientHeadingScreens"
            svgLogo={clientTheme.pageLogos.svg}
            pageLogo={clientTheme.pageLogos.news}
            enableFavorites={favoritesEnabled}
            categorySelector={
              <ScreenCategories
                title="News"
                categoryName="news"
                selectedCategoryItem={selectedCategoryItem}
                categoryOpen={categoryOpen}
                setCategoryOpen={(value) => setCategoryOpen(value)}
                handleCategorySelection={(value) => handleCategorySelection(value)}
                categories={categories}
              />
            }
            getContent={() => {
              setActiveIndex(0);
              setIsLoading(true);
              getContent(1);
            }}
            getFavourites={() => {
              setActiveIndex(activeIndex === 0 ? 1 : 0);
              setIsLoading(true);
            }}
            activeTabIndex={activeIndex}
          />
          {isLoading ? (
            <CompanyappLoader />
          ) : (
            <>
              {data.length > 0 ? (
                <>
                  <Row>
                    {data.map((item, index) => {
                      return (
                        <NewsItem
                          key={`news-${item.nid}-${index}`}
                          item={item}
                          categoryName={getCategoryName(item.field_category)}
                          index={index}
                          contentType={contentType}
                        />
                      );
                    })}
                  </Row>
                  {renderPagination()}
                </>
              ) : (
                <NoData activeIndex={activeIndex} />
              )}
            </>
          )}
        </main>
      )}
    </>
  );
}
