import React from "react";

import { useState } from 'react';
import { Snackbar, Alert, IconButton, Tooltip } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import GridItem from "../../partials/gridItem";
import ListItem from "../../partials/listItem";

import EmailIcon from '@mui/icons-material/EmailOutlined';
import CallIcon from '@mui/icons-material/CallOutlined';
import ChatIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

export default function CompanyDirectoryItem(props) {
  const [isHovered, setIsHovered] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false);
  const itemClass = "contact-item";

  function cleanString(inputString) {
    const cleanedString = inputString.replace(/&\s/g, '');
  
    return cleanedString;
  }

  return (
    <>
      <GridItem
        xs={12}
        sm={6}
        itemClass="company-directory"
        linkTo={true}
        route="company_directory"
        title={props.item.title}
        altStringAvatar={cleanString(props.item.title)}
        field_body={props.item.body}
        nid={props.item.nid}
        cardActions={true}
        disableCategory={true}
        // category_label={props.item?.category}
        hasAvatar={true}
        user_picture={props.item?.user_picture}
      />
    </>
  )
}