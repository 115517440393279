import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { apiEndpoints } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import { serverUrl } from "../../config";

import { Typography, Button } from "@mui/material";
import ChevronRight from '@mui/icons-material/ChevronRightOutlined';
import { CircularProgress } from "@mui/material";
import ClearIcon from '@mui/icons-material/ClearOutlined';

export default function SearchItem(props) {

  const [uri, setUri] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const user = useSelector((state) => state).authReducer.user;

  useEffect(() => {
    if (props.item.type === "documents") {
      setIsLoading(true);
      fetchDocument(props.item.nid);
    }
    if (props.item.type === "external_resource") {
      setIsLoading(true);
      fetchLink(props.item.nid);
    }
  }, [props.item]);

  const fetchDocument = (nid) => {
    let path = `${apiEndpoints.documents}/all/${nid}`;
    getOnlineNode(path, user.access_token)
      .then((response) => {
        setIsLoading(false);
        setUri(response?.data?.rows[0]?.uri);
      })
      .catch((_error) => {
        setIsLoading(false);
        setError(true);
      });
  }

  const fetchLink = (nid) => {
    let path = `${apiEndpoints.links}/all/${nid}`;
    getOnlineNode(path, user.access_token)
      .then((response) => {
        console.log('link response', response)
        setIsLoading(false);
        setUri(response?.data?.rows[0]?.field_url);
      })
      .catch((_error) => {
        setIsLoading(false);
        setError(true);
      });
  }


  const handleLink = () => {
    switch (props.item.type) {
      case "News": {
        navigate(`/news/${props.item.nid}`);
        break;
      }
      case "Events": {
        navigate(`/events/${props.item.nid}`);
        break;
      }
      case "Videos": {
        navigate(`/videos/${props.item.nid}`);
        break;
      }
      case "Podcasts": {
        navigate(`/podcasts/${props.item.nid}`);
        break;
      }
      case "Course": {
        navigate(`/training/${props.item.nid}`);
        break;
      }
      case "Quiz": {
        navigate(`/quizzes/${props.item.nid}`);
        break;
      }
      case "Documents": {
        if (uri) {
          window.open(serverUrl + uri, "_blank");
        }
        break;
      }
      case "External Resource":
        window.open(uri, "_blank");
        break;
      case "Webform": {
        props.showForm({ title: props.item.title, id: props.item.nid });
        break;
      }
      default:
        return;
    }
  }

  const iconSize = 20;

  return (
    <Button
      className="w-100 d-flex justify-content-between align-items-center my-1"
      variant="searchItem"
      owner="client"
      onClick={() => handleLink()}
      endIcon={
        error ?
          <ClearIcon
            sx={{ fontSize: `${iconSize}px` }}
          /> :
          isLoading ?
            <CircularProgress
              color="clientPrimary"
              size={iconSize}
            /> :
            <ChevronRight
              sx={{ fontSize: `${iconSize}px` }}
            />
      }
    >
      <Typography
        owner="client"
        variant="h6"
      >
        {props.item.title}
      </Typography>
    </Button>
  )
}