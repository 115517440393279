import { getOnlineNode } from './getNode';

const isLoggedIn = async () => {
  try {
    const response = await getOnlineNode('/user/login_status?_format=json');
    if (response.data === 1) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default isLoggedIn;