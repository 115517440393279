import React, { useState } from 'react';

// Modules

// App
import { serverUrl } from '../../config';
import { postNode } from '../../core/postNode';

// UI
import { Typography, Button, TextField } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import { SendOutlined } from '@mui/icons-material';


export default function CommentForm(props) {
  const [comment, setComment] = useState("");
  const [isLoading, setLoading] = useState(false);

  const addComment = () => {
    setLoading(true);

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/comment/comment',
        },
      },
      entity_id: [
        {
          target_id: props.nid,
        },
      ],
      entity_type: [
        {
          value: 'node',
        },
      ],
      comment_type: [
        {
          target_id: 'comment',
        },
      ],
      field_name: [
        {
          value: 'field_comments',
        },
      ],
      subject: [
        {
          value: '',
        },
      ],
      comment_body: [
        {
          value: comment.trim(),
        },
      ],
    };

    postNode(
      'comment',
      data,
      props.user.csrf_token,
    )
      .then((response) => {
        setComment('');
        props.onCommentPosted(response.data);
        setLoading(false);
      })
      .catch((_error) => {
        // error
        setLoading(false);
      });
  }

  if (!props.user.current_user) {
    return null;
  }

  return (
    <Row className='comment-form-container gx-0'>
      <Col>
        <Row className='comment-form-input-container gx-0'>
          <TextField
            className='comment-form-input'
            value={comment}
            multiline
            variant='standard'
            placeholder='Write a comment...'
            color='clientText'
            onChange={(e) => {
              setComment(e.target.value)
            }}
          />
        </Row>
        <Row className='comment-input-footer d-flex justify-content-between align-items-center gx-0'>
          <Col xs={'auto'}>
            <Typography
              className='comment-input-poster'
              owner='client'
              variant='body1'
              color="clientText.main"
              >
              posting as <strong>{props.user?.current_user?.name}</strong>
            </Typography>
          </Col>
          <Col xs={'auto'}>
            <Button
              disabled={(comment.trim() === "" || isLoading)}
              className='comment-form-submit'
              endIcon={<SendOutlined />}
              variant='text'
              color='clientPrimary'
              onClick={() => {
                if (comment.trim() !== "") {
                  addComment();
                }
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}