export const alignment = (block) => {
  let alignment = null;

  if (block?.tunes?.AlignmentBlockTune) {
    alignment = block?.tunes?.AlignmentBlockTune?.alignment;
  }

  if (alignment) {
    return `text-${alignment}`;
  } else {
    return ``;
  }
}

export const color = (block, returnColorOnly) => {
  let color = null;

  if (block?.tunes?.FontColorTool) {
    color = block?.tunes?.FontColorTool?.color;
  }
  else if (block?.data?.color) {
    color = block?.data?.color
  }

  if (color) {
    if (returnColorOnly) {
      return color;
    } else {
      return `color: ${color}`;
    }
  } else {
    return ``;
  }
}

export const animation = (block) => {
  let animation = null;

  if (block?.tunes?.Animation) {
    animation = block?.tunes?.Animation?.animation?.class;
  }

  if (animation) {
    return `fragment ${animation}`;
  } else {
    return ``;
  }
}

export const dimension = (block) => {
  let dimensions = null
  let tempDimensions = null
  if (block?.data?.dimensions) {
    tempDimensions = { ...block?.data?.dimensions }
    dimensions = {
      width: tempDimensions.width === 'auto' || tempDimensions.width === '0' ? 'auto' : `${tempDimensions.width}px`,
      height: tempDimensions.height === 'auto' || tempDimensions.height === '0' ? 'auto' : `${tempDimensions.height}px`,
    }
  }

  if (dimensions) {
    return `width:${dimensions.width}; height: ${dimensions.height}; object-fit: cover`;
  }
  else {
    return ``;
  }
}

export const blockBgColor = (block) => {
  let bgColor = null

  if (block?.tunes?.BlockBackgroundTool?.bgColor?.name) {
    bgColor = block?.tunes?.BlockBackgroundTool?.bgColor?.name;
  }

  if (bgColor) {
    return bgColor;
  } else {
    return 'blue';
  }
}

export const headingToggle = (block) => {
  let heading = null

  if (block?.tunes?.HeadingToggleTool?.variant) {
    heading = block?.tunes?.HeadingToggleTool?.variant;
  }

  if (heading) {
    return heading;
  } else {
    return 'heading-title';
  }
}

export const overlayTool = (block) => {
  let overlay = block?.tunes?.OverlayTool;
  let color, opacity

  if (block?.tunes?.OverlayTool) {
    color = overlay?.color;
    opacity = overlay?.opacity;
    return { backgroundColor: color, opacity: opacity }
  }
  else return ''
}

export const draggable = (block) => {
  if (block?.tunes?.DragResize) {
    const style = block.tunes.DragResize.draggable;
    if(style){
      if (style.absolute) {
        return `position: absolute; top: ${style.top}; left: ${style.left}; width: ${style.width}px; height: ${style.height}px;`;
      }
      else {
        if (style.width || style.height) {
          return `width: ${style.width}px; height: ${style.height}px;`;
        }
      }
    }
  }
  else {
    return ""
  }
}