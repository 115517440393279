import React from "react";

import {
  Dialog,
  DialogContent,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { Row, Col, Container } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/CloseRounded";

export default function IframeDialog(props) {
  return (
    <Dialog
      className="form-dialog"
      open={props.open}
      onClose={() => props.onClose()}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          height: 1,
          borderRadius: "25px",
        },
      }}
    >
      <Box>
        <Container className="px-5 pt-4" fluid>
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs={9}>
              <Typography variant="h3" owner="client" color="clientText.dark">
                {props.title}
              </Typography>
            </Col>
            <Col className="d-flex justify-content-end" xs={3}>
              <IconButton
                className="close-dialog-btn"
                onClick={() => props.onClose()}
              >
                <CloseIcon />
              </IconButton>
            </Col>
          </Row>
        </Container>
      </Box>
      <DialogContent
        className="form-dialog-content ps-modal-content p-0 mt-3"
        sx={{ minHeight: "500px", position: "relative" }}
      >
        <iframe
          className="w-100 h-100  px-5 pt-0"
          src={props.src}
          frameBorder="0"
          style={{ position: "absolute" }}
        ></iframe>
      </DialogContent>
    </Dialog>
  );
}
