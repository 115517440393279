import React, { useState, useEffect, useRef } from "react";

// Modules
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { NestedMenuItem } from "mui-nested-menu";

// App
import { filesystem_root, subdomain } from "../../../../../config";
import { alertMessages } from "../../../../../partials/alertMessages";
import { patchNode } from "../../../../../core/postNode";
import { getOnlineNode } from "../../../../../core/getNode";
import { deleteRequest } from "../../../../../core/delete";
import { jsonParser, decodeHtmlEntities } from "../../../../../utils/helpers";
import ColorPickerNew from "../../../../../partials/presentations/colorPickerNew";
import BespokeMedia from "../../../../../partials/presentations/bespokeMedia";
import Permissions from "../../permissions";
import Share from "../../share";
import Publishing from "./publishing";
import { getThemeBackgrounds } from "../../../../../utils/helpers";
import AddAudio from "../../../../../partials/presentations/addAudio";

// UI components
import LoadingTextAnimated from "../../../../../partials/loadingTextAnimated";
import PostTitle from "../../../../../partials/postTitle";
import AlertModal from "../../../../../partials/alertModal";
import { Container, Row, Col } from "react-bootstrap";
import {
  Alert,
  Box,
  Card,
  CardMedia,
  CardContent,
  Skeleton,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  CircularProgress,
  LinearProgress,
  Typography,
  IconButton,
  Snackbar,
  Stack,
  Dialog,
  DialogContent,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
} from "@mui/material";
import {
  PlayArrow,
  Check,
  Close,
  Share as ShareIcon,
  MoreVert,
  Edit,
  Delete,
  DownloadDoneOutlined,
  HighlightOff,
  ChevronLeft,
  KeyboardArrowRight,
  Settings,
  PictureAsPdf,
} from "@mui/icons-material";

import DialogPaper from "../../../../../partials/dialogPaper";
import FavouriteBtn from "../../../../../partials/favouriteBtn";
import { favoritesEnabled } from "../../../../../config";

const libraries = {
  shared_library: "Shared library",
  user_library: "My library",
  core_library: "Corporate library",
};

function Spinner({ text }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        backgroundColor: "rgba(0, 0, 0, 0.85)",
        borderRadius: "4px",
      }}
    >
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <CircularProgress size={20} color="white" />
      </Box>
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: 14,
            lineHeight: 1,
            margin: 0,
            color: "white !important",
          }}
        >
          {text ? text : "Publishing"}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            minWidth: 50,
            fontSize: 18,
            lineHeight: 1,
            margin: 0,
            transition: "all .25s ease-in-out",
            color: "white !important",
          }}
        >
          <LoadingTextAnimated text="." speed={300} />
        </Typography>
      </Box>
    </Box>
  );
}

export default function Presentation(props) {
  const [presentation, setPresentation] = useState(props.item);
  const [title, setTitle] = useState(presentation.title);
  const [body, setBody] = useState(presentation.body);
  const [coreLibrary, setCoreLibrary] = useState(
    presentation.core_library === "1" ? true : false
  );

  const json = jsonParser(presentation.json);

  const [audio, setAudio] = useState(json?.audio ? json?.audio : null);
  const [backgroundColor, setBackgroundColor] = useState(
    json?.backgroundColor ? json?.backgroundColor : ""
  );
  const [homeSlide, setHomeSlide] = useState(
    json?.homeSlide ? json?.homeSlide : ""
  );
  const [showHomeSlideInput, setShowHomeSlideInput] = useState(
    json?.homeSlide ? true : false
  );
  const [thumbnail, setThumbnail] = useState(
    json?.thumbnail ? json?.thumbnail : ""
  );
  const [presentationAudio, setPresentationAudio] = useState(
    json?.presentationAudio ? json?.presentationAudio : null
  );
  const categories = props.categories;
  const languages = props.languages;
  const themes = props.themes;
  const sitas = props.sitas;
  const [selectedCategory, setSelectedCategory] = useState(
    presentation?.category?.tid ? presentation?.category?.tid : 0
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    presentation?.language?.tid ? presentation?.language?.tid : 0
  );
  const [selectedTheme, setSelectedTheme] = useState(
    presentation?.theme?.tid ? presentation?.theme?.tid : 0
  );
  const [selectedSita, setSelectedSita] = useState(null);

  const publishProgressPath = `/api/publishing_progress/${presentation.id}`;

  const user = props.user;

  const [isPublishingSuccess, setPublishingSuccess] = useState(false);
  const [isPublishingError, setPublishingError] = useState(false);
  const [isUpdateError, setUpdateError] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [hasImage, setHasImage] = useState(
    presentation?.slides?.length > 0 ? true : false
  );
  const hoverTimer = useRef();

  const [isPublished, setPublished] = useState(
    presentation?.status === "published" ? true : false
  );
  const [isModified, setModified] = useState(
    presentation?.status === "modified" ? true : false
  );
  const [hasPdf, setPdf] = useState(presentation.pdf ? true : false);
  const [status, setStatus] = useState(presentation?.status);
  const [isPublishing, setPublishing] = useState(false);
  const [isPdfGenerating, setPdfGenerating] = useState(false);
  const [isPdfSuccess, setPdfSuccess] = useState(false);
  const [isPdfError, setPdfError] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const editMenuOpen = Boolean(anchorEl);
  const [open, setOpen] = useState(false);

  const [showShare, setShowShare] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
  const [showUnpublish, setShowUnpublish] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [modalOptions, setModalOptions] = useState({});

  // hooks
  const navigate = useNavigate();

  // redux
  const permissions = useSelector((state) => state).permissionsReducer
    .permissions;

  const library = libraries[presentation?.library.type_id];

  const publishedDateCreated = moment.unix(presentation?.published_date);
  const publishedCreated = publishedDateCreated.format(
    "DD[.]MM[.]YY [(]HH[:]mm[)]"
  );

  const unixDateCreated = moment.unix(presentation?.created);
  const dateCreated = unixDateCreated.format("DD[.]MM[.]YY [(]HH[:]mm[)]");

  const unixDateChanged = moment.unix(presentation?.changed);
  const dateChanged = unixDateChanged.format("DD[.]MM[.]YY [(]HH[:]mm[)]");

  let category = "Uncategorised";
  let language = "None";

  useEffect(() => {
    setSita();
  }, []);

  useEffect(() => {
    setSita();
  }, [sitas]);

  // is publishing?
  useEffect(() => {
    if (presentation.is_publishing === true) {
      setPublishing(true);
      checkPublishingState();
    } else {
      setPublishing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentation.is_publishing]);

  if (presentation.category?.label) {
    category = presentation.category.label;
  }

  if (presentation.language?.label) {
    language = presentation.language.label;
  }

  const setSita = () => {
    let selected;

    if (presentation?.sita?.tid) {
      selected = sitas.find((item) => item.tid == presentation?.sita?.tid);
    } else {
      selected = sitas.find((item) => item.tid == 0);
    }
    setSelectedSita(selected);
  };

  const handleCategorySelection = (tid) => {
    setSelectedCategory(parseInt(tid, 10));
    setOpen(false);
  };

  const openEditMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setHovered(true);
  };

  const closeEditMenu = () => {
    setAnchorEl(null);
    setHovered(false);
  };

  const deleteNode = () => {
    deleteRequest(`presentation/${presentation.id}?_format=json`, props.user.csrf_token)
      .then((response) => {
        setModalOptions({
          alertType: "success",
          alertTitle: "Success!",
          alertBody: "Presentation has been deleted.",
          alertConfirmButton: true,
        });

        setShowDelete(true);
      })
      .catch((_error) => {
        setDeleteError(true);
      });
  };

  const publish = () => {
    const path = `api/presentation_zip/${presentation.id}`;

    getOnlineNode(path, { timeout: 0 })
      .then((response) => {
        getPresentation();
        setPublishingError(false);
        setPublishing(false);
        setPublished(true);
        setModified(false);
        setStatus("published");
        setPublishingSuccess(true);
      })
      .catch((error) => {
        console.log("@publish: ", presentation.id, " err: ", error);
        setPublishingError(true);
        setPublishing(false);
        setPublishingSuccess(false);
      });
  };

  const checkPublishingState = () => {
    getOnlineNode(publishProgressPath, null, { timeout: 0 })
      .then((response) => {
        setPublishing(false);
      })
      .catch((error) => {
        setPublishing(false);
      });
  };

  const unpublish = () => {
    const path = `api/presentation_unpublish/${presentation.id}`;

    getOnlineNode(path)
      .then((response) => {
        getPresentation();

        setPublished(false);
        setModified(false);
        setStatus("unpublished");
      })
      .catch((error) => {
        console.log("error on unpublish: ", error);
      });
  };

  const deleteConfirm = () => {
    setShowDelete(false);
    setModalOptions({
      alertType: "warning",
      alertTitle: alertMessages.noticeDeleteConfirm.title,
      alertBody:
        "Are you sure you want to delete this presentation? This action cannot be undone.",
      alertCancelButton: true,
      alertConfirmButton: true,
      confirmButtonLabel: "Delete",
    });

    setShowDeleteConfirm(true);
  };

  const deleteConfirmAlert = () => {
    return (
      <AlertModal
        showAlert={showDeleteConfirm}
        showAlertCallback={() => {
          setShowDeleteConfirm(false);
        }}
        alertType={"error"}
        showCaLogo={true}
        alertMessageTitle={modalOptions.alertTitle}
        alertMessageBody={modalOptions.alertBody}
        cancelButton={modalOptions.alertCancelButton}
        confirmButton={modalOptions.alertConfirmButton}
        cancelButtonLabel={modalOptions.cancelButtonLabel}
        confirmButtonLabel={modalOptions.confirmButtonLabel}
        confirmButtonColor={"error"}
        onConfirm={() => {
          deleteNode();
        }}
      />
    );
  };

  const deleteAlert = () => {
    return (
      <AlertModal
        showAlert={showDelete}
        showAlertCallback={() => {
          setShowDelete(false);
        }}
        alertType={"success"}
        alertMessageTitle={modalOptions.alertTitle}
        alertMessageBody={modalOptions.alertBody}
        confirmButton={true}
        confirmButtonLabel={"OK"}
        onConfirm={() => {
          props.getContent();
        }}
      />
    );
  };

  const unpublishConfirm = () => {
    setModalOptions({
      alertType: "warning",
      alertTitle: alertMessages.presentationUnpublish.title,
      alertBody: alertMessages.presentationUnpublish.message,
      alertCancelButton: true,
      alertConfirmButton: true,
      confirmButtonLabel: "Unpublish",
    });

    setShowUnpublish(true);
  };

  const unpublishAlert = () => {
    return (
      <AlertModal
        showAlert={showUnpublish}
        showAlertCallback={() => {
          setShowUnpublish(false);
        }}
        alertType={"error"}
        showCaLogo={true}
        alertMessageTitle={modalOptions.alertTitle}
        alertMessageBody={modalOptions.alertBody}
        cancelButton={modalOptions.alertCancelButton}
        confirmButton={modalOptions.alertConfirmButton}
        cancelButtonLabel={modalOptions.cancelButtonLabel}
        confirmButtonLabel={modalOptions.confirmButtonLabel}
        onConfirm={() => {
          setShowUnpublish(false);
          unpublish();
        }}
      />
    );
  };

  const getThumbnail = () => {
    let thumb = null;

    const json = jsonParser(props?.item?.json);

    if (json?.thumbnail?.field_media_image) {
      thumb = json.thumbnail?.field_media_image;
    } else {
      if (props.item.slides.length > 0) {
        const slide_json = jsonParser(props?.item?.slides[0]?.slide_json);

        if (slide_json?.reveal?.slideThumbnail?.field_media_image) {
          thumb = slide_json?.reveal?.slideThumbnail?.field_media_image;
        } else {
          const isLibrarySlide =
            props.item.slides[0].initial_presentation === props.item.id
              ? false
              : true;

          if (isLibrarySlide) {
            thumb = `${filesystem_root}/${subdomain}/presentations/${props.item.slides[0].initial_presentation}/${props.item.slides[0].initial_presentation}/thumbnails/slide_${props.item.slides[0].id}_thumbnail.jpg`;
          } else {
            thumb = `${filesystem_root}/${subdomain}/slides/${props.item.slides[0].id}/slide_${props.item.slides[0].id}_thumbnail.jpg`;
          }
        }
      }
    }

    return thumb;
  };

  const getPresentation = () => {
    let path = `api/user_presentations?id=${presentation.id}`;

    getOnlineNode(path)
      .then((response) => {
        if (response?.data?.rows && response?.data?.rows[0]) {
          setPresentation(response.data.rows[0]);
        }
      })
      .catch((error) => {
        // do nothing
      });
  };

  const updatePresentation = (
    permissions,
    selectedCategory,
    selectedLanguage,
    selectedSita
  ) => {
    let data = {
      id: presentation.id,
      permissions: permissions,
      category: selectedCategory,
      sita: selectedSita,
      language: selectedLanguage,
    };

    return patchNode(
      `api/presentation/${presentation.id}`,
      data,
      user.csrf_token
    );
  };

  /**
   * @function updatePresentation - Send patch to server to update presentation entity
   *
   */
  const updatePresentationEntity = () => {
    let data = {
      id: presentation.id,
      title: title,
      body: body,
      category: selectedCategory,
      language: selectedLanguage,
      sita: selectedSita?.tid ? selectedSita.tid : 0,
      core_library: coreLibrary ? 1 : 0,
      theme: selectedTheme,
      json: {
        presentationAudio: presentationAudio,
        thumbnail: thumbnail,
        backgroundColor: backgroundColor,
        homeSlide: homeSlide ? homeSlide : "",
      },
    };

    return patchNode(
      `api/presentation/${presentation.id}`,
      data,
      user.csrf_token
    );
  };

  const generatePdf = () => {
    setPdfGenerating(true);
    getOnlineNode(`api/generate_pdf/${presentation.id}`)
      .then((response) => {
        if (response.data.code === 200) {
          setPdf(true);
        } else {
          setPdf(false);
          setPdfError(true);
        }

        setPdfSuccess(true);
        setPdfGenerating(false);
      })
      .catch((error) => {
        console.log("generatePdf err: ", error);
        setPdf(false);
        setPdfSuccess(false);
        setPdfGenerating(false);
        setPdfError(true);
      });
  };

  const onPublish = (params) => {
    if (params) {
      setPublishing(true);
      // setProgress(1);

      updatePresentation(
        params.permissions,
        params.category,
        params.language,
        params.sita
      )
        .then((response) => {
          setPresentation(response.data);
          setPublishingError(false);
          publish();
        })
        .catch((error) => {
          setPublishingError(true);
          setPublishing(false);
          // setProgress(0);
        });
    }
  };

  const hasChildren = (id) => {
    if (categories) {
      const nested = categories.filter((item) => item.parent_target_id === id);

      if (nested.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getChildItems = (arr, id) => {
    return arr.filter((item) => item.parent_target_id === id);
  };

  const renderBackgroundColors = () => {
    const themeItem = themes.find((el) => el.value === selectedTheme);
    if (themeItem) {
      return getThemeBackgrounds(themeItem?.title);
    }
  };

  const menuProps = {
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  let is_share_enabled = true;
  let is_view_enabled = false;
  let has_preview_permission = true;

  if (isModified || isPublished) {
    if (permissions?.access_share) {
      is_share_enabled = true;
    }
  }

  if (isModified || isPublished) {
    is_view_enabled = true;
  }

  if (isPublishing) {
    is_view_enabled = false;
  }

  return (
    <>
      {deleteConfirmAlert()}
      {deleteAlert()}
      {unpublishAlert()}
      <Col
        key={`presentation-${presentation.id ? presentation.id : Date.now()}`}
        className={`pres-view-item edit d-flex ${isHovered ? "active" : "in-active"
          }`}
        xs={12}
        sm={6}
        lg={3}
        xl={3}
        xxl={2}
      >
        <Card
          className={`mui-card ${isHovered ? "isHovered" : ""}`}
          variant="presentation-item"
          owner="admin"
          sx={{ width: "100%" }}
        >
          <Box
            className="ratio ratio-16x9 media"
            onMouseEnter={() => {
              if (
                anchorEl === null &&
                !isPublishingSuccess &&
                !isPublishingError
              ) {
                hoverTimer.current = setTimeout(() => {
                  setHovered(true);
                }, 500);
              }
            }}
            onMouseLeave={() => {
              if (
                anchorEl === null &&
                !isPublishingSuccess &&
                !isPublishingError
              ) {
                setHovered(false);
                clearTimeout(hoverTimer.current);
              } else {
                clearTimeout(hoverTimer.current);
              }
            }}
          >
            <Box className="play-container d-flex justify-content-around align-items-center">
              <Button
                onClick={() => setShowShare(true)}
                disabled={is_share_enabled ? false : true}
                sx={{
                  opacity: is_share_enabled ? 1 : 0,
                }}
              >
                <Box className="icon-container share">
                  <ShareIcon
                    fontSize="large"
                    sx={{ fill: is_share_enabled ? "#333" : "#b4b4b4" }}
                  />
                </Box>
                <Typography
                  variant="span"
                  owner="admin"
                  color="adminText.light"
                  sx={{ opacity: is_share_enabled ? 1 : 0 }}
                >
                  Share
                </Typography>
              </Button>

              <Button
                onClick={() =>
                  window.open(
                    "/presentations/view/" + presentation.id,
                    "_blank"
                  )
                }
                disabled={is_view_enabled ? false : true}
              >
                <Box
                  className={`icon-container view ${is_view_enabled ? "" : "disabled"
                    }`}
                >
                  <PlayArrow
                    fontSize="large"
                    sx={{ fill: is_view_enabled ? "#333" : "#b4b4b4" }}
                  />
                </Box>
                <Typography
                  variant="span"
                  owner="admin"
                  color="adminText.light"
                  sx={{ opacity: is_view_enabled ? 1 : 0 }}
                >
                  View
                </Typography>
              </Button>
              <Button
                onClick={(event) => openEditMenu(event)}
                disabled={
                  presentation?.has_edit_permission || has_preview_permission
                    ? false
                    : true
                }
                sx={{
                  opacity:
                    presentation?.has_edit_permission || has_preview_permission
                      ? 1
                      : 0,
                }}
              >
                <Box className="icon-container actions">
                  <MoreVert
                    fontSize="large"
                    sx={{
                      fill:
                        presentation?.has_edit_permission ||
                          has_preview_permission
                          ? "#333"
                          : "#b4b4b4",
                    }}
                  />
                </Box>
                <Typography
                  variant="span"
                  owner="admin"
                  color="adminText.light"
                  sx={{
                    opacity:
                      presentation?.has_edit_permission ||
                        has_preview_permission
                        ? 1
                        : 0,
                  }}
                >
                  Actions
                </Typography>
              </Button>
            </Box>
            {hasImage ? (
              <CardMedia
                className="thumb"
                component="img"
                image={getThumbnail()}
                alt={""}
                onError={() => setHasImage(false)}
              />
            ) : (
              <Box className="skeleton d-flex" data-thumb={getThumbnail()}>
                <Box className="w-50" sx={{ backgroundColor: "#f2f2f2" }}>
                  <Box sx={{ margin: "0 5%" }}>
                    <Skeleton animation={false} width="30%" height={20} />
                    <Skeleton animation={false} width="100%" height={20} />
                    <Skeleton animation={false} width="100%" height={20} />
                  </Box>
                </Box>
                <Box className="w-50">
                  <Box className="w-100 h-100">
                    <Skeleton
                      className="right w-100 h-100"
                      animation={false}
                      variant="rectangular"
                    />
                  </Box>
                </Box>
              </Box>
            )}
            <Box className="id-container">
              <Typography
                variant="span"
                className="id"
                owner="admin"
                color="adminText.contrastText"
              >
                {presentation.id}
              </Typography>
            </Box>
            <Box className="status-container">
              {!isPublished && !isModified && (
                <div className="tri unpublished">
                  <Close className="icon" />
                </div>
              )}

              {isModified && (
                <div className="tri modified">
                  <Check className="icon" />
                </div>
              )}

              {isPublished && (
                <div className="tri published">
                  <Check className="icon" />
                </div>
              )}
            </Box>
            {favoritesEnabled &&
              <Box className="fav-btn-container">
                <FavouriteBtn
                  id={presentation.id}
                  contentType={props.contentType}
                />
              </Box>
            }
            <Menu
              anchorEl={anchorEl}
              id="edit-menu"
              open={editMenuOpen}
              onClose={closeEditMenu}
              onClick={closeEditMenu}
            >
              {presentation.has_edit_permission && !isPublishing && (
                <MenuItem
                  onClick={() => {
                    navigate(`/presentations/edit/${presentation.id}`);
                  }}
                >
                  <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon>
                  Edit
                </MenuItem>
              )}

              {presentation.has_edit_permission && !isPublishing && (
                <MenuItem
                  onClick={() => {
                    setShowSettings(true);
                  }}
                >
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
              )}

              {has_preview_permission && (
                <MenuItem
                  onClick={() => {
                    window.open(
                      "/presentations/preview/" + presentation.id,
                      "_blank"
                    );
                  }}
                >
                  <ListItemIcon>
                    <PlayArrow fontSize="small" />
                  </ListItemIcon>
                  Preview
                </MenuItem>
              )}

              {presentation.has_edit_permission && !isPublishing && (
                <MenuItem
                  disabled={presentation.slides.length > 0 ? false : true}
                  onClick={() => setShowPublish(true)}
                >
                  <ListItemIcon>
                    <DownloadDoneOutlined fontSize="small" />
                  </ListItemIcon>
                  {isPublished ? "Re-publish" : "Publish"}
                </MenuItem>
              )}

              {permissions.access_pdf && (isPublished || isModified) && (
                <MenuItem
                  disabled={
                    presentation.slides.length < 1 || isPdfGenerating
                      ? true
                      : false
                  }
                  onClick={() => generatePdf()}
                >
                  <ListItemIcon>
                    <PictureAsPdf fontSize="small" />
                  </ListItemIcon>
                  {"Create PDF"}
                </MenuItem>
              )}

              {presentation.has_edit_permission &&
                !isPublishing &&
                status !== "unpublished" && (
                  <MenuItem onClick={() => unpublishConfirm()}>
                    <ListItemIcon>
                      <HighlightOff fontSize="small" />
                    </ListItemIcon>
                    Unpublish
                  </MenuItem>
                )}

              {presentation?.has_delete_permission && !isPublishing && (
                <MenuItem onClick={() => deleteConfirm()}>
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              )}
            </Menu>

            {(isPublishing || isPdfGenerating) && (
              <Box className="publishing-container">
                <Spinner
                  text={isPdfGenerating ? "Generating PDF" : "Publishing"}
                />
              </Box>
            )}

            {(isPublishingSuccess || isPdfSuccess) && (
              <Box className="publishing-container">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    backgroundColor: "rgba(0, 0, 0, 0.85)",
                    borderRadius: "4px",
                    zIndex: 10,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: 12,
                        lineHeight: 1,
                        margin: 0,
                        color: "white !important",
                      }}
                    >
                      <Check className="icon" fontSize="inherit" />{" "}
                      {isPdfSuccess
                        ? `PDF created. Access the file via the 'Share' button`
                        : "Published successfully"}
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: "10px" }}>
                    <Button
                      type="button"
                      className="close-modal-btn"
                      size="small"
                      variant="outlined"
                      color={"white"}
                      onClick={() => {
                        setPublishingSuccess(false);
                        setPdfSuccess(false);
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 10 }}
                        variant="span"
                        color="adminText.contrastText"
                        owner="admin"
                      >
                        OK
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}

            {(isPublishingError || isPdfError) && (
              <Box className="publishing-container">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    backgroundColor: "rgba(0, 0, 0, 0.85)",
                    borderRadius: "4px",
                    zIndex: 10,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: 12,
                        lineHeight: 1,
                        margin: 0,
                        color: "white !important",
                      }}
                    >
                      <Close className="icon" fontSize="inherit" />{" "}
                      {isPublishingError ? "Publishing failed" : "PDF failed"}
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: "10px" }}>
                    <Button
                      type="button"
                      className="close-modal-btn"
                      size="small"
                      variant="outlined"
                      color={"white"}
                      onClick={() => {
                        setPublishingError(false);
                        setPdfError(false);
                      }}
                    >
                      <Typography
                        variant="span"
                        color="adminText.contrastText"
                        owner="admin"
                        sx={{
                          fontSize: 10,
                        }}
                      >
                        Cancel
                      </Typography>
                    </Button>
                    <Button
                      sx={{ marginLeft: "10px" }}
                      type="button"
                      className="close-modal-btn"
                      size="small"
                      variant="outlined"
                      color={"white"}
                      onClick={() => {
                        if (isPublishingError) {
                          setPublishingError(false);
                          setPublishing(true);
                          publish();
                        }

                        if (isPdfError) {
                          setPdfError(false);
                          setPdfGenerating(true);
                          setPdfSuccess(false);
                          generatePdf();
                        }
                      }}
                    >
                      <Typography
                        variant="span"
                        color="adminText.contrastText"
                        owner="admin"
                        sx={{
                          fontSize: 10,
                        }}
                      >
                        Retry
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <CardContent className="content" sx={{ paddingTop: "5px" }}>
            <Link to={"/presentations/view/" + presentation.id} target="_blank">
              <PostTitle
                title={presentation.title}
                headingLevel={"h6"}
                titleColor="adminText.dark"
              />
            </Link>
            <Box className={`info ${isHovered ? "hovered" : ""}`}>
              <div className="data">
                <Row>
                  <Col xs={3} lg={3} className="d-flex">
                    <Typography
                      variant="caption"
                      weight="medium"
                      owner="admin"
                      color="adminText.dark"
                      className="label"
                    >
                      Published:
                    </Typography>
                  </Col>
                  <Col xs={9} lg={9} className="d-flex justify-content-end">
                    <Typography
                      variant="caption"
                      owner="admin"
                      color="adminText.main"
                      className="value"
                    >
                      {publishedCreated.toString()}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} lg={3} className="d-flex">
                    <Typography
                      variant="caption"
                      weight="medium"
                      owner="admin"
                      color="adminText.dark"
                      className="label"
                    >
                      Modified:
                    </Typography>
                  </Col>
                  <Col xs={9} lg={9} className="d-flex justify-content-end">
                    <Typography
                      variant="caption"
                      owner="admin"
                      color="adminText.main"
                      className="value"
                    >
                      {dateChanged.toString()}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} lg={3} className="d-flex">
                    <Typography
                      variant="caption"
                      weight="medium"
                      owner="admin"
                      color="adminText.dark"
                      className="label"
                    >
                      Created:
                    </Typography>
                  </Col>
                  <Col xs={9} lg={9} className="d-flex justify-content-end">
                    <Typography
                      variant="caption"
                      owner="admin"
                      color="adminText.main"
                      className="value"
                    >
                      {dateCreated.toString()}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} lg={3} className="d-flex">
                    <Typography
                      variant="caption"
                      weight="medium"
                      owner="admin"
                      color="adminText.dark"
                      className="label"
                    >
                      Author:
                    </Typography>
                  </Col>
                  <Col xs={9} lg={9} className="d-flex justify-content-end">
                    <Typography
                      variant="caption"
                      owner="admin"
                      color="adminText.main"
                      className="value"
                    >
                      {presentation?.author}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} lg={3} className="d-flex">
                    <Typography
                      variant="caption"
                      weight="medium"
                      owner="admin"
                      color="adminText.dark"
                      className="label"
                    >
                      Library:
                    </Typography>
                  </Col>
                  <Col xs={9} lg={9} className="d-flex justify-content-end">
                    <Typography
                      variant="caption"
                      owner="admin"
                      color="adminText.main"
                      className="value"
                    >
                      {library}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} lg={3} className="d-flex">
                    <Typography
                      variant="caption"
                      weight="medium"
                      owner="admin"
                      color="adminText.dark"
                      className="label"
                    >
                      Category:
                    </Typography>
                  </Col>
                  <Col xs={9} lg={9} className="d-flex justify-content-end">
                    <Typography
                      variant="caption"
                      owner="admin"
                      color="adminText.main"
                      className="value"
                    >
                      {category}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} lg={3} className="d-flex">
                    <Typography
                      variant="caption"
                      weight="medium"
                      owner="admin"
                      color="adminText.dark"
                      className="label"
                    >
                      SITA:
                    </Typography>
                  </Col>
                  <Col xs={9} lg={9} className="d-flex justify-content-end">
                    <Typography
                      variant="caption"
                      owner="admin"
                      color="adminText.main"
                      className="value"
                    >
                      {selectedSita?.title ? selectedSita.title : "NONE"}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} lg={3} className="d-flex">
                    <Typography
                      variant="caption"
                      weight="medium"
                      owner="admin"
                      color="adminText.dark"
                      className="label"
                    >
                      Theme:
                    </Typography>
                  </Col>
                  <Col xs={9} lg={9} className="d-flex justify-content-end">
                    <Typography
                      variant="caption"
                      owner="admin"
                      color="adminText.main"
                      className="value"
                    >
                      {presentation?.theme?.field_label
                        ? presentation?.theme?.field_label
                        : presentation?.theme?.label}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} lg={3} className="d-flex">
                    <Typography
                      variant="caption"
                      weight="medium"
                      owner="admin"
                      color="adminText.dark"
                      className="label"
                    >
                      Language:
                    </Typography>
                  </Col>
                  <Col xs={9} lg={9} className="d-flex justify-content-end">
                    <Typography
                      variant="caption"
                      owner="admin"
                      color="adminText.main"
                      className="value"
                    >
                      {language}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={6} className="d-flex">
                    <Typography
                      variant="caption"
                      weight="medium"
                      owner="admin"
                      color="adminText.dark"
                      className="label"
                    >
                      Slides:
                    </Typography>
                  </Col>
                  <Col xs={6} lg={6} className="d-flex justify-content-end">
                    <Typography
                      variant="caption"
                      owner="admin"
                      color="adminText.main"
                      className="value"
                    >
                      {presentation?.slide_length}
                    </Typography>
                  </Col>
                </Row>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Col>

      {showPublish && (
        <Publishing
          item={presentation}
          categories={props.categories}
          languages={props.languages}
          sitas={props.sitas}
          modalVisible={true}
          user={props.user}
          permissions={permissions}
          onHide={() => setShowPublish(false)}
          onPublish={(params) => onPublish(params)}
        />
      )}

      {showPermissions && (
        <Permissions
          item={presentation}
          user={props.user}
          modalVisible={true}
          onHide={() => setShowPermissions(false)}
        />
      )}

      {showShare && (
        <Share
          presentation={presentation}
          status={status}
          user={props.user}
          permissions={permissions}
          hasPdf={hasPdf}
          modalVisible={true}
          onHide={() => setShowShare(false)}
        />
      )}

      {showSettings && (
        <Dialog
          className="ps-modal presentation-settings-modal"
          open={showSettings}
          maxWidth={"lg"}
          fullWidth={true}
          onClose={() => {
            setShowSettings(false);
          }}
          PaperComponent={DialogPaper}
        >
          <Box className="ps-modal-title dialog-draggable-handle">
            <Container fluid>
              <Row className="align-items-center">
                <Col xs={12} sm={6}>
                  <Typography variant="h3" owner="admin" color="adminText.dark">
                    Presentation settings (ID: {presentation.id})
                  </Typography>
                </Col>
                <Col xs={12} sm={6}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    <Button
                      variant="outlined"
                      owner="admin"
                      dialogbtn="true"
                      dialogvariant="cancel"
                      className="lh"
                      onClick={() => {
                        setShowSettings(false);
                      }}
                    >
                      <Typography variant="button" owner="admin" className="lh">
                        Cancel
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      owner="admin"
                      dialogbtn="true"
                      dialogvariant="save"
                      className="lh"
                      onClick={() => {
                        setShowSettings(false);
                        updatePresentationEntity()
                          .then((response) => {
                            setPresentation(response.data);
                            setStatus("modified");
                            setModified(true);
                          })
                          .catch((error) => {
                            setUpdateError(true);
                          });
                      }}
                    >
                      <Typography variant="button" owner="admin" className="lh">
                        Save
                      </Typography>
                    </Button>
                  </Stack>
                </Col>
              </Row>
            </Container>
          </Box>
          <DialogContent>
            <Box className="ps-modal-content">
              <Container fluid>
                <Row>
                  <Col xs={12} sm={6}>
                    <Box className="spacer ls">
                      <Box className="form-group">
                        <Typography
                          className="vertical"
                          variant="form-group-label"
                          htmlFor="presentation-title"
                          owner="admin"
                        >
                          Title
                        </Typography>
                        <TextField
                          id="presentation-title"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={title}
                          sx={{
                            "& .MuiInputBase-input": {
                              height: 28,
                              // paddingTop: '11px',
                              // paddingBottom: '11px'
                            },
                          }}
                          onInput={(event) => {
                            setTitle(event.target.value);
                          }}
                        />
                      </Box>
                      <Box className="form-group">
                        <Typography
                          className="vertical"
                          variant="form-group-label"
                          htmlFor="description"
                          owner="admin"
                        >
                          Description
                        </Typography>
                        <TextField
                          id="description"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={body}
                          sx={{
                            "& .MuiInputBase-root": {
                              minHeight: 45,
                              paddingTop: "3px",
                              paddingBottom: "3px",
                            },
                          }}
                          onInput={(event) => {
                            setBody(event.target.value);
                          }}
                          multiline
                          maxRows={3}
                        />
                      </Box>
                      {permissions.access_promote_to_core_library && (
                        <Box className="form-group">
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="core-lib-checkbox"
                                checked={coreLibrary}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setCoreLibrary(true);
                                  } else {
                                    setCoreLibrary(false);
                                  }
                                }}
                              />
                            }
                            label={
                              <Typography
                                variant="form-group-label"
                                owner="admin"
                              >
                                Promote to Corporate library
                              </Typography>
                            }
                          />
                        </Box>
                      )}
                      <Box className="form-group multi-group">
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="home-slide-checkbox"
                              checked={homeSlide ? true : false}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setHomeSlide(homeSlide ? homeSlide : 1);
                                  setShowHomeSlideInput(true);
                                } else {
                                  setHomeSlide("");
                                  setShowHomeSlideInput(false);
                                }
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="form-group-label"
                              owner="admin"
                            >
                              Show home slide button / Slide #
                            </Typography>
                          }
                        />
                        {showHomeSlideInput && (
                          <div className="inner-form-group">
                            <TextField
                              id="slideNumber"
                              size="small"
                              value={homeSlide}
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: presentation?.slide_length,
                                },
                              }}
                              onChange={(event) => {
                                setHomeSlide(event.target.value);
                              }}
                            />
                          </div>
                        )}
                      </Box>
                      <Box className="form-group pres-bg">
                        <Typography
                          className="vertical"
                          variant="form-group-label"
                          owner="admin"
                        >
                          Presentation background
                        </Typography>
                        <ColorPickerNew
                          hideTitle={true}
                          slideBackgroundColor={
                            backgroundColor ? backgroundColor : ""
                          }
                          // colors={jsonParser(presentation?.theme_json)?.backgroundColors}
                          colors={renderBackgroundColors()}
                          hideDefault={true}
                          onColorSelected={(background_color) => {
                            setBackgroundColor(background_color);
                          }}
                        />
                      </Box>
                    </Box>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Box className="spacer rs">
                      <Box className="form-group">
                        <Typography
                          className="vertical"
                          variant="form-group-label"
                          htmlFor="themes"
                          owner="admin"
                        >
                          Theme
                        </Typography>
                        <FormControl
                          variant={"outlined"}
                          size={"small"}
                          sx={{
                            width: 1,
                            "& label[data-shrink=true]": {
                              visibility: "hidden",
                            },
                            "& legend": {
                              width: 0,
                            },
                          }}
                        >
                          <InputLabel id={`theme`}>Theme</InputLabel>
                          <Select
                            labelId={`theme-id`}
                            value={selectedTheme}
                            label={"Theme"}
                            onChange={(event) => {
                              setSelectedTheme(event.target.value);
                            }}
                          >
                            {themes?.map((theme, index) => {
                              return (
                                <MenuItem
                                  key={`theme-${theme.value}`}
                                  value={theme.value}
                                  sx={{ fontSize: 16 }}
                                >
                                  {decodeHtmlEntities(theme.label)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box className="form-group">
                        <Typography
                          className="vertical"
                          variant="form-group-label"
                          htmlFor="presentationCategories"
                          owner="admin"
                        >
                          Category
                        </Typography>
                        <FormControl
                          variant={"outlined"}
                          size={"small"}
                          sx={{
                            width: 1,
                            "& label[data-shrink=true]": {
                              visibility: "hidden",
                            },
                            "& legend": {
                              width: 0,
                            },
                          }}
                        >
                          <InputLabel id={`category`}>Category</InputLabel>
                          <Select
                            labelId={`category-id`}
                            value={selectedCategory}
                            label={"Category"}
                            open={open}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                          >
                            {categories?.map((category, index) => {
                              const has_child = hasChildren(category.value);
                              const is_top_level = category.parent_target_id
                                ? false
                                : true;

                              if (
                                !category.has_create_permission &&
                                category.value !== 0
                              ) {
                                return null;
                              }

                              if (is_top_level) {
                                if (has_child) {
                                  const region_child_items = getChildItems(
                                    categories,
                                    category.value
                                  );

                                  return (
                                    <NestedMenuItem
                                      key={`category-${category.value}`}
                                      leftIcon={<ChevronLeft />}
                                      rightIcon={null}
                                      parentMenuOpen={open}
                                      label={category.label}
                                      MenuProps={menuProps}
                                      className="settings-nested"
                                    >
                                      {region_child_items.map((region) => {
                                        const countries = getChildItems(
                                          categories,
                                          region.value
                                        );

                                        return (
                                          <NestedMenuItem
                                            key={`region-${region.value}`}
                                            leftIcon={<ChevronLeft />}
                                            rightIcon={null}
                                            parentMenuOpen={open}
                                            label={region.label}
                                            MenuProps={menuProps}
                                            className="settings-nested"
                                          >
                                            {countries.map((country) => {
                                              const cities = getChildItems(
                                                categories,
                                                country.value
                                              );

                                              return (
                                                <NestedMenuItem
                                                  key={`country-${country.value}`}
                                                  leftIcon={<ChevronLeft />}
                                                  rightIcon={null}
                                                  parentMenuOpen={open}
                                                  label={country.label}
                                                  MenuProps={menuProps}
                                                  className="settings-nested"
                                                >
                                                  {cities.map(
                                                    (city, cityIndex) => {
                                                      return (
                                                        <MenuItem
                                                          key={`city-${city.value}`}
                                                          value={city.value}
                                                          sx={{
                                                            fontSize: "16px",
                                                            fontWeight: "400",
                                                            "&.Mui-selected": {
                                                              backgroundColor:
                                                                "primary.light",
                                                            },
                                                          }}
                                                          onClick={() => {
                                                            handleCategorySelection(
                                                              city.value
                                                            );
                                                          }}
                                                        >
                                                          {decodeHtmlEntities(
                                                            city.label
                                                          )}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                                </NestedMenuItem>
                                              );
                                            })}
                                          </NestedMenuItem>
                                        );
                                      })}
                                    </NestedMenuItem>
                                  );
                                } else {
                                  return (
                                    <MenuItem
                                      key={`category-${category.value}`}
                                      value={category.value}
                                      sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        "&.Mui-selected": {
                                          backgroundColor: "primary.light",
                                        },
                                      }}
                                      onClick={() => {
                                        handleCategorySelection(category.value);
                                      }}
                                    >
                                      {decodeHtmlEntities(category.label)}
                                    </MenuItem>
                                  );
                                }
                              } else {
                                return (
                                  <MenuItem
                                    key={`category-${category.value}`}
                                    value={category.value}
                                    sx={{ display: "none" }}
                                    onClick={() => {
                                      handleCategorySelection(category.value);
                                    }}
                                  >
                                    {decodeHtmlEntities(category.label)}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box className="form-group">
                        <Typography
                          className="vertical"
                          variant="form-group-label"
                          htmlFor="languages"
                          owner="admin"
                        >
                          Language
                        </Typography>
                        <FormControl
                          variant={"outlined"}
                          size={"small"}
                          sx={{
                            width: 1,
                            "& label[data-shrink=true]": {
                              visibility: "hidden",
                            },
                            "& legend": {
                              width: 0,
                            },
                          }}
                        >
                          <InputLabel id={`Language`}>Language</InputLabel>
                          <Select
                            labelId={`language-id`}
                            value={selectedLanguage}
                            label={"Language"}
                            onChange={(event) => {
                              setSelectedLanguage(event.target.value);
                            }}
                          >
                            {languages?.map((language, index) => {
                              return (
                                <MenuItem
                                  key={`language-${language.value}`}
                                  value={language.value}
                                  sx={{ fontSize: 16 }}
                                >
                                  {decodeHtmlEntities(language.label)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>

                      <Box className="form-group">
                        <Typography
                          className="vertical"
                          variant="form-group-label"
                          htmlFor="sita"
                          owner="admin"
                        >
                          SITA
                        </Typography>
                        <FormControl
                          fullWidth
                          size="small"
                          sx={{
                            width: 1,
                            "& label[data-shrink=true]": {
                              visibility: "hidden",
                            },
                            "& legend": {
                              width: 0,
                            },
                          }}
                        >
                          <Autocomplete
                            options={sitas}
                            getOptionLabel={(option) => option.title}
                            value={selectedSita ? selectedSita : null}
                            disableClearable={true}
                            onChange={(event, value) => {
                              setSelectedSita(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="SITA"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                  sx: {
                                    paddingBottom: 0,
                                    "& .MuiOutlinedInput-input": {
                                      paddingBottom: "0 !important",
                                      paddingTop: "0 !important",
                                      borderColor: "#c2ccd6",
                                    },
                                    "&:hover .MuiOutlinedInput-input": {
                                      borderWidth: "2px",
                                      borderColor: "primary.main",
                                    },
                                    "& .MuiAutocomplete-endAdornment": {
                                      top: "calc(50% - 16px)",
                                    },
                                  },
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Col>
                </Row>
                {permissions.access_advanced_presentation_settings && (
                  <Row>
                    <Accordion
                      elevation={0}
                      sx={{
                        "&:before": {
                          display: "none",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<KeyboardArrowRight />}
                        sx={{
                          padding: 0,
                          flexDirection: "row-reverse",
                          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                          {
                            transform: "rotate(90deg)",
                          },
                        }}
                      >
                        <Col xs={12} className="d-flex align-items-center">
                          Advanced
                          <Divider className="mt-1 ms-2 flex-grow-1" />
                        </Col>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        <Row className="slide-settings-adv">
                          <Col xs={12} sm={6}>
                            <Row>
                              <Typography
                                owner="admin"
                                variant="h6"
                                color="adminText.dark"
                                className="mb-2"
                              >
                                Add presentation audio
                              </Typography>
                            </Row>
                            <AddAudio
                              user={user}
                              audioData={presentationAudio}
                              onAudioChange={(audio) => {
                                setPresentationAudio(audio);
                              }}
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <BespokeMedia
                              user={user}
                              files={{
                                minWidth: 400,
                                minHeight: 225,
                                maxWidth: 400,
                                maxHeight: 225,
                                aspectRatio: 1.777777777777778,
                              }}
                              media={thumbnail}
                              onMediaInsert={(media) => {
                                setThumbnail(media);
                              }}
                              title={`Bespoke presentation thumbnail`}
                              hint={`A thumbnail for the presentation will be created automatically. However, if you would like to create your own, please upload an image file via the Media library.`}
                            />
                          </Col>
                        </Row>
                      </AccordionDetails>
                    </Accordion>
                  </Row>
                )}
              </Container>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {/* 
      <Dialog
        className='ps-modal publish-error-modal'
        open={isPublishingError ? true : false}
        maxWidth={'sm'}
        fullWidth={true}
        onClose={() => {
          setPublishingError(false);
        }}>
        <Box className='ps-modal-title'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs={12} sm={12}>
                <h3>Publishing failed for ID <b>{presentation.id}</b></h3>
              </Col>
            </Row>
          </Container>
        </Box>
        <DialogContent>
          <Box className='ps-modal-content'>
            <Container fluid>
              <Row>
                <Col>
                  <p className="mb-0">Failed to publish the presentation. Please try again or contact an administrator if the problem persists.</p>
                </Col>
              </Row>
              <Row className='justify-content-center align-items-center mt-3'>
                <Col xs='auto'>
                  <Button
                    type="button"
                    className="close-modal-btn"
                    size="small"
                    variant='outlined'
                    color={'primary'}
                    onClick={() => {
                      setPublishingError(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className="close-modal-btn"
                    size="small"
                    variant="contained"
                    color={"primary"}
                    onClick={() => {
                      publish();
                    }}
                  >
                    Retry
                  </Button>
                </Col>
              </Row>
            </Container>
          </Box>
        </DialogContent>
      </Dialog> */}

      {/* <Dialog
        className='ps-modal publish-success-modal'
        open={isPublishingSuccess ? true : false}
        maxWidth={'sm'}
        fullWidth={true}
        onClose={() => {
          setPublishingSuccess(false);
        }}>
        <Box className='ps-modal-title'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs={12} sm={12}>
                <h3>ID <b>{presentation.id}</b> published</h3>
              </Col>
            </Row>
          </Container>
        </Box>
        <DialogContent>
          <Box className='ps-modal-content'>
            <Container fluid>
              <Row>
                <Col>
                  <p className="mb-0">Successfully published presentation <b>{presentation.id}</b>.</p>
                </Col>
              </Row>
              <Row className='justify-content-center align-items-center mt-3'>
                <Col xs='auto'>
                  <Button
                    type="button"
                    className="close-modal-btn"
                    size="small"
                    variant='outlined'
                    color={'primary'}
                    onClick={() => {
                      setPublishingSuccess(false);
                    }}
                  >
                    OK
                  </Button>
                </Col>
              </Row>
            </Container>
          </Box>
        </DialogContent>
      </Dialog> */}

      {/* <Snackbar
        open={true}
        anchorOrigin={{horizontal: 'center', vertical: 'center'}}
        onClose={() => {
          setPublishingError(false);
        }}>
        <Alert
          variant='filled'
          severity='error'
          action={(
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={() => {
                setPublishingError(false);
              }}>
              <Close fontSize='small' />
            </IconButton>
          )}>
          Failed to publish the presentation. Please try again or contact an administrator if the problem persists.
        </Alert>
      </Snackbar> */}

      <Snackbar
        open={isUpdateError ? true : false}
        autoHideDuration={5000}
        onClose={() => {
          setUpdateError(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setUpdateError(false);
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          Failed to update the presentation. Please try again or contact an
          administrator if the problem persists.
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteError ? true : false}
        autoHideDuration={5000}
        onClose={() => {
          setDeleteError(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setDeleteError(false);
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          Failed to delete the presentation. Please try again or contact an
          administrator if the problem persists.
        </Alert>
      </Snackbar>
    </>
  );
}
