import Draggable from "react-draggable";
import { Paper } from "@mui/material";

export default function DialogPaper(props) {

  return (
    <Draggable
      handle=".dialog-draggable-handle"
    >
      <Paper {...props} />
    </Draggable>
  )
}