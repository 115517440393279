import './change-case.css';

export default class SizeTool {
    static get isInline(){
        return true;
    }

    get state() {
        return this._state;
    }

    static get sanitize() {
        return {
          span: true
        }
      }

    constructor({config, api}){
        this.api = api;
        this.button = null;
        this.optionButtons = [];
        this._state = true;
        this.selectedText = null;
        this.range = null;
        this._settings = config;
        this.selectedOption = 'R';

        this.CSS = {
            actions: 'change-case-action',
            toolbarLabel: 'change-case-toolbar__label',
            tool: 'change-case-tool',
            toolbarBtnActive: this.api.styles.settingsButtonActive,
            inlineButton: this.api.styles.inlineToolButton
        };

        this.headingOptions = {
            'XL' : 'Extra large',
            'L' : 'Large',
            'R' : 'Regular',
            'S' : 'Small',
            'XS' : 'Extra small',
        };
        this.sizes = ['XL','L','R','S','XS']
    }

    set state(state){
        this._state = state;
        this.button.classList.toggle(this.CSS.toolbarBtnActive, state);
    }

    get title(){
        return 'Font size'
    }

    render(){
        this.button = document.createElement('button');
        this.button.type = 'button';
        this.button.innerHTML = `
        <svg width="18" height="16" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.52 11.73"><title>icons</title><path d="M12.81,14.85a.94.94,0,0,1,.08.31.21.21,0,0,1-.07.18.38.38,0,0,1-.23.08l-.43,0-.44,0a.75.75,0,0,1-.24,0,.23.23,0,0,1-.12-.08l-.08-.13-1-2.76H5.6l-.93,2.72a.61.61,0,0,1-.07.14.33.33,0,0,1-.13.09.58.58,0,0,1-.23.06l-.4,0-.42,0a.5.5,0,0,1-.23-.09.24.24,0,0,1-.06-.18,1.28,1.28,0,0,1,.08-.31L7,4.32a.35.35,0,0,1,.09-.16.35.35,0,0,1,.17-.1,1.43,1.43,0,0,1,.28,0h.9a1.55,1.55,0,0,1,.29,0,.39.39,0,0,1,.18.1A.51.51,0,0,1,9,4.32ZM7.94,5.56h0L6,11.21H9.92Z" transform="translate(-3.13 -3.7)"/><path d="M18.65,6.36a.9.9,0,0,1,0,.18.5.5,0,0,1,0,.13.23.23,0,0,1-.07.07l-.09,0H16.58V8.82a.08.08,0,0,1,0,.07.22.22,0,0,1-.07.06l-.13,0-.19,0L16,9l-.13,0-.07-.06a.14.14,0,0,1,0-.07V6.76H13.9l-.09,0s-.05,0-.06-.07a.5.5,0,0,1-.05-.13.82.82,0,0,1,0-.36.39.39,0,0,1,.05-.13A.14.14,0,0,1,13.81,6a.12.12,0,0,1,.08,0h1.86V3.9a.19.19,0,0,1,0-.08s0,0,.07-.06a.4.4,0,0,1,.13,0l.2,0,.19,0a.33.33,0,0,1,.13,0,.14.14,0,0,1,.07.06.11.11,0,0,1,0,.08V6h1.86a.11.11,0,0,1,.08,0,.16.16,0,0,1,.07.07.25.25,0,0,1,0,.13A.9.9,0,0,1,18.65,6.36Z" transform="translate(-3.13 -3.7)"/><path d="M18.3,12a.79.79,0,0,1-.08.41.26.26,0,0,1-.23.12H14.35a.27.27,0,0,1-.24-.12A.84.84,0,0,1,14,12a.81.81,0,0,1,.07-.41.27.27,0,0,1,.24-.11H18a.27.27,0,0,1,.13,0,.17.17,0,0,1,.1.09,1.38,1.38,0,0,1,.06.16A1.69,1.69,0,0,1,18.3,12Z" transform="translate(-3.13 -3.7)"/></svg>`;
        this.button.classList.add(this.CSS.inlineButton);

        return this.button;
    }

    checkState(selection){
        const text = selection.anchorNode;
        if (!text) return;
    }

    resize(range, option){
        if(range){
            const selectedText = range.extractContents()
            if(option === 'R'){
                if(this.spanTagExists){
                    this.spanTagExists.remove()
                    range.insertNode(selectedText);
                }
                else{
                    range.insertNode(selectedText)
                }
            }
            else if(option !== 'R'){     
                if(this.spanTagExists){
                    if(this.spanTagExists?.classList[0] === option){
                        this.spanTagExists.remove()
                        range.insertNode(selectedText)
                    }
                    else{
                        this.spanTagExists.remove()
                        const tag = document.createElement('SPAN')
        
                        tag.classList.add(option)                
                        tag.appendChild(selectedText)
                        range.insertNode(tag)
                        this.api.selection.expandToTag(tag);
                    }
                }
                else{
                    console.log('Else')
                    const tag = document.createElement('SPAN')
    
                    tag.classList.add(option)                
                    tag.appendChild(selectedText)
                    range.insertNode(tag)
                    this.api.selection.expandToTag(tag);
                }
            }
            this.api.inlineToolbar.close();
        }
    }

    surround(range){
        this.spanTagExists = this.api.selection.findParentTag('SPAN')
        this.selectedText = range.cloneContents();
        this.actions.hidden = !this.actions.hidden;
        this.range = !this.actions.hidden ? range : null;
        this.state = !this.actions.hidden;
    }

    renderActions(){
        this.actions = document.createElement('div');
        this.actions.classList.add(this.CSS.actions);
        const actionsToolbar = document.createElement('div');
        actionsToolbar.classList.add(this.CSS.toolbarLabel);
        actionsToolbar.innerHTML = 'Resize';
        
        this.actions.appendChild(actionsToolbar);
        
        this.optionButtons = Object.keys(this.headingOptions).map(option => {
            const btnOption = document.createElement('div');
            btnOption.classList.add(this.CSS.tool);
            btnOption.dataset.mode = option;
            btnOption.innerHTML = this.headingOptions[option];
            return btnOption
        })

        for (const btnOption of this.optionButtons) {
            this.actions.appendChild(btnOption);
            this.api.listeners.on(btnOption, 'click', () => {
                this.resize(this.range,btnOption.dataset.mode)
            });
        }

        this.actions.hidden = true;
        return this.actions;
    }

    destroy(){        
        for (const btnOption of this.optionButtons) {
            this.api.listeners.off(btnOption, 'click');
        }
    }
}
