import React, { useState } from 'react';

// Modules
import Checkbox from '@mui/material/Checkbox';

export default function CheckBox({ defaultValue, toggleChecked, variant, size, color }) {
  let default_value = false;

  if (defaultValue === true) {
    default_value = true;
  }

  const [checked, setChecked] = useState(default_value);

  return (
    <Checkbox
      variant={variant ? variant : 'primary'}
      checked={checked}
      size={size}
      color={color}
      onChange={(event) => {
        setChecked(event.target.checked);
        toggleChecked(event.target.checked);
      }}
    />
  )
}