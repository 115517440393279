const defaultState = {
  showHeader: true,
};

export const headerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'TOGGLE_HEADER':
      return {
        showHeader: action.payload,
      };

    default:
      return state;
  }
};