import React, { useState, useEffect } from "react";

// Modules
import TimeAgo from "react-timeago";
import moment from "moment";

// App
import { serverUrl } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import { postNode } from "../../core/postNode";
import { deleteRequest } from "../../core/delete";

// UI
import { Row, Col } from "react-bootstrap";
import { Button, Tooltip, Typography, Avatar } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";

export default function Comment(props) {
  const [isLoading, setLoading] = useState(true);
  const [hasReacted, setReacted] = useState(false);
  const [id, setId] = useState(null);
  const [likes, setLikes] = useState(0);
  const isAllowed = props.comment.uid === props.user.current_user.uid ? false : true;

  useEffect(() => {
    getReactions();
  }, []);

  const getReactions = () => {
    let likes = 0;

    getOnlineNode(`api/v2/comments/reactions/${props.comment.cid}`, props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          response.data.rows.forEach((like) => {
            likes = likes + 1;

            if (like.user_id === props.user.current_user.uid) {
              setReacted(true);
              setId(like.id);
            }
          });

          setLikes(likes);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const addReaction = () => {
    setLoading(true);

    const data = {
      _links: {
        type: {
          href: serverUrl + "/rest/type/vote/comment_likes",
        },
      },
      entity_id: [
        {
          target_id: props.comment.cid,
        },
      ],
      entity_type: [
        {
          value: "comment",
        },
      ],
    };

    postNode("entity/vote", data, props.user.csrf_token)
      .then((response) => {
        setReacted(true);
        setId(response.data.id[0].value);
        setLikes(likes + 1);
        getReactions();
      })
      .catch((_error) => {
        console.log("@addReaction [comment]: ", _error);
        setLoading(false);
      });
  };

  const removeReaction = () => {
    if (id) {
      setLoading(true);
      deleteRequest(
        `entity/vote/${id}?_format=json`,
        props.user.csrf_token,
        props.user.access_token
      )
        .then((response) => {
          setReacted(false);
          setId(null);
          setLikes(likes - 1);
          getReactions();
        })
        .catch((_error) => {
          console.log("@_error: ", _error);
          setLoading(false);
        });
    }
  };

  return (
    <Row className={`comment gx-0 cid-${props.comment.cid}`}>
      <Col>
        <Row className="comment-header gx-0 d-flex justify-content-between align-items-center">
          <Col className="comment-author">
            <Row className="gx-0 d-flex align-items-center">
              <Col xs={"auto"} className="comment-avatar me-2">
                <Avatar className="comment-author-avatar"></Avatar>
              </Col>
              <Col xs={"auto"} className="comment-username">
                <Typography
                  owner="client"
                  variant="h6"
                  weight="regular"
                  className="comment-author-username"
                >
                  {props.comment.name}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col xs={"auto"} className="comment-date">
            <TimeAgo date={moment.unix(props.comment.created)} />
          </Col>
        </Row>
        <Row className="comment-body gx-0">
          <Typography
            owner="client"
            variant="body1"
            className="comment-content"
          >
            {props.comment.comment_body}
          </Typography>
        </Row>
        <div className="comment_reactions">
          {isAllowed ? (
            <Button
              disabled={isLoading}
              variant="text"
              className={hasReacted ? "like reacted" : "like"}
              onClick={() => {
                if (hasReacted) {
                  removeReaction();
                } else {
                  addReaction();
                }
              }}
            >
              {hasReacted ? (
                <ThumbUpIcon color="commentLike" fontSize="small" />
              ) : (
                <ThumbUpOffAltIcon color="commentLike" fontSize="small" />
              )}
              <span
                style={{ marginLeft: 5 }}
                className={`comment_reactions_counter comment_reactions_like_counter ${
                  hasReacted ?? "reacted"
                }`}
              >
                {likes}
              </span>
            </Button>
          ) : (
            <Tooltip title="You can't like your own comment">
              <Button disabled={true} variant="text" className={"like"} sx={{pointerEvents: 'initial !important'}}>
                <ThumbUpOffAltIcon fontSize="small" />
                <span
                  sx={{ marginLeft: 5 }}
                  className={`comment_reactions_counter comment_reactions_like_counter`}>
                  {likes}
                </span>
              </Button>
            </Tooltip>
          )}
          
        </div>
      </Col>
    </Row>
  );
}
