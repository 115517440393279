import { getOnlineNode } from './getNode';

const getUser = async () => {
  try {
    const response = await getOnlineNode('/api/user_details?_format=json');
    if (response.data ) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export default getUser;