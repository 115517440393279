import { createContext, useState, useContext, useRef } from "react";

const SlideContext = createContext();

export const useSlideContext = () => useContext(SlideContext);

export const SlideContextProvider = ({ children }) => {
  const [activeSlideTitle, setActiveSlideTitle] = useState("");
  const [modifiedSlideTitle, setModifiedSlideTitle] = useState(false);
  const [activeSlideLayout, setActiveSlideLayout] = useState('');

  const toggleGridFunctionRef = useRef(null);

  const registerToggleGridFunction = (fn) => {
    toggleGridFunctionRef.current = fn;
  }

  const triggerToggleGrid = () => {
    if (toggleGridFunctionRef.current) {
      toggleGridFunctionRef.current();
    }
  }

  return (
    <SlideContext.Provider value={{ activeSlideTitle, setActiveSlideTitle, modifiedSlideTitle, setModifiedSlideTitle, activeSlideLayout, setActiveSlideLayout, registerToggleGridFunction, triggerToggleGrid }}>
      {children}
    </SlideContext.Provider>
  );
}