// Dependencies
import React, { useState } from "react";

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AlertTriangle, CheckCircle, Info } from "react-feather";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import logo from "../assets/ca-logo.png"
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import DialogPaper from "./dialogPaper";

export default function AlertModal(props) {

  const [modalVisible, setModalVisible] = useState(props.showAlert);

  /**
   * Render action buttons (Cancel / OK)
   * @param {*} alertType 
   * @returns 
   */
  const renderAlertActions = (alertType) => {
    return (
      <Row className="g-0">
        {props.cancelButton && (
          <Col className="pe-3">
            <Button
              variant="outlined"
              owner="admin"
              dialogbtn="true"
              dialogvariant="cancel"
              onClick={() => {
                setModalVisible(false);
                props.showAlertCallback(false);

                if (props.onCancel) {
                  props.onCancel();
                }
              }}
            >
              <Typography
                variant="button"
                owner="admin"
                className="lh"
              >
                {props.cancelButtonLabel
                  ? props.cancelButtonLabel
                  : "Cancel"}
              </Typography>
            </Button>
          </Col>
        )}

        {props.confirmButton && (
          <Col className="ps-3">
            <Button
              variant="contained"
              owner="admin"
              dialogbtn="true"
              dialogvariant={props.confirmButtonColor ?
                props.confirmButtonColor :
                "primary"
              }
              className="lh"
              onClick={() => {
                setModalVisible(false);
                if (props.showAlertCallback) {
                  props.showAlertCallback(false);
                }
                if (props.onConfirm) {
                  props.onConfirm();
                }
              }}
            >
              <Typography
                variant="button"
                owner="admin"
                className="lh"
              >
                {props.confirmButtonLabel
                  ? props.confirmButtonLabel
                  : "OK"}
              </Typography>
            </Button>
          </Col>
        )}
      </Row>
    );
  }

  /**
   * Render the alert modal
   * @returns 
   */
  const renderAlert = () => {

    const iconSelector = {
      success: <CheckCircle />,
      info: <Info />,
      error: <AlertTriangle />,
    }

    let alertType = "default";
    let icon = iconSelector.info;

    if (props.alertType !== "undefined") {
      alertType = props.alertType;
    }

    if (props.alertType === "success") {
      icon = iconSelector.success;
    }
    if (props.alertType === "warning") {
      icon = iconSelector.info;
    }
    if (props.alertType === "error") {
      icon = iconSelector.error;
      alertType = "danger"; // set to bootstrap colours
    }

    return (
      <Dialog
        open={props.showAlert}
        onClose={() => {
          if (props.onclose) {
            props.onClose()
          }
          if (props.showAlertCallback) {
            props.showAlertCallback(false)
          }
          setModalVisible(false)
        }
        }
        PaperComponent={DialogPaper}
        className="alert-dialog"
      // centered
      // dialogClassName={`alert-modal--${alertType} alert-modal`}
      >
        <DialogTitle
          // className={`d-flex justify-content-center text-${alertType}`}
          className={`d-flex justify-content-center align-items-center dialog-draggable-handle`}
        >
          {props.showCaLogo &&
            <img className="ca-logo" src={logo} />
          }
          {props.warningIcon &&
            <WarningIcon className="me-2" color={props.warningIconColor ?? "adminPrimary"} />
          }
          <Typography
            owner="admin"
            variant="h5"
            color="adminText.dark"
          >
            {props.alertMessageTitle}
          </Typography>
          {/* <Typography variant="h5" color="modal.dark">
            {props.alertMessageTitle}
          </Typography> */}
        </DialogTitle>
        <DialogContent className="text-center">
          <Typography className="mb-0"
            owner="admin"
            variant="h6"
            color="adminText.dark"
            fontWeight="light"
            lh="wide"
          >
            {props.alertMessageBody}
          </Typography>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-2">
          {renderAlertActions(alertType)}
        </DialogActions>
      </Dialog>
    );
  }

  if (props.showAlert) {
    return renderAlert();
  } else {
    return false;
  }
}

