import { HotelLocation, LocationHeaderIcon } from '../../../icons/icons'

/**
 * Build styles
 */
require('./index.css').toString();

export default class LocationHeader {

  static get toolbox() {
    return {
      icon: LocationHeaderIcon,
      title: 'Location Header',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      br: true,
    };
  }

  static get DEFAULT_COUNTRY_PLACEHOLDER() {
    return 'Country'
  }

  static get DEFAULT_CITY_PLACEHOLDER() {
    return 'City'
  }

  static get DEFAULT_NAME_PLACEHOLDER() {
    return 'Hotel name'
  }


  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-hotel-heading-container',
      contentContainer: 'cdx-hotel-heading-content-container',
      wrapper: 'cdx-hotel-heading',
      input: 'cdx-hotel-heading-input',
      locationIcon: 'location-pin-icon',
      country: 'hotel-country',
      city: 'hotel-city',
      hotelName: 'hotel-name',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;


    this._data = {
      country: data.country || '',
      city: data.city || '',
      hotelName: data.hotelName || '',
    };
    this._container = ''
    this._countryPlaceHolder = config.countryPlaceHolder ? config.countryPlaceHolder : LocationHeader.DEFAULT_COUNTRY_PLACEHOLDER;
    this._cityPlaceHolder = config.cityPlaceHolder ? config.cityPlaceHolder : LocationHeader.DEFAULT_CITY_PLACEHOLDER;
    this._hotelNamePlaceHolder = config.hotelNamePlaceHolder ? config.hotelNamePlaceHolder : LocationHeader.DEFAULT_NAME_PLACEHOLDER;
    this._locationIcon = this.getLocationIcon()
    this._hotelCountry = this.getHotelCountry();
    this._hotelCity = this.getHotelCity();
    this._hotelName = this.getHotelName();
    if (!this.readOnly) {
      this._onKeyUp = this.onKeyUp.bind(this);
      this._onKeyDown = this.onKeyDown.bind(this);
    }
  }

  getHotelCountry() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._countryPlaceHolder);
    tag.innerHTML = this._data.country || '';
    tag.classList.add(this._CSS.country, this._CSS.input);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._hotelCountry);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getHotelCity() {
    let tag = document.createElement('h5');

    tag.dataset.placeholder = this.api.i18n.t(this._cityPlaceHolder);
    tag.innerHTML = this._data.city || '';
    tag.classList.add(this._CSS.city, this._CSS.input);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._hotelCity);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getHotelName() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._hotelNamePlaceHolder);
    tag.innerHTML = this._data.hotelName || '';
    tag.classList.add(this._CSS.hotelName, this._CSS.input);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._hotelName);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getLocationIcon() {
    let tag = document.createElement('div');
    tag.classList.add(this._CSS.locationIcon)
    tag.innerHTML = HotelLocation

    return tag
  }

  onKeyUp(e, element) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = element;

    if (textContent === '') {
      element = '';
    }
  }

  onKeyDown(e, element) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.addLineBreak(element);
    }
  }

  addLineBreak(element) {
    const selection = window.getSelection(); // get the current selection.
    const range = selection.getRangeAt(0); // get the current range of the selection.
    const brTags = element.querySelectorAll('br');

    // we need to create 2x tags initially, 1x tag will not do anything
    // this is the default browser behavior with Enter + Shift as well
    if (brTags.length === 0) {
      const br1 = document.createElement('br');
      const br2 = document.createElement('br');
      range.insertNode(br1); // inserts the <br> element at the current cursor position.
      range.insertNode(br2); // inserts the <br> element at the current cursor position.
      range.setStartAfter(br2); // set the start of the range to the position after the <br> element.
    } else {
      const br = document.createElement('br');
      range.insertNode(br);
      range.setStartAfter(br); // set the start of the range to the position after the <br> element.
    }

    range.collapse(true); // collapse the range to the position after the <br> element.
    selection.removeAllRanges(); // remove any existing ranges from the selection.
    selection.addRange(range); // add the modified range to the selection.
  }

  render() {
    this._container = document.createElement('div');
    this._container.classList.add(this._CSS.container);
    let contentContainer = document.createElement('div');
    contentContainer.classList.add(this._CSS.contentContainer);


    contentContainer.appendChild(this._locationIcon);
    contentContainer.appendChild(this._hotelCountry);
    contentContainer.appendChild(this._hotelCity);
    contentContainer.appendChild(this._hotelName);
    this._container.appendChild(contentContainer);

    return this._container;
  }

  save() {
    this._data.country = this._hotelCountry.innerHTML;
    this._data.city = this._hotelCity.innerHTML;
    this._data.hotelName = this._hotelName.innerHTML;

    return this._data;
  }
}