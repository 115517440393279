import React, { useState, useEffect } from 'react';

// Modules
import { decode } from 'html-entities';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FileText } from 'react-feather';
import { Link } from 'react-router-dom';
import { FormControlLabel, Switch, Button, Typography, IconButton, Tooltip, Container } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkedIcon from '@mui/icons-material/Bookmark';

import GridIcon from '@mui/icons-material/ViewModuleOutlined';
import ListIcon from '@mui/icons-material/ViewListOutlined';

export default function PageHeader(props) {
  const [gridView, setGridView] = useState(props.gridView);

  useEffect(() => {
    if (props.viewToggle) {
      props.handleViewToggle(gridView)
    }
  }, [gridView])

  const renderFilters = () => {

    if (props.filters) {
      return (
        <Col
          xs={12}
          sm={3}
          className='mt-4 mt-xl-0 spacer ps-sm-0'
        >
          <Row
            className='page-header__filters justify-content-xl-end align-items-end g-0'
          >
            <Col
              xs={12}
            >
              {props.categorySelector}
            </Col>
          </Row>
        </Col>
      );
    }
  };

  const renderFavorites = () => {
    return (
      <Col xs={'auto'} className='me-1'>
        <Button
          className={`p-0 d-flex align-items-center`}
          disableFocusRipple={true}
          disableRipple={true}
          onClick={() => {
            if (props.getFavourites) {
              props.getFavourites();
            }
          }}
          endIcon={
            props.activeTabIndex === 1 ?
              <BookmarkedIcon className='ms-1 me-0' sx={{ fontSize: '1rem' }} /> :
              <BookmarkIcon className='ms-1 me-0' sx={{ fontSize: '1rem' }} />
          }
        >
          <Typography
            className='d-none d-sm-block'
            owner={props.owner}
            variant='button'
          >
            Favourites
          </Typography>
        </Button>
        {props.getArchives && (
          <Button
            className={
              props.activeTabIndex === 2
                ? 'filter-btn active'
                : 'filter-btn'
            }
            onClick={() => {
              if (props.getArchives) {
                props.getArchives();
              }
            }}
          >
            <FileText />
            <span>Archive</span>
          </Button>
        )}
      </Col>
    );
  }

  const listHeaderLabel = (item) => {
    return (
      <Col
        className={`list-header-label-column ${item.grow ? `flex-grow-${item.grow}` : ''} ${item?.className ? item.className : ''}`}
        xs={item?.width.xs ? item.width.xs : ''}
        sm={item?.width.sm ? item.width.sm : ''}
        md={item?.width.md ? item.width.md : ''}
        lg={item?.width.lg ? item.width.lg : ''}
        xl={item?.width.xl ? item.width.xl : ''}
        xxl={item?.width.xxl ? item.width.xxl : ''}
        style={item?.style ? item.style : {}}
      >
        <Typography
          owner={props.owner}
          variant={props.listHeaderLabel?.variant}
          color={props.listHeaderLabel?.color}
          weight={props.listHeaderLabel?.weight}
          lh={props.listHeaderLabel?.lh}
          className='list-header-label'
          align={item.align ? item.align : ''}
        >
          {item.title}
        </Typography>
      </Col>
    )
  }

  let pageName = props.pageName;
  try {
    pageName = decode(pageName)
  } catch (error) {
    // console.log('cannot decode')
  }

  return (
    <Container owner={props.owner} variant='screenHeader' className={`page-header px-0 ${props.listHeader ? 'mb-0' : ''}`}>
      <Row className='align-items-end'>
        <Col className='page-name'>
          <Row className='align-items-end justify-content-between'>
            <Col xs='auto' className='px-0'>
              <Row className='gx-0'>
                {props.pageLogo &&
                  <Col xs='auto' className='d-flex justify-content-center align-items-center pe-2'>
                    {props.svgLogo ?
                      <div className='page-logo' dangerouslySetInnerHTML={{ __html: props.pageLogo }}></div> :
                      <img src={props.pageLogo} alt='' className='page-logo' />
                    }
                  </Col>
                }
                <Col className='d-flex align-items-end'>
                  <Row className='gx-0'>
                    <Typography
                      className='mb-0'
                      variant='h1'
                      owner={props.owner ? props.owner : 'admin'}
                    >
                      {pageName}
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs='auto' className='px-0'>
              <Row className='align-items-center'>
                <Col xs='auto' className={props.enableFavorites ? 'pe-0' : ''}>
                  {props.viewToggle &&
                    <Button
                      className='d-flex p-0 align-items-center'
                      size='small'
                      disableFocusRipple={true}
                      disableRipple={true}
                      onClick={() =>
                        setGridView((prevState) => !prevState)
                      }
                      endIcon={gridView ?
                        <ListIcon
                          className='ms-1'
                          sx={{ fontSize: '1rem' }}
                        />
                        :
                        <GridIcon
                          className='ms-1'
                          sx={{ fontSize: '1rem' }}
                        />
                      }
                    >
                      <Tooltip
                        title={gridView ? 'List View' : 'Grid View'}
                        placement='left'
                      >
                        <Typography
                          className='d-none d-sm-block'
                          owner={props.owner}
                          variant='button'
                        >
                          View as
                        </Typography>
                      </Tooltip>
                    </Button>
                  }
                </Col>
                {props.enableFavorites && renderFavorites()}
              </Row>
            </Col>
            {props.toggleSwitch &&
              <Link to={props.switchLink}>
                <FormControlLabel label='Edit Mode' control={<Switch id='edit-switch' defaultChecked={props.switchStatus} />} />
              </Link>
            }
          </Row>
        </Col>
        {renderFilters()}
      </Row>
      {props.listHeader &&
        <Row className='list-header-label-container pt-4 pb-0 me-0'>
          {props.listLabels.length > 0 &&
            props.listLabels.map((item) => {
              return listHeaderLabel(item)
            })
          }
        </Row>
      }
    </Container>
  );
}

