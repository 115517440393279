import React from "react";

// Modules
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// App
import { getOnlineNode } from "../../core/getNode";
import Reactions from "./reactions";
import Comment from "./comment";
import CommentForm from "./commentForm";

// UI
import { Row, Col } from "react-bootstrap";
import { Typography, CircularProgress } from "@mui/material";
import CommentIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import TimeIcon from "@mui/icons-material/AccessTimeOutlined";

const pageSize = 10;

export default function CommentSection(props) {
  const user = useSelector((state) => state).authReducer.user;
  const [comments, setComments] = useState([]);

  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getComments();
  }, []);

  const getComments = () => {
    if (props.nid) {
      const path = `api/v2/comments/${props.nid}?items_per_page=${pageSize}&page=${currentPage}`;

      getOnlineNode(path, user.access_token)
        .then((response) => {
          setComments(response.data.rows);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  if (isLoading) {
    return (
      <Col className="w-100">
        <Row className="comment-section-container gx-0 justify-content-center">
          <CircularProgress size={18} />
        </Row>
      </Col>
    );
  } else {
    return (
      <Col className="w-100">
        <Row className="comment-section-container gx-0">
          <Col>
            {props.field_allow_reactions === "true" && (
              <Row className="reaction-container gx-0">
                <Reactions
                  user={user}
                  nid={props.nid}
                  field_allow_reactions={props.field_allow_reactions}
                />
              </Row>
            )}

            {props.field_allow_comments === "true" && (
              <Row className="comments-container gx-0">
                <Col>
                  <Typography
                    owner="client"
                    variant="h2"
                    className="mb-3  d-flex align-items-center"
                    color="clientText.main"
                  >
                    <CommentIcon className="me-2" sx={{ fontSize: "2rem" }} />
                    Comments
                  </Typography>
                  <CommentForm
                    user={user}
                    nid={props.nid}
                    onCommentPosted={(data) => {
                      getComments();
                    }}
                  />
                  {comments.length > 0 && (
                    <Row className="gx-0 mb-3">
                      <Typography
                        className=" d-flex align-items-center"
                        owner="client"
                        variant="h5"
                        color="clientText.main"
                      >
                        <TimeIcon
                          className="me-2"
                          sx={{ fontSize: "1.5rem" }}
                        />
                        Most Recent
                      </Typography>
                    </Row>
                  )}
                  {comments.map((e, i) => {
                    return <Comment key={`c-${i}`} user={user} comment={e} />;
                  })}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    );
  }
}
