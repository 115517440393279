/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// Modules
import Slider from 'react-slick';
import * as DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';

// App
import { getOnlineNode } from '../../../core/getNode';
import { patchNode } from '../../../core/postNode';
import { alertMessages } from '../../../partials/alertMessages';
import CheckBox from '../../../partials/mui/checkbox';

// UI components
import { Container, Row, Col } from 'react-bootstrap';
import { Close, Delete } from '@mui/icons-material';

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import TabsUnstyled from '@mui/base/TabsUnstyled';
// import TabsListUnstyled from '@mui/base/TabsListUnstyled';
// import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
// import TabUnstyled from '@mui/base/TabUnstyled';
// import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
// import LoadingButton from '@mui/lab/LoadingButton';
// import Textfield from '@mui/material/TextField';
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Button,
  Stack,
  CircularProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export default function Permissions(props) {
  const [userPermissions, setUserPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [accessCollectionPermissions, setAccessCollectionPermissions] = useState([]);
  const [accessCollectionList, setAccessCollectionList] = useState([]);

  const [selectedType, setSelectedType] = useState('0');

  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [isCollapsed, setCollapsed] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isTyping, setTyping] = useState(false);
  const [isSearching, setSearching] = useState(false);

  const user = useSelector((state) => state).authReducer.user;

  const api_endpoints = {
    users: `users?_format=json&first_name=${searchTerm}&last_name=${searchTerm}&name=${searchTerm}`,
    permissions: `api/presentation_permissions/${props.item.id}`,
  };

  const hideModal = () => {
    props.onHide();
  }

  const setSearch = value => {
    setSearchTerm(value);
    setTyping(false);
    setSearching(true);
  }

  const addUser = user => {
    const row = {
      user: {
        name: [{
          value: user.name,
        }],
        field_first_name: [{
          value: user.field_first_name,
        }],
        field_last_name: [{
          value: user.field_last_name,
        }],
        uid: [{
          value: user.uid,
        }]
      },
      permissions: {
        delete: null,
        edit: null,
        target_id: user.uid,
        target_type: 'user',
        view: true
      }
    }

    setUserPermissions(oldState => [...oldState, row]);
  };

  useEffect(() => {
    if (!isTyping && searchTerm.length > 0) {
      getOnlineNode(api_endpoints.users)
        .then(response => {
          if (response.data.length > 0) {
            setUsers(response.data);
            setTyping(false);
            setSearching(false);
            setError(false);
          } else {
            setError(true);
            setUsers([]);
            setTyping(false);
            setSearching(false);
          }
        })
        .catch(_error => {
          setError(true);
          setUsers([]);
          setTyping(false);
          setSearching(false);
        });
    } else {
      setUsers([]);
      setTyping(false);
      setSearching(false);
      setError(false);
    }
  }, [isTyping, searchTerm, api_endpoints.users]);

  useEffect(() => {
    getPermissions(api_endpoints.permissions);
  }, [api_endpoints.permissions]);

  const getPermissions = (endpoint) => {
    getOnlineNode(endpoint)
      .then(response => {
        setUserPermissions(response.data?.users);
        setRolePermissions(response.data?.roles);
        setRoleList(response.data.role_list);
        setAccessCollectionPermissions(response.data.access_collection);
        setAccessCollectionList(response.data.access_collection_list);
        setLoading(false);
      })
      .catch(_error => {
        setUserPermissions([]);
        setRolePermissions([]);
        setRoleList([]);
        setAccessCollectionPermissions([]);
        setAccessCollectionList([]);
        setLoading(false);
      });
  };

  const updatePermissions = () => {
    setLoading(true);

    let users = [];
    let roles = [];
    let access_collections = [];

    userPermissions.forEach(row => {
      users.push(row.permissions);
    });

    for (const key in rolePermissions) {
      const row = rolePermissions[key];

      roles.push(row.permissions);
    }

    for (const key in accessCollectionPermissions) {
      const row = accessCollectionPermissions[key];

      access_collections.push(row.permissions);
    }

    const data = {
      users: users,
      roles: roles,
      access_collections: access_collections,
    };

    patchNode('api/presentation_permissions/' + props.item.id, data, user.csrf_token)
      .then(_response => {
        getPermissions(api_endpoints.permissions);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  const renderItem = item => {
    let itemStyle = '';

    let isSelected = false;

    userPermissions.forEach(user => {
      if (user?.permissions?.target_id === item.uid) {
        itemStyle = 'selected-user';
        isSelected = true;
      }
    });

    let name = '';

    if (item.field_first_name) {
      name = item.field_first_name;
    }

    if (item.field_last_name) {
      name = name + ' ' + item.field_last_name;
    }

    name = name + ' (<b>' + item.name + '</b>)';

    return (
      <Col>
        <Button
          className={`user ${itemStyle}`}
          disabled={isSelected ? true : false}
          onClick={() => {
            addUser(item);
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(name) }} />
        </Button>
      </Col>
    );
  };

  const renderUsers = (users) => {
    let new_user_permissions = users;

    return (
      <TableContainer component={Paper}>
        <Table key='user_table' aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell align='right'>View</TableCell>
              <TableCell align='right'>Edit</TableCell>
              <TableCell align='right'>Delete</TableCell>
              <TableCell align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row, index) => (
              <TableRow
                key={row?.user?.uid[0]?.value}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {`${row?.user?.field_first_name[0]?.value ? row?.user?.field_first_name[0]?.value : ''} ${row?.user?.field_last_name[0]?.value ? row?.user?.field_last_name[0]?.value : ''}`} <b>({`${row?.user?.name[0]?.value}`})</b>
                </TableCell>
                <TableCell align='right'>
                  <CheckBox
                    defaultValue={row?.permissions?.view}
                    toggleChecked={value => {
                      let permissions = row.permissions;
                      permissions.view = value;

                      const new_row = {
                        user: row.user,
                        permissions: permissions,
                      };

                      new_user_permissions[index] = new_row;
                      setUserPermissions(new_user_permissions);
                    }}
                  />
                </TableCell>
                <TableCell align='right'>
                  <CheckBox
                    defaultValue={row?.permissions?.edit}
                    toggleChecked={value => {
                      let permissions = row.permissions;
                      permissions.edit = value;

                      const new_row = {
                        user: row.user,
                        permissions: permissions,
                      };

                      new_user_permissions[index] = new_row;
                      setUserPermissions(new_user_permissions);
                    }}
                  />
                </TableCell>
                <TableCell align='right'>
                  <CheckBox
                    defaultValue={row?.permissions?.delete}
                    toggleChecked={value => {
                      let permissions = row.permissions;
                      permissions.delete = value;

                      const new_row = {
                        user: row.user,
                        permissions: permissions,
                      };

                      new_user_permissions[index] = new_row;
                      setUserPermissions(new_user_permissions);
                    }}
                  />
                </TableCell>
                <TableCell align='right'>
                  <IconButton color='error' aria-label='upload picture' component='label' onClick={() => {
                    let updated = [];
                    new_user_permissions.forEach((element, _index) => {
                      if (_index !== index) {
                        updated.push(element);
                      }
                    });

                    setUserPermissions(updated);
                  }}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderRoles = (roles, roleList) => {
    let new_role_permissions = roles;
    let new_data = {};

    for (const key of Object.keys(roleList)) {
      if (roleList[key].id !== 'anonymous') {
        if (new_role_permissions[key]) {
          new_data[key] = new_role_permissions[key]
        } else {
          new_data[key] = {
            permissions: {
              delete: null,
              edit: null,
              target_id: roleList[key]?.id,
              target_type: 'user_role',
              view: null
            },
            role: roleList[key],
          }
        }
      }
    }

    return (
      <TableContainer component={Paper}>
        <Table key='role_table' aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell align='right'>View</TableCell>
              <TableCell align='right'>Edit</TableCell>
              <TableCell align='right'>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(new_data).map((key, index) => {
              const row = new_data[key];
              return (
                <TableRow
                  key={row?.role?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.role?.label}
                  </TableCell>
                  <TableCell align='right'>
                    <CheckBox
                      defaultValue={row?.permissions?.view}
                      toggleChecked={value => {
                        let permissions = row.permissions;
                        permissions.view = value;

                        const new_row = {
                          role: row.role,
                          permissions: permissions,
                        };

                        new_role_permissions[key] = new_row;
                        setRolePermissions(new_role_permissions);
                      }}
                    />
                  </TableCell>
                  <TableCell align='right'>
                    <CheckBox
                      defaultValue={row?.permissions?.edit}
                      toggleChecked={value => {
                        let permissions = row.permissions;
                        permissions.edit = value;

                        const new_row = {
                          role: row.role,
                          permissions: permissions,
                        };

                        new_role_permissions[key] = new_row;
                        setRolePermissions(new_role_permissions);
                      }}
                    />
                  </TableCell>
                  <TableCell align='right'>
                    <CheckBox
                      defaultValue={row?.permissions?.delete}
                      toggleChecked={value => {
                        let permissions = row.permissions;
                        permissions.delete = value;

                        const new_row = {
                          role: row.role,
                          permissions: permissions,
                        };

                        new_role_permissions[key] = new_row;
                        setRolePermissions(new_role_permissions);
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderAccessCollections = (access_collection, accessCollectionList) => {
    let new_access_collection_permissions = access_collection;
    let new_data = {};

    for (const key of Object.keys(accessCollectionList)) {
      if (new_access_collection_permissions[key]) {
        new_data[key] = new_access_collection_permissions[key];
      } else {
        new_data[key] = {
          permissions: {
            delete: null,
            edit: null,
            target_id: accessCollectionList[key]?.id,
            target_type: 'access_collection',
            view: null
          },
          access_collection: accessCollectionList[key],
        }
      }
    };

    return (
      <TableContainer component={Paper}>
        <Table key='role_table' aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Access Collection</TableCell>
              <TableCell align='right'>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(new_data).map((key, index) => {
              const row = new_data[key];
              return (
                <TableRow
                  key={row?.access_collection?.id[0]?.value}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.access_collection?.title[0]?.value}
                  </TableCell>
                  <TableCell align='right'>
                    <CheckBox
                      defaultValue={row?.permissions?.view}
                      toggleChecked={value => {
                        let permissions = row.permissions;
                        permissions.view = value;

                        const new_row = {
                          access_collection: row.access_collection,
                          permissions: permissions,
                        };

                        new_access_collection_permissions[key] = new_row;
                        setRolePermissions(new_access_collection_permissions);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Dialog
      open={props.modalVisible}
      onClose={() => hideModal}
      className='permissions-modal'
      fullScreen
    >
      <AppBar sx={{ position: 'relative', backgroundColor: 'primary.light', color: 'solid.dark' }}>
        <DialogContent sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Access permissions
            </Typography>
            
            <Row className="g-0 align-items-center">
              <Col>
                <Stack direction='row' spacing={2}>
                  <Button
                    sx={{
                      backgroundColor: 'primary.contrastText',
                      width: '130px',
                      height: '35px',
                      textTransform: 'none',
                      fontWeight: '400'
                    }}
                    variant='outlined'
                    className="cancel-btn"
                    onClick={() => {
                      hideModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    sx={{
                      backgroundColor: 'primary.confirm',
                      width: '130px',
                      height: '35px',
                      textTransform: 'none',
                      fontWeight: '400'
                    }}
                    size='small'
                    onClick={updatePermissions}
                    loading={isLoading}
                    loadingPosition='center'
                    variant='contained'
                  >
                    {isLoading ? '' : `Save`}
                  </LoadingButton>
                </Stack>
              </Col>
            </Row>
            {/* <IconButton
              edge="start"
              color="inherit"
              onClick={() => hideModal()}
              aria-label="close"
            >
              <Close />
            </IconButton> */}
          </Toolbar>
        </DialogContent>
      </AppBar>

      <DialogContent>
        <Container fluid>
          <Row className='mb-5'>
            <Col>
              <Tabs
                value={selectedType}
                onChange={(event, newValue) => {
                  setSelectedType(newValue);
                }}
                aria-label='Layout categories'
              >
                <Tab value={'0'} label={'Users'} />
                <Tab value={'1'} label={'Roles'} />
                <Tab value={'2'} label={'Access Collections'} />
              </Tabs>
            </Col>
          </Row>
          <Row>
            <Col className={selectedType === '0' ? `` : `d-none`}>
              <div>
                {renderUsers(userPermissions)}
                <section className="users-section">
                  <div className="form-group">
                    <Row className="mb-1">
                      <Col>
                        <label className='search-label'>Search users</label>
                      </Col>
                      <Col xs={"auto"} className="d-flex align-items-center mb-1">
                        {isSearching && (
                          <CircularProgress />
                        )}
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <Col>
                        <TextField
                          key='name'
                          id='user-search'
                          label='Type a name'
                          variant='outlined'
                          autoComplete='off'
                          onChange={(event) => {
                            event.preventDefault();
                            setSearch(event.target.value);
                          }}
                          value={searchTerm} />
                      </Col>
                    </Row>

                    {users && users.length > 0 ? (
                      <div className="users-container">
                        {users.map((item, index) => {
                          return (
                            <Row key={`user-${index}`}>{renderItem(item)}</Row>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        {isError && (
                          <div className="error-message">
                            <span>{errorMessage}</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </section>
              </div>
            </Col>
            <Col className={selectedType === '1' ? `` : `d-none`}>
              <div>
                {renderRoles(rolePermissions, roleList)}
              </div>
            </Col>
            <Col className={selectedType === '2' ? `` : `d-none`}>
              <div>
                {renderAccessCollections(accessCollectionPermissions, accessCollectionList)}
              </div>
            </Col>
          </Row>
        </Container>
      </DialogContent>
      


      {/* <DialogContent>
        <Row>
          <Col xs={12}>
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <TabsUnstyled className='tabs' defaultValue={0}>
                    <TabsListUnstyled className='tab-list'>
                      <TabUnstyled className='tab'>Users</TabUnstyled>
                      <TabUnstyled className='tab'>Roles</TabUnstyled>
                      <TabUnstyled className='tab'>Access Collections</TabUnstyled>
                    </TabsListUnstyled>
                    <TabPanelUnstyled className='tab-panel' value={0}>
                      <div>
                        {renderUsers(userPermissions)}
                        <section className="users-section">
                          <div className="form-group">
                            <Row className="mb-1">
                              <Col>
                                <label className='search-label'>Search users</label>
                              </Col>
                              <Col xs={"auto"} className="d-flex align-items-center mb-1">
                                {isSearching && (
                                  <CircularProgress />
                                )}
                              </Col>
                            </Row>

                            <Row className="mb-1">
                              <Col>
                                <TextField
                                  key='name'
                                  id='user-search'
                                  label='Type a name'
                                  variant='outlined'
                                  autoComplete='off'
                                  onChange={(event) => {
                                    event.preventDefault();
                                    setSearch(event.target.value);
                                  }}
                                  value={searchTerm} />
                              </Col>
                            </Row>

                            {users && users.length > 0 ? (
                              <div className="users-container">
                                {users.map((item, index) => {
                                  return (
                                    <Row key={`user-${index}`}>{renderItem(item)}</Row>
                                  );
                                })}
                              </div>
                            ) : (
                              <>
                                {isError && (
                                  <div className="error-message">
                                    <span>{errorMessage}</span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </section>
                      </div>
                    </TabPanelUnstyled>
                    <TabPanelUnstyled className='tab-panel' value={1}>
                      {renderRoles(rolePermissions, roleList)}
                    </TabPanelUnstyled>
                    <TabPanelUnstyled className='tab-panel' value={2}>
                      {renderAccessCollections(accessCollectionPermissions, accessCollectionList)}
                    </TabPanelUnstyled>
                  </TabsUnstyled>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Row className="g-0 align-items-center">
          <Col>
            <Stack direction='row' spacing={2}>
              <Button
                variant='outlined'
                className="cancel-btn"
                onClick={() => {
                  hideModal();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                size='small'
                color='success'
                onClick={updatePermissions}
                loading={isLoading}
                loadingPosition='center'
                variant='contained'
              >
                {isLoading ? '' : `Save`}
              </LoadingButton>
            </Stack>
          </Col>
        </Row>
      </DialogActions> */}
      <DialogActions>
        {/* <Row className="g-0 align-items-center">
          <Col>
            <Stack direction='row' spacing={2}>
              <Button
                variant='outlined'
                className="cancel-btn"
                onClick={() => {
                  hideModal();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                size='small'
                color='success'
                onClick={updatePermissions}
                loading={isLoading}
                loadingPosition='center'
                variant='contained'
              >
                {isLoading ? '' : `Save`}
              </LoadingButton>
            </Stack>
          </Col>
        </Row> */}
      </DialogActions>
    </Dialog>
  );
};
