import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, FormControlLabel, Checkbox, Typography } from '@mui/material'
import { Row, Col, Form } from 'react-bootstrap';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

export default function NotificationModal(props) {
  const [noticeIndex, setNoticeIndex] = useState(0);
  const [noticeDialog, setNoticeDialog] = useState(false);

  let navigate = useNavigate()
  let singleItem = props.singleItem
  let publishDate = new Date(props?.notifications[noticeIndex]?.published_on * 1000)
  let publishDateFormatted = `${publishDate.toLocaleString("en-GB", { day: "numeric" })} ${publishDate.toLocaleString("en-GB", { month: "long" })} ${publishDate.toLocaleString("en-GB", { year: "numeric" })}`


  useEffect(() => {
    let timeNow = Math.floor(Date.now() / 1000)

    if (!singleItem && props?.showPath === props?.currentPath) {
      if (props?.notifications.length > 0) {
        if (props.settings) {
          if (timeNow >= props?.settings?.lastReminder && timeNow >= props?.settings?.nextReminder) {
            setNoticeIndex(0);
            setNoticeDialog(true);
          }
        }
      }
      else setNoticeDialog(false);
    }
  }, [props.currentPath, props?.notifications.length, props.settings])

  // notification changes

  const handleNext = () => {
    let index = noticeIndex
    if (index + 1 < props?.notifications.length) {
      setNoticeIndex(index + 1)
    }
  }

  const handlePrev = () => {
    let index = noticeIndex
    if (index - 1 > -1) {
      setNoticeIndex(index - 1)
    }
  }

  const checkLoadingArray = () => {
    if (props.notifications.length > noticeIndex && props?.loadingArray) {
      if (props?.loadingArray.includes(props?.notifications[noticeIndex].id)) {
        return true
      }
      else return false
    }
    else return false
  }

  return (
    <Dialog
      open={singleItem ? props.dialogOpen : noticeDialog}
      maxWidth='md'
      fullWidth={true}
      className='notification-dialog'
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "720px",
          },
        },
      }}
    >
      <DialogTitle className='notification-dialog-title'>
        <Typography
          owner='admin'
          variant='h3'
          color='adminText.dark'
        >
          Notices
        </Typography>
        {!singleItem &&
          <div className='notification-nav'>
            <Button
              variant='contained'
              disabled={noticeIndex === 0}
              color='primary'
              size='small'
              onClick={() => {
                handlePrev()
              }}
            >
              <ChevronLeftRounded />
            </Button>
            {noticeIndex + 1} of {props?.notifications.length} unread
            <Button
              variant='contained'
              disabled={noticeIndex + 1 >= props?.notifications.length}
              color='primary'
              size='small'
              onClick={() => {
                handleNext()
              }}
            >
              <ChevronRightRounded />
            </Button>
          </div>
        }
        <Button
          className="me-0"
          variant="outlined"
          owner="admin"
          dialogbtn="true"
          dialogvariant="cancel"
          onClick={() => {
            if (singleItem && props.dialogClose) {
              props.dialogClose()
            }
            else if (props.settings && props.forceNextReminder) {
              props.forceNextReminder()
              setNoticeDialog(false)
            }
            else {
              setNoticeDialog(false)
            }
          }}
        >
          <Typography
            variant="button"
            owner="admin"
            className="lh"
          >
            Close
          </Typography>
        </Button>
      </DialogTitle>
      <DialogContent className='notification-dialog-content'>
        <div className='notification-content-heading'>
          <div className='notification-content-date'>
            <Typography
              owner='admin'
              variant='overline'
              color='adminPrimary.main'
              textTransform='none'
            >
              {publishDateFormatted}
            </Typography>
          </div>
          <div className='notification-checkbox-container'>
            <FormControlLabel
              className='notification-checkbox-form-control'
              label={
                <Typography
                  owner='admin'
                  variant='overline'
                  color='adminText.main'
                  textTransform='none'
                >
                  I've read this notice
                </Typography>
              }
              control={
                <>
                  {checkLoadingArray() ?
                    <div className='notification-checkbox-progress-container'>
                      <CircularProgress
                        color='primary'
                        size={12}
                        sx={{
                        }}
                      />
                    </div>
                    :
                    <Checkbox
                      className='notification-checkbox'
                      checked={singleItem ? props.readStatus : props?.notifications[noticeIndex]?.isRead}
                      onChange={(e) => {
                        if (props?.statusChange) {
                          props.statusChange(props?.notifications[noticeIndex].id, e.target.checked)
                        }
                      }}
                    />
                  }
                </>
              }
            />
          </div>
        </div>
        <div className='notification-content-container'>
          <div className='notification-content-title'>
            {props?.notifications[noticeIndex]?.label}
          </div>
          <div className='notification-content-body'>
            <div dangerouslySetInnerHTML={{ __html: props?.notifications[noticeIndex]?.content }} />
          </div>
        </div>
      </DialogContent>
      {!singleItem &&
        <DialogActions className='notification-dialog-actions'>
          <Button
            variant='text'
            onClick={() => {
              setNoticeDialog(false)
              if (props.settings && props.forceNextReminder) {
                props.forceNextReminder()
              }
              navigate('/account/notifications')
            }}
          >
            Go to notices archive
          </Button>
          <Button
            variant='text'
            onClick={() => {
              props?.markAllNotifications()
            }}
          >
            Mark all notices as read
          </Button>
        </DialogActions>
      }
    </Dialog>
  )
}