import React, { useState, useCallback, useEffect } from 'react';

// Modules
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// App
import { getOnlineNode } from '../../core/getNode';
import PageHeader from '../../partials/pageHeader';
import isLoggedIn from '../../core/isLoggedIn';
import getUser from '../../core/getUser';

// UI
import { Box, Button, Alert, CircularProgress } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { InfoOutlined } from '@mui/icons-material';
import { Row, Col } from 'react-bootstrap';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default function SSOAuth(props) {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [clientInfo, setClientInfo] = useState({
    endpoint: null,
    server_authentication_endpoint: null,
  });

  useEffect(() => {
    isLoggedIn()
      .then(isLoggedIn => {
        if (isLoggedIn) {
          fetchUser();
        } else {
          getClientInfo();
        }
      });
  }, []);

  const fetchUser = () => {
    getUser()
      .then(data => {
        if (data) {
          dispatch({ type: 'SAVE_SESSION', payload: data });
          navigate('/');
        } else {
          dispatch({ type: 'REMOVE_SESSION', payload: {} });
          getClientInfo();
        }
      });
  };
 
  // const getClients = () => {
  //   const path = `/openid_connect_rest_auth/list_clients`;

  //   getOnlineNode(path)
  //     .then(response => {
  //       setClientInfo(response.data);
  //     })
  //     .catch(error => {
  //     });
  // };

  const getClientInfo = () => {
    const path = `/openid_connect_rest_auth/authorization_endpoint/adfs?redirect_uri=${window.location.origin}/login/callback`;

    getOnlineNode(path)
      .then(response => {
        setClientInfo(response.data);
        setLoading(false);
      })
      .catch(() => {
        setErrorMessage('Failed to get client info');
        setError(true);
        setLoading(false);
      });
  }

  const login = () => {
    if (clientInfo.endpoint) {
      const parsed = decodeURIComponent(clientInfo.endpoint);
      window.location.href = parsed;
    } else {
      setErrorMessage('No endpoint defined');
      setError(true);
    }
  }

  const ADFSButton = () => {
    return (
      <Box>
        <Button onClick={login} variant='contained' sx={{textTransform: 'none'}}>
          via RHG Rezportal
          <CustomWidthTooltip title="This button will take you to an external webpage to log in. Once you've logged in successfully, you will be re-directed back to the app." arrow>
            <InfoOutlined sx={{marginLeft: '5px'}} fontSize='14' />
          </CustomWidthTooltip>
        </Button>
      </Box>
    )
  }

  const Error = () => {
    return (
      <>
        {isError && (
          <>
            {errorMessage && (
              <Col xs='auto' className='mb-4'>
                <Alert variant='filled' severity='error'>{errorMessage}</Alert>
              </Col>
            )}
          </>
        )}
      </>
    );
  }

  const Loading = () => {
    return (
      <Row>
        <Col className='text-center'>
          <CircularProgress size={20} />
        </Col>
      </Row>
    );
  }

  return (
    <>
      {props.type === 'modal' ? (
        <div className='mt-3'>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {clientInfo.endpoint && (
                <Row>
                  <Col>
                    {isError ? (
                      <Error />
                    ) : (
                      <ADFSButton />
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      ) : (
        <main className={`login screen d-flex flex-column align-items-center`}>
          <Row className={`g-0 ${props.type === 'modal' ? "" : "w-50 mb-3"}`}>
            <PageHeader pageName='Log in' filters={false} />
          </Row>
          
          {isLoading ? (
            <Loading />
          ) : (
            <Row className={`g-0 ${props.type === 'modal' ? "" : "w-50 mb-3"}`}>
              <Col>
                {isError ? (
                  <Error />
                ) : (
                  <ADFSButton />
                )}
              </Col>
            </Row>
          )}
        </main>
      )}
    </>
  );
}
