/**
 * Build styles
 */
require('./index.css').toString();
const { make } = require('./util');

export default class HeadingToggleTool {
    /**
     * Default colour
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_COLOR() {
        return '';
    }

    static get isTune() {
        return true;
    }

    getColor() {
        if (!!this.settings?.blocks && this.settings.blocks.hasOwnProperty(this.block.name)) {
            return this.settings.blocks[this.block.name]
        }
        if (!!this.settings?.default) {
            return this.settings.default
        }
        return HeadingToggleTool.DEFAULT_COLOR
    }

    constructor({ api, data, config, block }) {
        this.api = api;
        this.block = block;
        this.settings = config;
        this.data = data;
        this._CSS = {
            wrapper: 'cdx-heading-picker-settings',
            button: 'cdx-heading-picker-button',
            span: 'cdx-heading-picker',
        };
    }

    wrap(blockContent) {
        this.wrapper = make("div");
        this.wrapper.append(blockContent);
        this.wrapper.style.width = '100%';
        this.wrapper.style.height = '100%';
        console.log(this.data)
        this.target = this.wrapper.querySelector(".cdx-header-redactor");
        if (this.data.variant !== "") {
            this.target.className = 'cdx-header-redactor'
            this.target.classList.add(this.data.variant);
        }

        return this.wrapper;
    }

    applyHeading(variant) {
        if (!this.target.classList.contains(variant)) {
            this.target.className = 'cdx-header-redactor'
            this.target.classList.add(variant)
        }
        this.data = { 
            variant: variant 
        }
    }

    render() {
        const wrapper = make("div");
        wrapper.classList.add(this._CSS.wrapper);
        const largeBtn = make("button", [this._CSS.button, 'large-heading-btn']);
        const smallBtn = make("button", [this._CSS.button, 'small-heading-btn']);
        largeBtn.innerText = 'Large'
        smallBtn.innerText = 'Small'

        largeBtn.addEventListener('click', () => this.applyHeading('heading-title'));
        smallBtn.addEventListener('click', () => this.applyHeading('heading-subtitle'));

        wrapper.appendChild(smallBtn);
        wrapper.appendChild(largeBtn);
        // this.colors.map(color => {
        //     const button = document.createElement('button');
        //     const span = document.createElement('span');

        //     button.classList.add(this._CSS.button)
        //     button.type = 'button';
        //     span.classList.add(this._CSS.span);

        //     span.style.boxShadow = `${color.value} 0px 0px 0px 15px inset`;
        //     button.appendChild(span);
        //     wrapper.appendChild(button);


        //     button.classList.toggle('colorCircleActive', color.value === this.data?.bgColor?.value);
        //     wrapper.appendChild(button);
        //     return button
        // }).forEach((element, index, elements) => {
        //     element.addEventListener('click', () => {
        //         this.data = {
        //             bgColor: this.colors[index]
        //         }
        //         elements.forEach((el, i) => {
        //             el.classList.toggle('colorCircleActive', this.colors[i].value === this.data?.bgColor?.value);
        //             this.colors.forEach((e) => {
        //                 if(this.target.classList.contains(e.name)){
        //                     this.target.classList.remove(e.name);
        //                 }
        //             })
        //             this.target.classList.add(this.data?.bgColor?.name);
        //         });
        //     });
        // });
        return wrapper;
    }

    save() {
        return this.data;
    }
}
