import { IconCross } from '@codexteam/icons';
import { AudioIcon } from '../../icons/icons';

export default class Audio {

  static get toolbox() {
    return {
      icon: AudioIcon,
      title: 'Audio',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-media-container',
      wrapper: 'cdx-media-wrapper',
      newButton: 'cdx-media-btn',
      replaceButton: 'cdx-media-btn',
      removeButton: 'cdx-media-btn',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      url: data.url || '',
      mid: data.mid || '',
    };

    this._element = this.getElement();
  }

  getElement() {
    /**
     * Create element for current Block's level
     */
    let audio;

    if (this._data.url) {
      audio = document.createElement('audio');
      audio.classList.add(this._CSS.wrapper);
      audio.setAttribute('src', this._data.url);
      audio.setAttribute('controls', true);

      if (this._data.mid) {
        audio.setAttribute('data-mid', this._data.mid);
      }
    }

    return audio;
  }

  render() {
    let container = document.createElement('div');
    container.classList.add(this._CSS.container);
    let audio = this._element;

    if (!this.readOnly) {
      if (!this.readOnly) {
        let btn = document.createElement('button');
        let btnRemove = document.createElement('button');
  
        if (this._data.url) {
          btn.classList.add(this._CSS.replaceButton);
          btn.innerHTML = `${Audio.toolbox.icon}`;
          btnRemove.classList.add(this._CSS.removeButton);
          btnRemove.innerHTML = `${IconCross}`;
        } else {
          btn.classList.add(this._CSS.newButton);
          btn.innerHTML = `${Audio.toolbox.icon}`;
        }
  
        btn.onclick = () => {
          const blockIndex = this.api.blocks.getCurrentBlockIndex();
          const block = this.api.blocks.getBlockByIndex(blockIndex);
          this._settings.showMediaLibrary({
            details: block,
            data: this._data,
            bundle: 'audio',
          });
        };
  
        btnRemove.onclick = () => {
          this._data.url = '';
          this._data.mid = '';
          this._element.setAttribute('src', '');
          this._element.setAttribute('mid', '');
          this._element.remove();
          btnRemove.remove();
        }
  
        if (typeof audio !== 'undefined') {
          container.appendChild(audio);
          container.appendChild(btn);
          container.appendChild(btnRemove);
        } else {
          // hidden input hack
          // prevents empty blocks being created
          let input = document.createElement('input');
          input.setAttribute('style', 'visibility: hidden; width: 0; height: 0; padding: 0; margin: 0; border: 0; position: absolute');
    
          container.appendChild(input);
          container.appendChild(btn);
        }
      } else {
        if (typeof img !== 'undefined') {
          container.appendChild(audio);
        }
      }
    }

    return container;
  }

  save() {
    if (typeof this._element !== 'undefined') {
      this._data.url = this._element.getAttribute('src') || '';
      this._data.mid = this._element.getAttribute('data-mid') || '';
    } else {
      this._data.url = '';
      this._data.mid = '';
    }

    return this._data;
  }
}