import { useEffect, useState } from "react";

// Modules
import { useSelector } from "react-redux";

// App
import { getOnlineNode } from "../../core//getNode";
import { patchNode } from "../../core/postNode";
import CompanyappLoader from "../../partials/companyappLoader";
import PageHeader from "../../partials/pageHeader";

// UI
import {
  Alert,
  Snackbar,
  IconButton,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";

import { FileDownloadOutlined, Close } from "@mui/icons-material";
import Creator from "../../partials/creator";
import caLogoMin from "../../assets/ca-logo.png";


export default function LaptopApp(props) {
  const user = useSelector((state) => state).authReducer.user;
  const [keys, setKeys] = useState([]);
  const [licenseCount, setLicenseCount] = useState(1);

  const content = useSelector((state) => state).contentReducer;

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // get device keys
    getKeys();
  }, []);

  const getKeys = () => {
    getOnlineNode(`api/devicekey?_format=json`)
      .then((response) => {
        const serverKeys = response.data.keys;
        const serverCount = response.data.license ? response.data.license : 1;

        if (serverCount) {
          setLicenseCount(Number(serverCount));
        }

        const _keys = [];

        for (let i = 0; i < serverCount; i++) {
          const keySet = serverKeys[i];
          let data = {
            key: "",
            name: "",
          };

          if (keySet) {
            data = {
              key: keySet.key ? keySet.key : "",
              name: keySet.name ? keySet.name : "",
            };
          }

          _keys.push(data);
        }

        setKeys(_keys);
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        // error
        setErrorMessage(
          "Failed to get keys from the server. Please try again later."
        );
        setLoading(false);
        setError(true);
      });
  };

  const saveKeys = () => {
    patchNode(`/api/devicekey`, keys, user.csrf_token)
      .then((response) => {
        setLoading(true);
        setError(false);
        getKeys();
        setSuccess(true);
      })
      .catch((error) => {
        setErrorMessage("Failed to save keys. Please try again later.");
        setLoading(false);
        setError(true);
        setSuccess(false);
      });
  };

  if (isLoading) {
    return (
      <div className="circular-container fs">
        <CompanyappLoader />
      </div>
    );
  } else {
    return (
      <>
        <div className="laptop-app screen d-flex flex-column justify-content-between h-100 mx-lg-auto">
          <Row className="gx-0">
            <Col>
              <Row className="mb-3">
                <style dangerouslySetInnerHTML={{ __html: content.css ? content.css : '' }} />
                <div className='dynamic-content app' dangerouslySetInnerHTML={{ __html: content.app ? content.app : '' }} />
              </Row>
    
              {/* <Row className="mt-5 mx-5 px-3">
                {content.windows && (
                  <Col>
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <div
                          className="os-logo mb-4"
                          dangerouslySetInnerHTML={{ __html: winLogo }}
                        ></div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <Button
                          className="download-add-in-btn mt-3"
                          owner="admin"
                          color="adminPrimary"
                          variant="contained"
                          startIcon={<FileDownloadOutlined />}
                          onClick={() => {
                            window.open(content.windows, '_blank');
                          }}
                        >
                          <Typography owner="admin" variant="button">
                            Download
                          </Typography>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}

                {content.macos && (
                  <Col>
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <div
                          className="os-logo mb-4"
                          dangerouslySetInnerHTML={{ __html: macLogo }}
                        ></div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <Button
                          className="download-add-in-btn mt-3"
                          owner="admin"
                          color="adminPrimary"
                          variant="contained"
                          startIcon={<FileDownloadOutlined />}
                          onClick={() => {
                            window.open(content.macos, '_blank');
                          }}
                        >
                          <Typography owner="admin" variant="button">
                            Download
                          </Typography>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <Row className="mt-5 pt-3">
                <Col>
                  <Typography owner="admin" variant="h5" color="adminText.dark">
                    Device Key
                  </Typography>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Typography owner="admin" variant="body2" lh="medium">
                    Please enter the Device Key for your Laptop App in the
                    relevant box below.
                  </Typography>
                </Col>
              </Row> */}

              {keys.length > 0 && (
                <>
                  {keys.map((set, index) => {
                    return (
                      <Row key={`key-${index}`}>
                        <Col xs={4}>
                          <Row className="mt-2">
                            <Col>
                              <Typography
                                owner="admin"
                                variant="form-group-label"
                                lh="wide"
                              >
                                Device Key
                              </Typography>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextField
                                id={`key-${index}`}
                                defaultValue={set.key}
                                placeholder="XXXXXX"
                                maxLength={6}
                                size="small"
                                inputProps={{
                                  maxLength: 6,
                                  style: { textTransform: "uppercase" },
                                }}
                                onChange={(event) => {
                                  let data = keys;
                                  data[index].key = event.target.value;
                                  setKeys(data);
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={8}>
                          <Row className="mt-2">
                            <Col>
                              <Typography
                                owner="admin"
                                variant="form-group-label"
                                lh="wide"
                              >
                                Name of device (optional)
                              </Typography>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextField
                                id={`name-${index}`}
                                defaultValue={set.name}
                                placeholder="Device name"
                                size="small"
                                onChange={(event) => {
                                  let data = keys;
                                  data[index].name = event.target.value;
                                  setKeys(data);
                                }}
                                fullWidth
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row className="mt-3 justify-content-end">
                    <Col xs="auto">
                      <Button
                        className="submit-btn"
                        onClick={() => {
                          saveKeys();
                        }}
                        sx={{
                          textTransform: "none",
                          backgroundColor: "secondary.main",
                          color: "solid.light",
                          "&:hover": {
                            backgroundColor: "secondary.dark",
                          },
                        }}
                      >
                        <Typography
                          owner="admin"
                          variant="subtitle1"
                          weight="medium"
                          lh="wide"
                        >
                          Save keys
                        </Typography>
                      </Button>
                    </Col>
                  </Row>
                </>
              )}

              {content.app_bottom && (
                <Row className="mt-5">
                  <Col>
                    <Typography owner="admin" variant="body2" lh="medium">
                      <span dangerouslySetInnerHTML={{ __html: content.app_bottom ? content.app_bottom : '' }} />
                    </Typography>
                  </Col>
                </Row>
              )}

            </Col>
          </Row>
          <Creator />
        </div>

        <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={() => {
            setError(false);
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setError(false);
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          <Alert
            onClose={() => {
              setError(false);
            }}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>

        <Snackbar
          open={isSuccess}
          autoHideDuration={5000}
          onClose={() => {
            setSuccess(false);
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setSuccess(false);
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          <Alert
            onClose={() => {
              setSuccess(false);
            }}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Your keys have been updated successfully.
          </Alert>
        </Snackbar>
      </>
    );
  }
}
