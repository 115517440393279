
export default function BtnTooltip(parent, text) {
  const mainTag = document.createElement('div');
  mainTag.classList.add('btn-tooltip');
  mainTag.innerHTML = text;


  if (parent) {
    parent.addEventListener('mouseover', () => {
      const documentRect = document.body.getBoundingClientRect();
      const rect = parent.getBoundingClientRect();

      if (documentRect.right - rect.right <= 100) {
        mainTag.classList.add('left');
        mainTag.style.right = `${(documentRect.right - rect.right) + rect.width + 10}px`;
        mainTag.style.top = `${rect.top + (rect.height / 2)}px`;
        mainTag.style.left = "unset";
        mainTag.style.bottom = "unset";
        mainTag.style.transform = `translateY(-50%)`;
      }
      else if (rect.left <= 100) {
        mainTag.classList.add('right');
        mainTag.style.left = `${(rect.left) + rect.width + 10}px`;
        mainTag.style.top = `${rect.top + (rect.height / 2)}px`;
        mainTag.style.right = "unset";
        mainTag.style.bottom = "unset";
      }
      else {
        if ((documentRect.bottom - rect.bottom) < 200) {
          mainTag.classList.add('top');
          mainTag.style.bottom = `${documentRect.bottom - rect.bottom + rect.height + 10}px`;
          mainTag.style.top = "unset";
          mainTag.style.right = "unset";
          mainTag.style.left = `${rect.left + (rect.width / 2)}px`;
        }
        else {
          mainTag.classList.add('bottom');
          mainTag.style.top = `${rect.top + rect.height + 10}px`;
          mainTag.style.right = "unset";
          mainTag.style.bottom = "unset";
          mainTag.style.left = `${rect.left + (rect.width / 2)}px`;
        }

        mainTag.style.transform = `translateX(-50%)`;
      }
      document.body.appendChild(mainTag);
    });

    parent.addEventListener('mouseout', () => {
      mainTag.remove();
    })
    parent.addEventListener('click', () => {
      mainTag.remove();
    })
  }
}