import React, { useState } from 'react';

// Modules

// App

// UI components
import { Row, Col, Container } from 'react-bootstrap';
// import RangeSlider from 'react-bootstrap-range-slider';
import { Slider } from '@mui/material';

export default function BackgroundImageOpacity(props) {
  const [backgroundOpacity, setBackgroundOpacity] = useState(props.slideBackgroundOpacity ? props.slideBackgroundOpacity : 1);

  const onBackgroundImageOpacity = opacity => {
    setBackgroundOpacity(opacity);
    props.onBackgroundImageOpacity(opacity);
  }

  return (
    <div className='background-opacity h-100'>
      <Row className='h-100'>
        <Col className='d-flex align-items-center h-100'>
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={backgroundOpacity}
            onChange={changeEvent => onBackgroundImageOpacity(changeEvent.target.value)}
            valueLabelDisplay="auto"
            style={{ padding: 0 }}
            onMouseDown={() => props.sliderMouseDown()}
            onChangeCommitted={() => props.sliderChangeCommit()}
          />
        </Col>
      </Row>
    </div>
  );
};
