import React from "react";

import { filesystem_root, subdomain } from "../../config";

import GridItem from "../../partials/gridItem";

export default function GalleryItem(props) {

const formatDate = (date) => {
  if(date !== ""){
    const dateCreated = new Date(parseInt(date.replace('\n', '').trim()) * 1000)
    const day = dateCreated.toLocaleString("en-GB", { day: "2-digit" })
    const month = dateCreated.toLocaleString("en-GB", { month: "short" })
    const timestamp = `${day} ${month}`;
    return timestamp
  }
  else return ""
}

  return (
    <GridItem
      xs={12} sm={6} md={6}
      itemClass="gallery"
      linkTo={true}
      route="galleries"
      nid={props.item?.nid}
      title={props.item?.title}
      field_body={props.item?.summary ? props.item?.summary : props.item?.body}
      category_label={props.item?.category_label}
      created={props.item?.created}
      hasImg={true}
      newsVariant={"featured"}
      // imgSrc={`${filesystem_root}/${subdomain}/galleries/${props.item?.field_video}.jpg`} alt={`Poster for Video ${props.item?.field_video}`}
      imgSrc={props.item?.uri}
    />
  )
}