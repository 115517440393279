/**
 * Build styles
 */
require('./index.css').toString();

export default class Rule {

  static get toolbox() {
    return {
      icon: `<svg width="10" height="14" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.45 1.5"><defs><style>.cls-1{fill:#1a1a1a;}</style></defs><title>rule</title><line class="cls-1" x1="0.72" y1="0.75" x2="16.72" y2="0.75"/><path class="cls-1" d="M2,10.75H18a.75.75,0,0,0,0-1.5H2a.75.75,0,0,0,0,1.5Z" transform="translate(-1.28 -9.25)"/></svg>`,
      // `<svg width="10" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 14"><path d="M7.6 8.15H2.25v4.525a1.125 1.125 0 0 1-2.25 0V1.125a1.125 1.125 0 1 1 2.25 0V5.9H7.6V1.125a1.125 1.125 0 0 1 2.25 0v11.55a1.125 1.125 0 0 1-2.25 0V8.15z"/></svg>`,
      title: 'Rule',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      block: this.api.styles.block,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
      tag: 'rule',
      tagWrapper: 'rule-container',
      blockSettingsWrapper: 'cdx-animations-settings',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      text: data.text || '',
    };

    this._element = this.getElement();
  }

  getElement() {
    /**
     * Create element for current Block's level
     */
    const tag = document.createElement('hr');
    const tagWrapper = document.createElement('div');

    /**
     * Add styles class
     */
    tag.classList.add(this._CSS.tag);
    tagWrapper.classList.add(this._CSS.tagWrapper);


    /**
     * Make tag editable
     */
    tag.contentEditable = false

    tagWrapper.appendChild(tag)
    return tagWrapper;
  }

  render() {
    return this._element;
  }

  save() {
    this._data.text = this._element.innerHTML;

    return this._data;
  }
}