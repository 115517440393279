const defaultState = {
  permissions: {},
};

export const permissionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SAVE_PERMISSIONS':
      return {
        ...state,
        permissions: action.payload,
      };

    default:
      return state;
  }
};