import React, { useState, useEffect, useRef } from "react";

// Modules
import { Cropper } from "react-advanced-cropper";

// App
import { serverUrl } from "../../config";
import BackButton from "./backButton";
import { getOnlineNode } from "../../core//getNode";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  CircularProgress,
  Alert,
  Box,
  Button,
  Stack,
  Typography,
  SvgIcon
} from "@mui/material/";
import { Crop, Done, Close, Send } from "@mui/icons-material";
import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/corners.css";

export default function SelectedIce(props) {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedCoordinates, setCropCoordinates] = useState({});
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [defaultCrop, setDefaultCrop] = useState({
    left: 0,
    top: 0,
    width: props.maxWidth,
    height: props.maxHeight,
  });

  const [metadata, setMetadata] = useState(null);

  const cropperRef = useRef(null);
  const [image, setImage] = useState(null);

  // load metadata
  useEffect(() => {
    if (props?.selectedMedia?.assetId) {
      let path = `/api/iceportal/assets?assetId=${props?.selectedMedia?.assetId}?include=metadata,caption,description,alttext`;


      getOnlineNode(path)
        .then((response) => {
          setMetadata(response.data);
        })
        .catch((_error) => {
          // no data
        });
    }
  }, [props?.selectedMedia?.assetId]);

  // load image
  useEffect(() => {
    if (props?.selectedMedia?.links.cdnLinks) {
      setCroppedImage(null);
      setImage(props?.selectedMedia?.links.cdnLinks[1]);
      setError(false);
      setLoading(true);
      tryUntilCrop();
    }
  }, [props?.selectedMedia?.links.cdnLinks]);

  useEffect(() => {
    getCrop();
    setLoading(false);
    if (cropperRef.current) {
      cropperRef.current.refresh();
    }
  }, [isCropping]);

  const onChange = () => {
    if (cropperRef.current) {
      const coordinates = cropperRef.current.getCoordinates();
      setCropCoordinates(coordinates);
    }
  };

  const onMoveEnd = () => {
    getCrop();
  };

  const onResizeEnd = () => {
    getCrop();
  };

  const getCrop = () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.getCanvas();
      if (canvas) {
        const croppedImageDataUrl = canvas.toDataURL();
        const coordinates = cropperRef.current.getCoordinates();
        setCropCoordinates(coordinates);
        setCroppedImage(croppedImageDataUrl);
      }
    }
  };

  const finishCrop = () => {
    let data = {
      external: true,
      x: String(croppedCoordinates.left),
      y: String(croppedCoordinates.top),
      width: String(croppedCoordinates.width),
      height: String(croppedCoordinates.height),
      maxWidth: String(props.maxWidth),
      maxHeight: String(props.maxHeight),
      field_media_image: `${serverUrl}/api/image?fileId=${props?.selectedMedia.fileId}&listingId=${props.listingId}&x=${croppedCoordinates.left}&y=${croppedCoordinates.top}&width=${croppedCoordinates.width}&height=${croppedCoordinates.height}&maxWidth=${props.maxWidth}&maxHeight=${props.maxHeight}&external=true&src=${props?.selectedMedia?.links.cdnLinks[1]}`,
      bundle: "image",
      mid: `ext-${props?.selectedMedia.fileId}`,
      assetId: props?.selectedMedia.assetId,
      fileId: props?.selectedMedia.fileId,
      listingId: props.listingId,
      listingName: props.listingName,
      iceMedia: props.selectedMedia,
    };

    data.iceMedia.links = {
      originalFileURL: null,
      mediaLinkURL: null,
      cdnLinks: [
        null,
        props?.selectedMedia?.links.cdnLinks[1],
        null,
      ],
      thumbnailLink: null
    };

    props.onMediaInsert(data);
  };

  const setDefaults = (event) => {
    if (
      event.target.naturalWidth &&
      event.target.naturalHeight
    ) {
      // Calculate default crop area and center it
      const width = props.minWidth;
      const height = props.minHeight;
      const left = (event.target.naturalWidth - width) / 2;
      const top = (event.target.naturalHeight - height) / 2;

      let defaultCropParams = {
        left,
        top,
        width,
        height,
      };

      if (
        props.currentBlock &&
        props.currentBlock?.details?.name !== "gallery"
      ) {
        const { x, y, width, height } =
          props.currentBlock?.data;

        if (x && y && width && height) {
          defaultCropParams = {
            left: x,
            top: y,
            width: width,
            height: height,
          };
        }
      }

      setDefaultCrop(defaultCropParams);
      setImageDimensions({
        width: event.target.naturalWidth,
        height: event.target.naturalHeight,
      });
    }

    // tryUntilCrop();
    setLoading();
  }

  const tryUntilCrop = () => {
    const interval = setInterval(() => {
      if (cropperRef.current) {
        const canvas = cropperRef.current.getCanvas();
        if (canvas) {
          const croppedImageDataUrl = canvas.toDataURL();
          const coordinates = cropperRef.current.getCoordinates();
          setCropCoordinates(coordinates);
          setCroppedImage(croppedImageDataUrl);
          clearInterval(interval);
        }
      }
    }, 2500);
  }

  let cropStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    visibility: 'hidden',
    zIndex: -9990,
    width: '100%',
  };

  let cropImageStyles = {};

  if (isCropping) {
    cropStyles = {}
    cropImageStyles = {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      visibility: 'hidden',
      zIndex: -9990,
      width: '100%',
    }
  }

  if (!props?.selectedMedia?.links.cdnLinks) {
    return null;
  }

  const processArray = (_array, objProperty) => {
    let commaSeparatedString = ""
    if (_array.length > 0) {
      _array.forEach((e, i) => {
        if (i !== 0) {
          commaSeparatedString = commaSeparatedString + `, ${e[objProperty]}`;
        }
        else {
          commaSeparatedString = commaSeparatedString + e[objProperty];
        }
      });
    }
    return commaSeparatedString;
  }

  const processIceTags = (_iceTags) => {
    let commaSeparatedString = ""

    if (_iceTags.length > 0) {
      _iceTags.forEach((e) => {
        e.tags.forEach((_e, i) => {
          if (i !== 0) {
            commaSeparatedString = commaSeparatedString + `, ${_e.name}`;
          }
          else {
            commaSeparatedString = commaSeparatedString + _e.name;
          }
        });
      });
    }
    return commaSeparatedString;
  }

  const propertyValueRow = (_property, _value) => {
    return (
      <>
        <Row className="mb-1 align-items-center info-row">
          <Col xs={4} sm={3} xl={2}>
            <Typography variant="form-group-label" owner="admin">
              {_property}
            </Typography>
          </Col>
          <Col>
            <Typography
              variant="form-group-label"
              owner="admin"
              weight="regular"
              color="adminText.main"
            >
              {_value}
            </Typography>
          </Col>
        </Row>
      </>
    )
  }

  console.log('selectedMedia', props.selectedMedia);
  console.log('selectedIce metaData', metadata);

  return (
    <>
      <Row className="mt-3 mb-3 justify-content-center">
        <Col>
          <div
            className={`crop-actions ${props.isNotForInsert ? "singular" : ""}`}
          >
            <Row className="w-100 align-items-center">
              <Col xs={6}>
                <Row className="justify-content-between">
                  <Col xs={"auto"}>
                    <BackButton
                      className="ice"
                      label="Back"
                      onCancel={() => {
                        props.onCancel(true);
                        setIsCropping(false);
                      }}
                    />
                  </Col>
                  <Col xs={"auto"} className="d-flex align-items-center">
                    <Row>
                      <Col xs={"auto"}>
                        {!props.isNotForInsert ?
                          <Button
                            owner="admin"
                            variant="outlined"
                            dialogbtn="true"
                            dialogvariant="cancel"
                            className='mx-0'
                            onClick={() => {
                              props.onCancel(false, true);
                              setIsCropping(false);
                            }}
                            endIcon={
                              <SvgIcon>
                                <svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                                  <g fill="currentColor">
                                    <path d="m1150.8 573.6h-124.8c-13.199-224.4-199.2-400.8-426-400.8-73.199 0-145.2 19.199-207.6 54l-22.801 13.199c-9.6016 6-13.199 18-7.1992 27.602l36 55.199c6 8.3984 16.801 12 26.398 6l19.199-10.801c48-26.398 102-40.801 156-40.801 169.2 0 307.2 129.6 320.4 295.2h-121.2c-15.602 0-25.199 16.801-16.801 30l175.2 270c7.1992 12 25.199 12 32.398 0l175.2-270c9.6055-12 1.2031-28.797-14.395-28.797z" />
                                    <path d="m801.6 877.2c-6-8.3984-16.801-12-26.398-6l-19.203 10.801c-49.199 26.398-100.8 40.801-156 40.801-169.2 0-307.2-130.8-321.6-295.2h121.2c15.602 0 25.199-16.801 16.801-30l-175.2-271.2c-7.1992-12-25.199-12-32.398 0l-175.2 270c-8.3984 13.199 1.1992 30 16.801 30h123.6c13.195 223.2 199.2 400.8 426 400.8 72 0 144-19.199 207.6-54l22.801-13.199c9.6016-6 13.199-18 7.1992-27.602z" />
                                  </g>
                                </svg>
                              </SvgIcon>
                            }
                          >
                            <Typography
                              owner="admin"
                              variant="button"
                              texTransform="none"
                            >
                              Change image
                            </Typography>
                          </Button>
                          : <></>
                        }
                      </Col>
                      <Col xs={"auto"}>
                        {isCropping ? (
                          <>
                            <Button
                              owner="admin"
                              variant="contained"
                              dialogbtn="true"
                              dialogvariant="error"
                              className='mx-0'
                              endIcon={<Close />}
                              onClick={() => setIsCropping(false)}
                            >
                              <Typography
                                owner="admin"
                                variant="button"
                                texTransform="none"
                              >
                                Cancel crop
                              </Typography>
                            </Button>
                          </>
                        ) : (
                          <>
                            {!props.isNotForInsert && (
                              <>
                                {imageDimensions.width >= props.minWidth &&
                                  imageDimensions.height >= props.minHeight && (
                                    <Button
                                      owner="admin"
                                      variant="contained"
                                      dialogbtn="true"
                                      dialogvariant="primary"
                                      className='mx-0'
                                      endIcon={<Crop />}
                                      onClick={() => setIsCropping(true)}
                                    >
                                      <Typography
                                        owner="admin"
                                        variant="button"
                                        texTransform="none"
                                      >
                                        Crop image
                                      </Typography>
                                    </Button>
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                {isCropping ? (
                  <Button
                    variant="contained"
                    owner="admin"
                    dialogbtn="true"
                    dialogvariant="save"
                    className='mx-0'
                    endIcon={<Send />}
                    onClick={() => {
                      finishCrop();
                    }}
                  >
                    <Typography
                      owner="admin"
                      variant="button"
                      texTransform="none"
                    >
                      Save crop
                    </Typography>
                  </Button>
                ) : (
                  <>
                    {!props.isNotForInsert && (
                      <Button
                        variant="contained"
                        owner="admin"
                        dialogbtn="true"
                        dialogvariant="save"
                        className='mx-0'
                        endIcon={<Done />}
                        onClick={() => {
                          if (
                            imageDimensions.width <= props.minWidth ||
                            imageDimensions.height <= props.minHeight
                          ) {
                            setError(
                              `This image is below the required dimensions.`
                            );
                          } else if (
                            imageDimensions.width > props.maxWidth ||
                            imageDimensions.height > props.maxHeight
                          ) {
                            setError(
                              `This image exceeds the maximum dimensions.`
                            );
                          } else {
                            props.onMediaInsert(props.selectedMedia);
                          }
                        }}
                      >
                        <Typography
                          owner="admin"
                          variant="button"
                          texTransform="none"
                        >
                          Select image
                        </Typography>
                      </Button>
                    )}
                  </>
                )}
              </Col>
            </Row>
            {/* {isCropping ? (
              <Stack direction="row" spacing={2} textAlign="center">
                <Button
                  variant="outlined"
                  startIcon={<Close />}
                  onClick={() => setIsCropping(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Close />}
                  onClick={() => {
                    props.onCancel();
                    setIsCropping(false);
                  }}
                >
                  Replace image
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<Send />}
                  onClick={() => {
                    finishCrop();
                  }}
                >
                  Save crop
                </Button>
              </Stack>
            ) : (
              <>
                {!props.isNotForInsert && (
                  <Stack direction="row" spacing={2} textAlign="center">
                    {imageDimensions.width >= props.minWidth &&
                      imageDimensions.height >= props.minHeight && (
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<Crop />}
                          onClick={() => setIsCropping(true)}
                        >
                          Crop
                        </Button>
                      )}

                    <Button
                      variant="contained"
                      color="secondary"
                      endIcon={<Done />}
                      onClick={() => {
                        if (
                          imageDimensions.width <= props.minWidth ||
                          imageDimensions.height <= props.minHeight
                        ) {
                          setError(
                            `This image is below the required dimensions.`
                          );
                        } else if (
                          imageDimensions.width > props.maxWidth ||
                          imageDimensions.height > props.maxHeight
                        ) {
                          setError(
                            `This image exceeds the maximum dimensions.`
                          );
                        } else {
                          props.onMediaInsert(props.selectedMedia);
                        }
                      }}
                    >
                      Select
                    </Button>
                  </Stack>
                )}
              </>
            )} */}
          </div>
        </Col>
      </Row>

      <Row className="image-selection mb-3">
        <Col sm={12} lg={6} className={"mb-3"}>
          {!props.isNotForInsert && (
            <>
              {props.minWidth !== undefined &&
                props.minHeight !== undefined && (
                  <div>
                    <Alert severity="warning">
                      <Stack direction={"row"} spacing={1}>
                        {props.minWidth && props.minHeight && (
                          <label>{`SIZE REQUIRED (W x H): MIN ${props.minWidth} and ${props.minHeight} pixels / MAX = ${props.maxWidth} x ${props.maxHeight} pixels`}</label>
                        )}
                      </Stack>
                    </Alert>
                  </div>
                )}
            </>
          )}
          <div className="">
            <div className="cropper">
              <div style={{ position: "relative" }}>
                {isLoading && (
                  <div className="cropper-loader">
                    <CircularProgress size={16} />
                  </div>
                )}
                <div style={cropStyles}>
                  <Cropper
                    key={`${image}`}
                    ref={cropperRef}
                    src={`${serverUrl}/api/iceportal/view?src=${props?.selectedMedia?.links.cdnLinks[1]}`}
                    backgroundWrapperProps={{
                      scaleImage: false,
                    }}
                    imageRestriction={"fitArea"}
                    onChange={onChange}
                    onMoveEnd={onMoveEnd}
                    onResizeEnd={onResizeEnd}
                    className={"cropper"}
                    resizeImage={false}
                    moveImage={false}
                    checkOrientation={false}
                    minWidth={props?.minWidth}
                    minHeight={props?.minHeight}
                    onReady={(cropper) => {
                      setTimeout(() => {
                        getCrop();
                        setLoading(false);
                      }, 1000);
                    }}
                    stencilProps={{
                      handlers: true,
                      lines: true,
                      aspectRatio: props?.aspectRatio ? props?.aspectRatio : "",
                      movable: true,
                    }}
                    defaultSize={defaultCrop}
                    defaultPosition={defaultCrop}
                  />
                </div>

                <img
                  key={`image-${image}`}
                  style={cropImageStyles}
                  src={props?.selectedMedia?.links.cdnLinks[1]}
                  alt={`ice-${image}`}
                  onLoad={(event) => {
                    setDefaults(event);
                  }}
                />
              </div>
            </div>
          </div>
        </Col>

        {/* <Col sm={12} lg={6} className="mb-3">
          {croppedImage && (
            <div className="cropped-view">
              <img src={croppedImage} alt="Cropped view" />
            </div>
          )}
        </Col> */}

        {isCropping ? (
          <>
            {croppedImage && (
              <Col sm={12} lg={6} className={"mb-3"}>
                <div>
                  <Alert severity="success">
                    <Stack direction={"row"} spacing={1}>
                      {props.minWidth && props.minHeight && (
                        <label>{`CROP POSITION: X = ${Math.ceil(
                          croppedCoordinates.left
                        )}, Y = ${Math.ceil(
                          croppedCoordinates.top
                        )}, CROP SIZE: W = ${Math.ceil(
                          croppedCoordinates.width
                        )}, H = ${Math.ceil(
                          croppedCoordinates.height
                        )} pixels`}</label>
                      )}
                    </Stack>
                  </Alert>
                </div>
                <Row className="align-items-end h-100">
                  <Col xs={12} className="align-self-start">
                    <div
                      className={`img-preview ${isCropping ? "cropping" : ""}`}
                    >
                      <img src={croppedImage} alt="Cropped-preview" />
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </>
        ) : (
          <Col sm={12} lg={6} className={"mb-3 info-container d-flex flex-column"}>
            {isError && (
              <div>
                <Alert variant="filled" severity="error">
                  <Stack direction={"row"} spacing={1}>
                    {props.minWidth && props.minHeight && (
                      <label>{isError}</label>
                    )}
                  </Stack>
                </Alert>
              </div>
            )}

            {!isError && !props.isNotForInsert && !isCropping && (
              <div style={{ visibility: "hidden" }}>
                <Alert severity="warning">
                  <Stack direction={"row"} spacing={1}>
                    {props.minWidth && props.minHeight && (
                      <label>{`SIZE REQUIRED (W x H): MIN ${props.minWidth} and ${props.minHeight} pixels / MAX = ${props.maxWidth} x ${props.maxHeight} pixels`}</label>
                    )}
                  </Stack>
                </Alert>
              </div>
            )}
            <div className="wrapper flex-grow-1">
              <div className="form-group">
                {props.selectedMedia?.mid && (
                  <Row className="mb-1 align-items-center info-row">
                    <Col xs={4} sm={3} xl={2}>
                      <Typography variant="form-group-label" owner="admin">
                        Asset ID
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      >
                        {props.selectedMedia.assetId}
                      </Typography>
                    </Col>
                  </Row>
                )}
                {props.selectedMedia?.fileId && propertyValueRow("File ID", props.selectedMedia.fileId)}
                {props.selectedMedia?.assetId && propertyValueRow("Asset ID", props.selectedMedia?.assetId)}
                {props.selectedMedia?.privateId && propertyValueRow("Private ID", props.selectedMedia?.privateId)}
                {props.selectedMedia?.publicMediaId && propertyValueRow("Public ID", props.selectedMedia?.publicMediaId)}
                {metadata?.fileMetadata?.originalFileName && propertyValueRow("File name", metadata?.fileMetadata?.originalFileName)}
                {imageDimensions.width !== 0 && imageDimensions.height !== 0 && propertyValueRow("Dimensions", `${imageDimensions.width} x ${imageDimensions.height} pixels`)}
                {metadata?.descriptions && propertyValueRow("Description", processArray(metadata?.descriptions, "description"))}
                {metadata?.altTexts && propertyValueRow("Alt texts", processArray(metadata?.altTexts, "altText"))}
                {metadata?.category && propertyValueRow("Categories", processArray(metadata?.category, "iceCategory"))}
                {metadata?.captions && propertyValueRow("Captions", processArray(metadata?.captions, "caption"))}
                {metadata?.iceTags && propertyValueRow("Ice tags", processIceTags(metadata?.iceTags))}
              </div>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}
