// Dependencies
import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

// App
import { serverUrl, apiEndpoints } from "../../config";
import { decodeHtmlEntities } from "../../utils/helpers";
import { getOnlineNode } from "../../core/getNode";
import { postNode, patchNode } from "../../core/postNode";
import PostBody from "../../partials/postBody";
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import SkeletonSingleScreen from "../../partials/skeleton-screens/skeletonSingleScreen";
import PostImage from "../../partials/postImage";
import AlertModal from "../../partials/alertModal";

// UI components
import { Row, Col, Container } from "react-bootstrap";
import {
  Button,
  IconButton,
  Typography,
  Snackbar,
  CircularProgress,
  Alert,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import SinglesHeader from "../../partials/singlesHeader";
import CloseIcon from "@mui/icons-material/CloseRounded";

export default function QuizSingle(props) {
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [maxAnswerReached, setMaxAnswerReached] = useState(false);

  const [post, setPost] = useState(null);

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [answerLength, setAnswerLength] = useState(0);
  const [checkedLength, setCheckedLength] = useState(0);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [checkboxLoading, setCheckboxLoading] = useState({})

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();

  useEffect(() => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
    } else {
      setIsError(true);
      setErrorStatus(404);
      setErrorMessage("Not found");
    }
  }, [params.id]);

  useEffect(() => {
    if (post) {
      let total = 0;
      let checkedTotal = 0;
      post.field_question.forEach((question) => {
        const correctAnswers = question.field_answers.filter(
          (answer) => answer.field_correct === true
        );

        const checkedAnswers = question.field_answers.filter(
          (answer) => answer.checked === true
        );

        total = total + correctAnswers.length;
        checkedTotal = checkedTotal + checkedAnswers.length;
      });

      setCheckedLength(checkedTotal);
      setAnswerLength(total);
    }
    if (checkboxLoading.hasOwnProperty("questionIndex")) {
      setCheckboxLoading({})
    }
    if(post?.submitted){
      setShowConfirmation(false);
    }
  }, [post]);

  useEffect(() => {
    if (checkedLength === answerLength) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [checkedLength, answerLength]);

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = `${apiEndpoints.quiz}?nid=${nid}&status=1&promote=1&_format=json`;
    getOnlineNode(path)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setPost(response.data.rows[0]);
          setIsError(false);
          setErrorStatus(null);
          setErrorMessage(null);
          setLoading(false);
        } else {
          setIsError(true);
          setErrorStatus(404);
          setErrorMessage("Not found");
        }
      })
      .catch((_error) => {
        setIsError(true);

        if (_error.response) {
          setErrorStatus(_error.response.status);
          setErrorMessage(_error.response.statusText);
        } else if (_error.request) {
          setErrorStatus(0);
          setErrorMessage(alertMessages.requestError.message);
        } else {
          setErrorStatus(0);
          setErrorMessage(alertMessages.unknownError.message);
        }

        setLoading(false);
      });
  };

  const renderPostImage = (post) => {
    const img = post.field_featured_image;

    if (img) {
      return <PostImage imgSrc={img} nid={post.nid} />;
    }
  };

  const toggleCheckbox = (answer, question) => {
    const data = {
      nid: post.nid,
      question_id: question.id,
      answer_id: answer.id,
      checked: answer.checked ? "false" : "true",
    };

    patchNode(
      `/api/quiz/progress/${user.current_user.uid}`,
      data,
      user.csrf_token
    )
      .then((response) => {
        // setLoading(true);
        getContent(params.id);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const submitQuiz = () => {
    if (post.nid && post.field_question) {
      let data = {
        quizID: post.nid,
        questions: post.field_question,
      };

      postNode("api/quiz", data, user.csrf_token)
        .then((response) => {
          setSubmitLoading(false);
          setSubmitError(false);
          setLoading(true);
          getContent(params.id);
        })
        .catch((error) => {
          setSubmitLoading(false);
          setSubmitError(true);
        });
    } else {
      setSubmitLoading(false);
      setSubmitError(true);
    }
  };


  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return <Error status={errorStatus} message={errorMessage} />;
    } else {
      let category = "Uncategorised";

      if(post.category_labels){
        category = post.category_labels
      }

      return (
        <main className="quiz single">
          <article className="article">
            <SinglesHeader
              route={"/quizzes"}
              backBtnText={"Tests & Quizzes"}
              category={category}
              timestamp={post.created}
              title={post.title}
            />
            {renderPostImage(post)}
            <div className="article__body">
              <PostBody body={post.body} />
            </div>
            {post.submitted && (
              <Alert
                className="ps-4"
                owner="client"
                severity="success"
                sx={{
                  "& .MuiAlert-icon": {
                    padding: "7px 0px 7px 9px",
                  },
                }}
              >
                <Typography owner="client" variant="body1" weight="light">
                  You have completed the quiz
                </Typography>
              </Alert>
            )}

            {post.submitted && <hr />}

            {post.field_question && post.field_question.length > 0 && (
              <>
                {post.field_question.map((question, questionIndex) => {
                  if (
                    question.field_answers &&
                    question.field_answers.length > 0
                  ) {
                    const correctAnswers = question.field_answers.filter(
                      (answer) => answer.field_correct === true
                    );
                    const checkedAnswers = question.field_answers.filter(
                      (answer) => answer.checked === true
                    );

                    return (
                      <Row
                        className="w-100 quiz-container"
                        key={`question-${questionIndex}`}
                      >
                        <Col xs={12} className="quiz-section">
                          <Row className="pt-4">
                            <Typography
                              className="question-header mb-2"
                              owner="client"
                              variant="h6"
                              color="clientPrimary.main"
                            >
                              {question.field_question}
                            </Typography>
                          </Row>
                          {!post.submitted && (
                            <Row>
                              <Typography
                                className="question-header mb-2"
                                owner="client"
                                variant="body2"
                                color="clientText.main"
                              >
                                *Please choose {correctAnswers.length}{" "}
                                {correctAnswers.length > 1
                                  ? "answers"
                                  : "answer"}
                              </Typography>
                            </Row>
                          )}
                          {question.field_answers.length > 0 && (
                            <Row className="ps-4 question-options-container pb-4 pt-2">
                              <FormGroup>
                                {question.field_answers.map((answer, index) => {
                                  let checked = answer.checked;
                                  let style;

                                  if (post.submitted) {
                                    if (
                                      post.submission?.answers?.find(
                                        (g) =>
                                          g.field_answer === answer.field_answer
                                      )
                                    ) {
                                      checked = true;
                                    }

                                    if (answer.field_correct) {
                                      style = {
                                        color: "#66bb6a",
                                      };
                                    } else {
                                      if (checked) {
                                        style = {
                                          color: "#f44336",
                                        };
                                      }
                                    }
                                  }

                                  return (
                                    <FormControlLabel
                                      className="my-2"
                                      key={`q-${questionIndex}-answer-${index}`}
                                      control={
                                        questionIndex === checkboxLoading?.questionIndex && index === checkboxLoading?.index ?
                                          <CircularProgress className="p-1 mx-2 d-flex" size={30} sx={{ "& svg": { width: "1em", height: "1em" } }} />
                                          :
                                          <Checkbox
                                            className="p-0 mx-2"
                                            color="clientPrimary"
                                            disabled={post.submitted || checkboxLoading.hasOwnProperty("questionIndex")}
                                            onChange={() => {
                                              if (checked) {
                                                setCheckboxLoading({ questionIndex: questionIndex, index: index });
                                                toggleCheckbox(answer, question);
                                              } else {
                                                if (
                                                  checkedAnswers.length >=
                                                  correctAnswers.length
                                                ) {
                                                  setMaxAnswerReached(true);
                                                  return false;
                                                } else {
                                                  setCheckboxLoading({ questionIndex: questionIndex, index: index });
                                                  toggleCheckbox(
                                                    answer,
                                                    question
                                                  );
                                                }
                                              }
                                            }}
                                            checked={checked}
                                            style={style}
                                          />
                                      }
                                      label={
                                        <Typography
                                          owner="client"
                                          variant="body1"
                                          lh="medium"
                                          style={style}
                                        >
                                          {answer.field_answer}
                                        </Typography>
                                      }
                                    />
                                  );
                                })}
                              </FormGroup>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    );
                  } else {
                    return null;
                  }
                })}
              </>
            )}

            {!post.submitted && (
              <>
                {!submitEnabled && (
                  <Row className="my-3">
                    <Col xs="auto">
                      <Typography
                        className="question-header mb-2"
                        owner="client"
                        variant="body1"
                        color="clientText.main"
                        weight="light"
                      >
                        Please note: You need to answer all the questions before
                        you are able to submit.
                      </Typography>
                    </Col>
                  </Row>
                )}
                <Row className="d-flex justify-content-end w-100 pb-4 mb-4">
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      color="clientPrimary"
                      disabled={!submitEnabled}
                      onClick={() => setShowConfirmation(true)}
                    >
                      <Typography
                        owner="client"
                        variant="h6"
                        color="clientText.contrastText"
                        lh="medium"
                      >
                        Submit
                      </Typography>
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </article>

          <AlertModal
            warningIcon
            showAlert={submitError}
            alertMessageTitle={"Submission failed"}
            alertMessageBody={"Failed to submit quiz. Please try again later."}
            confirmButton={true}
            confirmButtonLabel={"Ok"}
            onConfirm={() => {
              setSubmitError(false);
            }}
          />

          <Dialog
            className="form-dialog ps-modal"
            open={showConfirmation}
            onClose={() => setShowConfirmation(false)}
          >
            <Box className="ps-modal-title">
              <Container fluid>
                <Row className="d-flex align-items-center justify-content-between">
                  <Col xs={"auto"}>
                    <Typography
                      variant="h3"
                      owner="client"
                      color="clientText.dark"
                    >
                      Important
                    </Typography>
                  </Col>
                  <Col xs={"auto"}>
                    <IconButton
                      className="close-dialog-btn"
                      onClick={() => setShowConfirmation(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Col>
                </Row>
              </Container>
            </Box>
            <DialogContent
              className="form-dialog-content ps-modal-content"
              sx={{ position: "relative" }}
            >
              <Container fluid>
                <Row>
                  <Typography owner="client" variant="body1">
                    Once submitted you can no longer change your answers for
                    this test
                  </Typography>
                </Row>
                <Row className="d-flex justify-content-center my-3">
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setShowConfirmation(true);
                        setSubmitLoading(true);
                        submitQuiz();
                      }}
                    >
                      {submitLoading ? (
                        <CircularProgress size={14} />
                      ) : (
                        <Typography owner="client" variant="button">
                          Submit
                        </Typography>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </DialogContent>
          </Dialog>

          <AlertModal
            warningIcon
            showAlert={maxAnswerReached}
            alertMessageTitle={`Maximum number of answers reached.`}
            alertMessageBody={
              "You have reached the maximum number of answers for this question. Uncheck at least one to modify your selection"
            }
            confirmButton={true}
            confirmButtonLabel={"Ok"}
            onConfirm={() => {
              setMaxAnswerReached(false);
            }}
          />
        </main>
      );
    }
  }
}
