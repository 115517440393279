export function Make(tag, classes) {
  let element = document.createElement(tag)
  if (classes) {
    if (typeof classes === "string") {
      element.classList.add(classes)
    }
    else if (typeof classes === 'object') {
      classes.forEach((e) => {
        element.classList.add(e)
      });
    }
  }

  return element
}

export function Label(item, text, tooltip) {
  let labelElement = document.createElement('div');
  let tooltipElement = document.createElement('div');
  labelElement.classList.add(`${item}-label`);
  labelElement.classList.add('modal-label');
  labelElement.innerText = text;

  if (tooltip !== "" && tooltip !== undefined) {
    tooltipElement.classList.add(`${item}-label-tooltip`);
    tooltipElement.classList.add('modal-label-tooltip');
    tooltipElement.innerText = tooltip;

    labelElement.append(tooltipElement);
  }

  return labelElement;
}

export function Input(item) {
  let inputElement = document.createElement('input');
  inputElement.classList.add(`${item}-input`);
  inputElement.classList.add('country-map-input');

  inputElement.addEventListener('keydown', (e) => {

    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === 'Backspace' || e.code === 'Backspace') {
      if (e.target.value === "" || !e.target.value) {
        console.log('prevent triggered');
        e.preventDefault();
      }
    }
  })

  return inputElement;
}