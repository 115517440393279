import React, { useEffect, useState } from 'react';

// Modules
import { useSelector } from 'react-redux';

// App
import CompanyappLoader from '../../partials/companyappLoader';

// UI components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Box, Alert } from '@mui/material';


export default function Radisson4u(props) {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('An error occurred. Please try again or contact system administrator.');

  // hooks
  const permissions = useSelector((state) => state).permissionsReducer.permissions;

  useEffect(() => {
    if (permissions.access_radisson_4u) {
      setError(false)
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  }, [permissions]);

  if (isLoading) {
    return (
      <CompanyappLoader />
    );
  } else {
    if (isError) {
      return (
        <Row className='h-100 justify-content-center align-self-center'>
          <Col>
            <Box className='d-flex h-100 justify-content-center align-items-center align-content-center' sx={{ flexDirection: 'column' }}>
              <Alert variant='filled' severity='error'>{errorMessage}</Alert>
            </Box>
          </Col>
        </Row>
      )
    } else {
      return (
        <main className='reports screen'>
          <Container fluid>
            <Row className='mt-5 justify-content-center align-self-center'>
              <Col>
                <div className='ratio ratio-16x9'>
                  <iframe title='Dashboard' src='https://r4u.companyapp.net' />
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      );
    }
  }
}
