import React from "react";

import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function SlidePreview(props) {
  return(
    <Dialog
      className="slide-preview-dialog"
      open={props.open}
      onClose={() => props.onClose()}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogContent
        className="ratio ratio-16x9 p-0"
      >
        <IconButton
          className="slide-preview-dialog-close-btn"
          onClick={() => props.onClose()}
          // color="solid"
        >
          <CloseRoundedIcon/>
        </IconButton>
        <iframe src={`${window.origin}/presentations/${props.mode}/${props.presentationId}?slide_ids=${props.slideId}&nocontrols=true`} />
      </DialogContent>
    </Dialog>
  )
}