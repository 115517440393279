import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

// App
import { serverUrl } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import { postNode } from "../../core/postNode";
import { paginator } from "../../core/paginator";
// import Pagination from "../../core/pagination";
import Pagination from "../../partials/pagination";
import PageHeader from "../../partials/pageHeader";
import CategorySelector from "../../partials/categorySelector";
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
// import { _checkContent } from "../../core/checkContent";
import NoData from "../../partials/noData";
import { decodeHtmlEntities } from "../../utils/helpers";
import ForumTopic from "./forumTopic";
import ListHeader from "../../partials/listHeader";

// UI components
import { Container, Row, Col } from "react-bootstrap";
import { Button, Typography, TextField, Box } from "@mui/material";
import CompanyappLoader from "../../partials/companyappLoader";
import AddIcon from "@mui/icons-material/AddRounded";
import DiscussionIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import SendIcon from "@mui/icons-material/SendOutlined";

export default function ForumTopics(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [index, setIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState("");
  const [data, setData] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(0)
  const [categoryOpen, setCategoryOpen] = useState(false);

  const [userName, setUserName] = useState("");
  const [firstName, setUserFirstName] = useState("");
  const [lastName, setUserLastName] = useState("");
  const [forum, setForum] = useState([]);
  const [addTopic, setAddTopic] = useState(false);
  const [topicTitle, setTopicTitle] = useState("");
  const [topicBody, setTopicBody] = useState("");

  const user = useSelector((state) => state).authReducer.user;

  const params = useParams();


  const listLabels = [
    { title: "Topic", width: { xs: 1 }, grow: 1 },
    { title: "Author", width: { xs: 2 } },
    { title: "Posts", width: { xs: 1 } },
    { title: "Last Post", width: { xs: 3 } },
  ]


  useEffect(() => {
    getForums()
  }, [])

  useEffect(() => {
    getContent(1, params.tid);
  }, [])


  useEffect(() => {
    getUserData()
  }, []);

  const getUserData = (_data) => {
    let path = "custom_user?_format=json&name=" + user.current_user.name;

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setUserName(response.data.rows[0].name);
          setUserFirstName(response.data.rows[0].field_first_name);
          setUserLastName(response.data.rows[0].field_last_name);
        }
      })
      .catch((_error) => {
        // error
      });

  };

  const postTopic = () => {


    const data = {
      _links: {
        type: {
          href: serverUrl + "/rest/type/node/forum",
        },
      },
      type: {
        target_id: "forum",
      },
      title: {
        value: topicTitle,
      },
      body: {
        value: topicBody,
      },
      field_forum: [
        {
          target_id: params.tid,
        },
      ],
    };
    postNode(
      "node",
      data,
      user.csrf_token,
      user.access_token
    )
      .then((response) => {
        setTopicTitle("");
        setTopicBody("");
        setAddTopic(false);
        getContent(1, params.tid);
      })
      .catch((_error) => {
        if (_error.response) {
          //console.log("@rest response: ", _error.response);
          _setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log("@rest request: ", _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log("@rest unknown: ", _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      })
  }

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };



  const getForums = () => {
    getOnlineNode(
      // "all_categories/news_categories",
      `api/categories?tid=${params.tid}`,
      user.access_token,
    )
      .then((response) => {
        setForum(response.data[0]);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log("@rest request: ", _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log("@rest unknown: ", _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (_currentPage, _discussionId) => {
    let path =
      `api/forum/topic/${_discussionId}?status=1&promoted=1` +
      "&items_per_page=" +
      pageSize +
      "&page=" +
      (_currentPage - 1);

    getOnlineNode(path, user.access_token)
      .then((response) => {
        // dataHandler(response.data);
        setData(response.data.rows);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          //console.log("@rest response: ", _error.response);
          _setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log("@rest request: ", _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log("@rest unknown: ", _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);

    setCurrentPage(_index);
    getContent(_index, params.tid);
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  const renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: "All Boards",
      },
    ];

    _data.forEach(item => {
      categories.push({
        value: item.tid,
        label: item.title,
      });
    });

    setCategories(categories);
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  const handleCategorySelection = (value) => {
    setIsLoading(true);

    setIsError(false);
    setCurrentPage(1);
    setSelectedCategory(parseInt(value, 10));
    setSelectedCategoryItem(value);
    setCategoryOpen(false);
  };

  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {
      return (
        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={data.length < pageSize ? true : false}
          currentPage={currentPage}
          onPrevious={() => {
            onPaginate(currentPage - 1)
          }}
          onNext={() => {
            onPaginate(currentPage + 1)
          }}
        />
      );
    }
  };

  const getCategoryName = (id) => {
    let categoryName = categories.find((e) => e.value === id)
    return categoryName ? categoryName.label : ""
  }

  // Data component
  const dataComponent = () => {
    if (typeof data !== "undefined" && data.length > 0) {
      return (
        <>
          {data.map((item, index) => {
            return (
              <ForumTopic
                item={item}
              />
            );
          })}
        </>
      );
    }

    else {
      return (
        <NoData activeIndex={activeIndex} />
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */


  const parseHtml = (string) => {
    let parser = new DOMParser()
    return parser.parseFromString(string, "text/html").body.innerText
  }

  const renderNewTopicBtn = () => {

    return (
      <Row className="justify-content-end">
        <Col xs={"auto"}>
          <Button
            onClick={() => setAddTopic(true)}
          >
            <AddIcon />
            <Typography>
              New Topic
            </Typography>
          </Button>
        </Col>
      </Row>
    )
  }

  const renderNewTopic = () => {

    return (
      <Container className="new-topic-screen px-0">
        <Row className="new-topic-board-title-container">
          <Col xs="auto">
            <Button
              className="px-0 d-flex align-items-center new-topic-board-title-btn"
              onClick={() => {
                setTopicBody("");
                setTopicTitle("");
                setAddTopic(false);
              }}
            >
              <Typography
                className="new-topic-board-title"
                owner="client"
                variant="h6"
              >
                <DiscussionIcon
                  className="me-2"
                />
                {parseHtml(forum?.title)}
              </Typography>
            </Button>
          </Col>
        </Row>
        <Row className="new-topic-board-description-container mb-5">
          <Col xs={12}>
            <Typography
              className="new-topic-board-description"
              owner="client"
              variant="body1"
              weight="light"
              lh="x-wide"
            >
              {parseHtml(forum?.description__value)}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Container className="new-topic-input-container">
              <Row>
                <Col xs={12}>
                  <TextField
                    className="w-100 new-topic-input-title"
                    placeholder="Topic title..."
                    value={topicTitle}
                    sx={{
                      fontSize: "1.2rem"
                    }}
                    onChange={(e) => {
                      setTopicTitle(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextField
                    className="w-100 new-topic-input-body"
                    placeholder="Write a description..."
                    value={topicBody}
                    multiline
                    minRows={4}
                    onChange={(e) => {
                      setTopicBody(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-between">
                <Col xs="auto" className="d-flex flex-column justify-content-center">
                  <Typography
                    owner="client"
                    variant="body2"
                    weight="light"
                    lh="wide"
                  >
                    {`Posting as: ${firstName && lastName ? `${firstName} ${lastName}` : userName}`}
                  </Typography>
                </Col>
                <Col xs="auto">
                  <Button
                    disabled={topicTitle.trim() === "" || topicBody.trim() === ""}
                    onClick={() => {
                      setIsLoading(true);
                      postTopic()
                    }}
                  >
                    <Typography
                      owner="client"
                      variant="h6"
                      capitalize
                    >
                      Submit
                    </Typography>
                    <SendIcon
                      className="ms-1"
                      sx={{ fontSize: "22px" }}
                    />
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <>
      {
        isError ?

          <Error
            status={errorStatus}
            message={errorMessage}
          />
          :
          <main className={`forums-topics screen ${addTopic ? 'add-topic' : ''}`}>
            <PageHeader
              pageName={addTopic ? "Create a new topic" : decodeHtmlEntities(forum?.title)}
              // filters={true}
              owner="client"
              pageNameColor="clientText.dark"
              getContent={() => {
                setActiveIndex(0);
                setIsLoading(true);
                getContent(1, params.tid);
              }}
              activeTabIndex={activeIndex}
            />
            {isLoading ?
              <CompanyappLoader />
              :
              addTopic ?
                <>
                  {renderNewTopic()}
                </>
                :
                <>
                  <Row>
                    <Col xs={12}>
                      <Typography
                        owner="client"
                        variant="body1"
                        weight="light"
                        lh="x-wide"
                      >
                        {parseHtml(forum?.description__value)}
                      </Typography>
                    </Col>
                  </Row>
                  <ListHeader
                    items={listLabels}
                    labelConfig={{
                      owner: "client",
                      variant: "body2",
                      color: "clientPrimary.main",
                      weight: "medium",
                      lh: "wide"
                    }}
                  />
                  {dataComponent()}
                  {renderNewTopicBtn()}
                  {renderPagination()}
                </>
            }
          </main>
      }
    </>
  )
}
