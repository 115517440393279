import React, { useState, useRef } from "react";

// App
import { createPoster } from '../core/createVideoPoster';

// UI components
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import {
  Close,
  ContentCopy,
} from "@mui/icons-material";

export default function IceElement(props) {
  const [isHovered, setHovered] = useState(false);
  const [importAlert, setImportAlert] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const [copySnack, setCopySnackOpen] = useState(false);
  const hoverTimer = useRef();
  const type = props.photo.mediaType === 'MVI' ? 'video' : 'image';

  const copyToClipboard = (string) => {
    navigator.clipboard.writeText(string);
    setCopySnackOpen(true);
  };

  return (
    <Box
      className={`gallery-element ${type}`}
      sx={{
        width: props.photo.width,
        height: props.photo.height,
        opacity: props.hidden ? '0' : '',
        visibility: props.hidden ? 'hidden' : '',
      }}
    >
      <Box
        className={`ice-element d-flex ${isHovered ? "active" : "in-active"} w-100 h-100`}
      >
        <Card
          className={`mui-card ${isHovered ? "isHovered" : ""}`}
          variant="presentation-item"
          owner="admin"
          sx={{ width: "100%" }}
        >
          <CardActionArea
            className='w-100 h-100'
            onClick={() => {
              props.onSelect(props.photo);
            }}
          >
            <Box
              className='w-100 h-100'
              onMouseEnter={() => {
                hoverTimer.current = setHovered(true);
              }}
              onMouseLeave={() => {
                setHovered(false);
                clearTimeout(hoverTimer.current);
              }}
            >
              {type === 'image' && (
                <CardMedia
                  className="thumb"
                  component="img"
                  image={props.photo.src}
                  alt={props.photo.fileId}
                  sx={{ width: props.photo.width, height: props.photo.height }}
                />
              )}

              {type === 'video' && (
                <>
                  {props.photo?.videoAdditionalFileLinks[0]?.posterFrameLink && (
                    <CardMedia
                      className="thumb"
                      component="img"
                      image={props.photo?.videoAdditionalFileLinks[0]?.posterFrameLink}
                      alt={props.photo.fileId}
                      sx={{ width: props.photo.width, height: props.photo.height }}
                    />
                  )}
                </>
              )}

              <Box
                className="element-name"
                sx={{ textAlign: "center", zIndex: 999 }}
              >
                <Typography
                  variant="span"
                  className="id"
                  owner="admin"
                  color="adminText.contrastText"
                >
                  {props.photo.fileId}
                </Typography>
              </Box>
            </Box>
          </CardActionArea>
        </Card>
      </Box>

      <Snackbar
        open={importAlert}
        onClose={() => {
          setImportAlert(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant="filled"
          severity="warning"
          action={
            <Box>
              <IconButton
                size="small"
                color="inherit"
                aria-label="copy"
                onClick={() => {
                  copyToClipboard(props.photo.fileId);
                  setImportAlert(false);
                }}
              >
                <ContentCopy fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setImportAlert(false);
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          }
        >
          {`This asset has already been imported. Go back to the Media files tab and search for file ID "${props.photo.fileId}".`}
        </Alert>
      </Snackbar>

      <Snackbar
        open={importSuccess}
        onClose={() => setImportSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setImportSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {`Successfully imported file ID: "${props.photo.fileId}".`}
        </Alert>
      </Snackbar>

      <Snackbar
        open={copySnack}
        autoHideDuration={3000}
        onClose={() => setCopySnackOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setCopySnackOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          File ID copied
        </Alert>
      </Snackbar>
    </Box>
  );
}
