import React from "react";

import { serverUrl } from "../../config";
import GridItem from "../../partials/gridItem";
import { useNavigate } from "react-router-dom";

export default function VideoItem(props) {
  const navigate = useNavigate();

  return (
    <GridItem
      xs={12}
      sm={6}
      md={3}
      itemClass="video"
      nid={props.item?.nid}
      title={props.item?.title}
      field_body={props.item?.summary ? props.item?.summary : props.item?.body}
      category_label={props.item.category_labels}
      created={props.item?.created}
      hasImg={true}
      imgSrc={`${serverUrl}/api/video/thumbnail?mid=${props.item?.mid}`}
      alt={`Poster for Video ${props.item.nid}`}
      cardAction={true}
      cardActionCallBack={() => navigate(`/videos/${props.item?.nid}`)}
      contentType={props.contentType}
    ></GridItem>
  );
}
