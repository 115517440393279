import React, { useEffect, useState, useRef } from 'react';

// Modules
import * as DOMPurify from 'dompurify';

// App
import { serverUrl, filesystem_root, subdomain } from '../../../config';
import { postNode, patchNode } from '../../../core/postNode';
import { getOnlineNode } from '../../../core/getNode';
import CheckBox from '../../../partials/mui/checkbox';

// UI components
import { Col, Container, Row } from 'react-bootstrap';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Snackbar,
  TextField,
  IconButton,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box
} from '@mui/material';
import { ContentCopy, Delete, AddCircle } from '@mui/icons-material';
import DialogPaper from '../../../partials/dialogPaper';

const DEFAULT_ERROR_MESSAGE = 'User not found';

export default function Share(props) {
  const [presentation, setPresentation] = useState(props.presentation);

  const [isShareError, setShareError] = useState(false);
  const [isShareLoading, setShareLoading] = useState(true);
  const [shareErrorMessage, setShareErrorMessage] = useState(DEFAULT_ERROR_MESSAGE);

  const [isPermissionsError, setPermissionsError] = useState(false);
  const [isPermissionsLoading, setPermissionsLoading] = useState(true);
  // const [permissionsErrorMessage, setPermissionsErrorMessage] = useState(DEFAULT_ERROR_MESSAGE);
  const permissionsErrorMessage = useState(DEFAULT_ERROR_MESSAGE);

  const [hasToken, setHasToken] = useState(false);
  const [generatingToken, setGeneratingToken] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);

  const [token, setToken] = useState(null);
  const textAreaRef = useRef(null);

  // permissions
  const [userPermissions, setUserPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [accessCollectionPermissions, setAccessCollectionPermissions] = useState([]);
  // const [accessCollectionList, setAccessCollectionList] = useState([]);
  const [selectedType, setSelectedType] = useState(props.hideShare ? '1' : '0');

  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  // const [selectedUsers, setSelectedUsers] = useState([]);
  const [isTyping, setTyping] = useState(false);
  const [isSearching, setSearching] = useState(false);

  const api_endpoints = {
    users: `users?_format=json&first_name=${searchTerm}&last_name=${searchTerm}&name=${searchTerm}`,
    permissions: `api/presentation_permissions/${presentation.id}`,
  };

  // redux
  const user = props.user;

  const hideModal = () => {
    props.onHide();
  };

  useEffect(() => {
    checkPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.presentation) {
      setPresentation(props.presentation);
    }
  }, [props.presentation]);

  const checkPermission = () => {
    if (props.permissions.access_share) {
      checkTokens();
    } else {
      setShareErrorMessage("You don't have permissions to this feature.");
      setShareError(true);
    }
  };

  const checkTokens = () => {
    if (!presentation.access_token || presentation.access_token.length < 1) {
      setShareLoading(false);
      setGeneratingToken(true);
      generateToken();
    } else {
      if (presentation.status !== 'unpublished') {
        setHasToken(true);
        setShareLoading(false);
        setToken(presentation.access_token[0].token);
      } else {
        setShareErrorMessage("PLEASE NOTE: Not available until the presentation is published.");
        setShareError(true);
        setShareLoading(false);
      }
    }
  };

  const generateToken = () => {
    if (presentation.id) {
      if (presentation.status !== 'unpublished') {
        const data = {
          _links: {
            type: {
              href: serverUrl + '/rest/type/access_token/access_token',
            },
          },
          title: {
            value: `Generated by ${user.current_user.name}`
          },
          expire: [
            {
              value: '-1'
            }
          ],
          entity_type: [
            {
              value: 'presentation'
            }
          ],
          entity_id: [
            {
              value: presentation?.id
            }
          ],
          value: [
            {
              value: 'generate-token'
            }
          ],
        };

        postNode('/entity/access_token', data, props.user.csrf_token)
          .then(response => {
            setShareLoading(false);
            setGeneratingToken(false);
            setToken(response.data.value[0].value);
            setHasToken(true);

            // @TODO update list callback
          })
          .catch(error => {
            setShareLoading(false);
            setGeneratingToken(false);
            setToken(null);
            setHasToken(true);
          });
      } else {
        setShareErrorMessage("PLEASE NOTE: Not available until the presentation is published.");
        setShareError(true);
        setShareLoading(false);
      }
    } else {
      setShareErrorMessage(DEFAULT_ERROR_MESSAGE);
      setShareError(true);
      setShareLoading(false);
    }
  };

  const copyToClipboard = (string) => {
    navigator.clipboard.writeText(string);
    setSnackOpen(true);
  };

  const setSearch = value => {
    setSearchTerm(value);
    setTyping(false);
    setSearching(true);
  };

  const addUser = user => {
    const row = {
      user: {
        name: [{
          value: user.name,
        }],
        field_first_name: [{
          value: user.field_first_name,
        }],
        field_last_name: [{
          value: user.field_last_name,
        }],
        uid: [{
          value: user.uid,
        }]
      },
      permissions: {
        delete: null,
        edit: null,
        target_id: user.uid,
        target_type: 'user',
        view: true
      }
    }

    setUserPermissions(oldState => [...oldState, row]);
  };

  useEffect(() => {
    if (!isTyping && searchTerm.length > 0) {
      getOnlineNode(api_endpoints.users)
        .then(response => {
          console.log("search response", response)
          if (response.data.length > 0) {
            setUsers(response.data);
            setTyping(false);
            setSearching(false);
            setPermissionsError(false);
          } else {
            setPermissionsError(true);
            setUsers([]);
            setTyping(false);
            setSearching(false);
          }
        })
        .catch(_error => {
          console.log("search error", _error)
          setPermissionsError(true);
          setUsers([]);
          setTyping(false);
          setSearching(false);
        });
    } else {
      setUsers([]);
      setTyping(false);
      setSearching(false);
      setPermissionsError(false);
    }
  }, [isTyping, searchTerm, api_endpoints.users]);

  useEffect(() => {
    getPermissions(api_endpoints.permissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api_endpoints.permissions]);

  const getPermissions = (endpoint, callback) => {
    getOnlineNode(endpoint)
      .then(response => {
        setUserPermissions(response.data?.users);
        setRolePermissions(response.data?.roles);
        setRoleList(response.data.role_list);
        setAccessCollectionPermissions(response.data.access_collection);
        // setAccessCollectionList(response.data.access_collection_list);
        setPermissionsLoading(false);

        if (props.onPermissionsUpdate && callback) {
          props.onPermissionsUpdate(response.data);
        }
      })
      .catch(_error => {
        setUserPermissions([]);
        setRolePermissions([]);
        setRoleList([]);
        setAccessCollectionPermissions([]);
        // setAccessCollectionList([]);
        setPermissionsLoading(false);
      });
  };

  const updatePermissions = () => {
    setPermissionsLoading(true);

    let users = [];
    let roles = [];
    let access_collections = [];

    userPermissions.forEach(row => {
      users.push(row.permissions);
    });

    for (const key in rolePermissions) {
      const row = rolePermissions[key];

      roles.push(row.permissions);
    }

    for (const key in accessCollectionPermissions) {
      const row = accessCollectionPermissions[key];

      access_collections.push(row.permissions);
    }

    const data = {
      users: users,
      roles: roles,
      // access_collections: access_collections,
    };

    patchNode('api/presentation_permissions/' + presentation.id, data, props.user.csrf_token)
      .then(_response => {
        getPermissions(api_endpoints.permissions, true);
      })
      .catch(error => {
        setPermissionsLoading(false);
      });
  };

  const renderItem = item => {
    let itemStyle = '';

    let isSelected = false;

    userPermissions.forEach(user => {
      if (user?.permissions?.target_id === item.uid) {
        itemStyle = 'selected-user';
        isSelected = true;
      }
    });

    let name = '';

    if (item.field_first_name) {
      name = item.field_first_name;
    }

    if (item.field_last_name) {
      name = name + ' ' + item.field_last_name;
    }

    name = name + ' (<b>' + item.name + '</b>)';

    return (
      <Button
        className={`user ${itemStyle}`}
        disabled={isSelected ? true : false}
        onClick={() => {
          addUser(item);
        }}
      >
        <Typography
          variant='subtitle2'
          owner='admin'
          color='adminText.main'
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(name) }}
        >
        </Typography>
        {/* <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(name) }} /> */}
        <AddCircle />
      </Button>
    );
  };

  const renderUsers = (users) => {
    let new_user_permissions = users;

    return (
      <TableContainer component={Paper}>
        <Table key='user_table' stickyHeader aria-label='access' size='small'>
          <TableHead sx={{ height: '56px' }}>
            <TableRow>
              <TableCell sx={{ width: '60%' }}>
                <Typography
                  variant='subtitle2'
                  owner='admin'
                  color='adminText.main'
                  className='my-1'
                >
                  User
                </Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography
                  variant='subtitle2'
                  owner='admin'
                  color='adminText.main'
                  className='my-1'
                >
                  View
                </Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography
                  variant='subtitle2'
                  owner='admin'
                  color='adminText.main'
                  className='my-1'
                >
                  Edit
                </Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography
                  variant='subtitle2'
                  owner='admin'
                  color='adminText.main'
                  className='my-1'
                >
                  Delete
                </Typography>
              </TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row, index) => (
              <TableRow
                key={row?.user?.uid[0]?.value}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>

                  <Typography
                    variant='body2'
                    owner='admin'
                    color='adminText.main'
                  >
                    {`${row?.user?.field_first_name[0]?.value ? row?.user?.field_first_name[0]?.value : ''} ${row?.user?.field_last_name[0]?.value ? row?.user?.field_last_name[0]?.value : ''}`} <b>({`${row?.user?.name[0]?.value}`})</b>
                  </Typography>
                </TableCell>
                <TableCell align='right'>
                  <CheckBox
                    defaultValue={row?.permissions?.view}
                    toggleChecked={value => {
                      let permissions = row.permissions;
                      permissions.view = value;

                      const new_row = {
                        user: row.user,
                        permissions: permissions,
                      };

                      new_user_permissions[index] = new_row;
                      setUserPermissions(new_user_permissions);
                    }}
                    size='small'
                    color='adminPrimary'
                  />
                </TableCell>
                <TableCell align='right'>
                  <CheckBox
                    defaultValue={row?.permissions?.edit}
                    toggleChecked={value => {
                      let permissions = row.permissions;
                      permissions.edit = value;

                      const new_row = {
                        user: row.user,
                        permissions: permissions,
                      };

                      new_user_permissions[index] = new_row;
                      setUserPermissions(new_user_permissions);
                    }}
                    size='small'
                    color='adminPrimary'
                  />
                </TableCell>
                <TableCell align='right'>
                  <CheckBox
                    defaultValue={row?.permissions?.delete}
                    toggleChecked={value => {
                      let permissions = row.permissions;
                      permissions.delete = value;

                      const new_row = {
                        user: row.user,
                        permissions: permissions,
                      };

                      new_user_permissions[index] = new_row;
                      setUserPermissions(new_user_permissions);
                    }}
                    size='small'
                    color='adminPrimary'
                  />
                </TableCell>
                <TableCell align='right'>
                  <IconButton
                    aria-label='remove'
                    component='label'
                    onClick={() => {
                      let updated = [];
                      new_user_permissions.forEach((element, _index) => {
                        if (_index !== index) {
                          updated.push(element);
                        }
                      });

                      setUserPermissions(updated);
                    }}
                    color='error'
                    size='small'
                  >
                    <Delete
                      color='error'
                      fontSize='small'
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderRoles = (roles, roleList) => {
    let new_role_permissions = roles;
    let new_data = {};

    for (const key of Object.keys(roleList)) {
      const role = roles[roleList[key]?.role.id];

      new_data[key] = {
        permissions: {
          delete: role ? role?.permissions?.delete : null,
          edit: role ? role?.permissions?.edit : null,
          target_id: roleList[key]?.role?.id,
          target_type: 'user_role',
          view: role ? role?.permissions?.view : null,
        },
        roleObject: roleList[key],
      }
    };

    return (
      <TableContainer component={Paper}>
        <Table key='role_table' stickyHeader aria-label='role table' size='small'>
          <TableHead>
            <TableRow
            >
              <TableCell sx={{ width: '60%' }}>
                <Typography
                  variant='subtitle2'
                  owner='admin'
                  color='adminText.main'
                  className='my-1'
                >
                  Role
                </Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography
                  variant='subtitle2'
                  owner='admin'
                  color='adminText.main'
                  className='my-1'
                >
                  View
                </Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography
                  variant='subtitle2'
                  owner='admin'
                  color='adminText.main'
                  className='my-1'
                >
                  Edit
                </Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography
                  variant='subtitle2'
                  owner='admin'
                  color='adminText.main'
                  className='my-1'
                >
                  Delete
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(new_data).map((key, index) => {
              const row = new_data[key];
              const roleObject = row.roleObject;
              const role = roleObject.role;
              const allowed = roleObject.allowed;

              return (
                <TableRow
                  key={role?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className={allowed ? '' : 'd-none'}
                >
                  <TableCell component='th' scope='row'>
                    <Typography
                      variant='body2'
                      owner='admin'
                      color='adminText.main'
                    >
                      {role?.label}
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <CheckBox
                      defaultValue={row?.permissions?.view}
                      toggleChecked={value => {
                        let permissions = row.permissions;
                        permissions.view = value;

                        const new_row = {
                          permissions: permissions,
                          role: role,
                        };

                        new_role_permissions[role.id] = new_row;
                        setRolePermissions(new_role_permissions);
                      }}
                      size='small'
                      color='adminPrimary'
                    />
                  </TableCell>
                  <TableCell align='right'>
                    <CheckBox
                      defaultValue={row?.permissions?.edit}
                      toggleChecked={value => {
                        let permissions = row.permissions;
                        permissions.edit = value;

                        const new_row = {
                          permissions: permissions,
                          role: role,
                        };

                        new_role_permissions[role.id] = new_row;
                        setRolePermissions(new_role_permissions);
                      }}
                      size='small'
                      color='adminPrimary'
                    />
                  </TableCell>
                  <TableCell align='right'>
                    <CheckBox
                      defaultValue={row?.permissions?.delete}
                      toggleChecked={value => {
                        let permissions = row.permissions;
                        permissions.delete = value;

                        const new_row = {
                          permissions: permissions,
                          role: role,
                        };

                        new_role_permissions[role.id] = new_row;
                        setRolePermissions(new_role_permissions);
                      }}
                      size='small'
                      color='adminPrimary'
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // const renderAccessCollections = (access_collection, accessCollectionList) => {
  //   let new_access_collection_permissions = access_collection;
  //   let new_data = {};

  //   for (const key of Object.keys(accessCollectionList)) {
  //     if (new_access_collection_permissions[key]) {
  //       new_data[key] = new_access_collection_permissions[key];
  //     } else {
  //       new_data[key] = {
  //         permissions: {
  //           delete: null,
  //           edit: null,
  //           target_id: accessCollectionList[key]?.id,
  //           target_type: 'access_collection',
  //           view: null
  //         },
  //         access_collection: accessCollectionList[key],
  //       }
  //     }
  //   };

  //   return (
  //     <TableContainer component={Paper}>
  //       <Table key='role_table' stickyHeader aria-label='groups table' size='small'>
  //         <TableHead>
  //           <TableRow>
  //             <TableCell sx={{ width: '60%' }}>Access Collection</TableCell>
  //             <TableCell align='right'>View</TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {Object.keys(new_data).map((key, index) => {
  //             const row = new_data[key];
  //             return (
  //               <TableRow
  //                 key={row?.access_collection?.id[0]?.value}
  //                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
  //               >
  //                 <TableCell component='th' scope='row'>
  //                   {row?.access_collection?.title[0]?.value}
  //                 </TableCell>
  //                 <TableCell align='right'>
  //                   <CheckBox
  //                     defaultValue={row?.permissions?.view}
  //                     toggleChecked={value => {
  //                       let permissions = row.permissions;
  //                       permissions.view = value;

  //                       const new_row = {
  //                         access_collection: row.access_collection,
  //                         permissions: permissions,
  //                       };

  //                       new_access_collection_permissions[key] = new_row;
  //                       setRolePermissions(new_access_collection_permissions);
  //                     }}
  //                   />
  //                 </TableCell>
  //               </TableRow>
  //             );
  //           })}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //   );
  // };

  const shareContent = () => {
    return (
      <Row>
        <Col xs={12}>
          <Typography className='mb-3'>This is a public link that enables you to share the presentation with your client, no login required.</Typography>
        </Col>
        <Col xs={11}>
          <div className='form-group'>
            <TextField
              ref={textAreaRef}
              id='presentation-link'
              multiline
              disabled={isShareError}
              maxRows={4}
              value={isShareError ? shareErrorMessage : `${window.location.origin}/presentations/view/${presentation.id}?hash=${token}`}
              sx={{
                '& .MuiInputBase-input': {
                  pointerEvents: isShareError ? 'none' : 'all'
                },
                width: '100%'
              }}
            />
          </div>
        </Col>
        <Col xs={1}>
          <IconButton disabled={isShareError} sx={{ float: 'right' }} aria-label='copy' onClick={() => { copyToClipboard(`${window.location.origin}/presentations/view/${presentation.id}?hash=${token}`) }}>
            <ContentCopy />
          </IconButton>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Dialog
        open={props.modalVisible}
        onClose={() => hideModal}
        className='ps-modal share-modal'
        maxWidth='lg'
        fullWidth={true}
        PaperComponent={DialogPaper}
      >
        <Box className='ps-modal-title dialog-draggable-handle'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs={12} sm={6}>
                <Typography
                  variant='h3'
                  owner='admin'
                  color='adminText.dark'
                >
                  {!props.hideShare ? `Sharing & access (ID: ${presentation.id})` : 'Access'}
                </Typography>
              </Col>
              <Col xs={12} sm={6}>
                <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                  <Button
                    variant="outlined"
                    owner="admin"
                    dialogbtn="true"
                    dialogvariant="cancel"
                    className="lh"
                    onClick={() => {
                      hideModal();
                    }}>
                    <Typography
                      variant='button'
                      owner='admin'
                      className='lh'
                    >
                      Close
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    owner="admin"
                    dialogbtn="true"
                    dialogvariant="save"
                    className="lh"
                    onClick={() => {
                      // save
                      if (!isPermissionsLoading) {
                        updatePermissions();
                      }
                    }}>
                    <Typography
                      variant='button'
                      owner='admin'
                      className='lh'
                    >
                      {isPermissionsLoading ? 'Loading' : 'Save'}
                    </Typography>
                  </Button>
                </Stack>
              </Col>
            </Row>
          </Container>
        </Box>

        <DialogContent>
          <Box className='ps-modal-content'>
            <Container fluid>
              <Row className='mb-3'>
                <Col>
                  <Tabs
                    value={selectedType}
                    onChange={(event, newValue) => {
                      setSelectedType(newValue);
                    }}
                    aria-label='Access'
                  >
                    <Tab 
                    className={props.hideShare ? 'd-none' : ''} 
                    value={'0'} 
                    label={
                      <Typography
                        variant='subtitle2'
                        owner='admin'
                        sx={{
                          textTransform: 'none'
                        }}
                      >
                        Share link
                      </Typography>
                      } 
                    />
                    {presentation.has_edit_permission && (
                      <Tab value={'1'}
                        label={
                          <Typography
                            variant='subtitle2'
                            owner='admin'
                            sx={{
                              textTransform: 'none'
                            }}
                          >
                            User access
                          </Typography>
                        }
                      />
                    )}
                    {presentation.has_edit_permission && (
                      <Tab value={'2'}
                        label={
                          <Typography
                            variant='subtitle2'
                            owner='admin'
                            sx={{
                              textTransform: 'none'
                            }}
                          >
                            Role access
                          </Typography>
                        }
                      />
                    )}
                    {props.hasPdf && (
                      <Tab value={'3'} label={
                        <Typography
                          variant='subtitle2'
                          owner='admin'
                          sx={{
                            textTransform: 'none'
                          }}
                        >
                          PDF
                        </Typography>
                      }
                      />
                    )}
                  </Tabs>
                </Col>
              </Row>
              <Row className='ps-relative'>
                <Col className={selectedType === '0' ? `` : `d-none`}>
                  {isShareError ? (
                    shareContent()
                    // <Typography variant='body'>{shareErrorMessage}</Typography>
                  ) : (
                    <>
                      {isShareLoading ? (
                        <Col>
                          <CircularProgress />
                        </Col>
                      ) : (
                        <>
                          {hasToken ? (
                            shareContent()
                          ) : (
                            <Col>
                              {generatingToken && (
                                <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
                                  <CircularProgress />
                                  <Typography variant='body'>Generating share link...</Typography>
                                </Stack>
                              )}
                            </Col>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Col>
                <Col className={selectedType === '1' ? `` : `d-none`}>
                  {isPermissionsLoading && (
                    <Box className='circular-container fs'>
                      <CircularProgress />
                    </Box>
                  )}
                  <Row>
                    <Col xs={12}>
                      <Typography
                        variant='body2'
                        owner='admin'
                        className='mb-3'
                        color='adminText.main'
                      >
                        Use this feature to give view, edit or delete access to your presentation to a colleague.
                      </Typography>
                    </Col>
                  </Row>
                  <Box className='bordered'>
                    <Row>
                      <Col xs={12} sm={4}>
                        <section className='users-section'>
                          <div className='form-group'>
                            <Row>
                              <Col>
                                <Typography
                                  variant='subtitle2'
                                  owner='admin'
                                  color='adminPrimary.main'
                                  className='search-label'
                                  lh='wide'
                                >
                                  Search users
                                </Typography>
                              </Col>
                              <Col xs={'auto'} className='d-flex align-items-center mb-1'>
                                {isSearching && (
                                  <CircularProgress size={20} />
                                )}
                              </Col>
                            </Row>

                            <Row className='mb-1'>
                              <Col>
                                <TextField
                                  key='name'
                                  id='user-search'
                                  label='Type a name'
                                  variant='outlined'
                                  autoComplete='off'
                                  onChange={(event) => {
                                    event.preventDefault();
                                    setSearch(event.target.value);
                                  }}
                                  value={searchTerm} />
                              </Col>
                            </Row>

                            {users && users.length > 0 ? (
                              <div className='users-container'>
                                {users.map((item, index) => {
                                  return (
                                    <div key={`user-${index}`}>{renderItem(item)}</div>
                                  );
                                })}
                              </div>
                            ) : (
                              <>
                                {isPermissionsError && (
                                  <div className='error-message'>
                                    <Typography
                                      variant='body2'
                                      owner='admin'
                                      color='adminText.main'
                                      weight='light'
                                    >
                                      {permissionsErrorMessage}
                                    </Typography>
                                    {/* <span>{permissionsErrorMessage}</span> */}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </section>
                      </Col>
                      <Col xs={12} sm={8}>
                        <section className='users-section'>
                          <div className='form-group'>
                            <Row>
                              <Col>
                                <label style={{ opacity: 0 }} className='search-label'>Current</label>
                              </Col>
                            </Row>
                            <Row>
                              {renderUsers(userPermissions)}
                            </Row>
                          </div>
                        </section>
                      </Col>
                    </Row>
                  </Box>

                </Col>
                <Col className={selectedType === '2' ? `` : `d-none`}>
                  {isPermissionsLoading && (
                    <Box className='circular-container fs'>
                      <CircularProgress />
                    </Box>
                  )}
                  <Row>
                    <Col xs={12}>
                      <Typography
                        variant='body2'
                        owner='admin'
                        className='mb-3'
                        color='adminText.main'
                      >
                        Use this feature to give view, edit or delete access to your presentation to a colleague on a role basis.
                      </Typography>
                    </Col>
                  </Row>
                  <Box className='bordered'>
                    <Row>
                      <Col xs={12}>
                        {renderRoles(rolePermissions, roleList)}
                      </Col>
                    </Row>
                  </Box>
                </Col>

                <Col className={selectedType === '3' ? `` : `d-none`}>
                  <Row>
                    <Col xs={12}>
                      <Typography className='mb-3'>
                        <a
                          href={`${filesystem_root}/${subdomain}/presentations/${presentation.id}/${presentation.id}.pdf`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {`Click to download`}
                        </a>
                      </Typography>
                    </Col>
                  </Row>
                </Col>

                {/* <Col className={selectedType === '3' ? `` : `d-none`}>
                  {isPermissionsLoading && (
                    <Box className='circular-container fs'>
                      <CircularProgress />
                    </Box>
                  )}
                  <Row>
                    <Col xs={12}>
                      <Typography className='mb-3'>Use this feature to give view access to a group of users.</Typography>
                    </Col>
                  </Row>
                  <Box className='bordered'>
                    <Row>
                      <Col xs={12}>
                        {renderAccessCollections(accessCollectionPermissions, accessCollectionList)}
                      </Col>
                    </Row>
                  </Box>
                </Col> */}
              </Row>
            </Container>
          </Box>
        </DialogContent>

        <Snackbar open={snackOpen} autoHideDuration={3000} onClose={() => setSnackOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={() => setSnackOpen(false)} severity='success' sx={{ width: '100%' }}>
            Link copied!
          </Alert>
        </Snackbar>
      </Dialog >
    </>
  );
};
