import { api } from '../config';

export const postNode = (endpoint, node, csrf_token) => {
  let postUri = 'node?_format=hal_json';

  if (endpoint) {
    postUri = endpoint;
  }

  return api({
    method: 'post',
    url: postUri + '?_format=hal_json',
    data: JSON.stringify(node),
    headers: {
      Accept: 'application/hal+json',
      'Content-Type': 'application/hal+json',
      'X-CSRF-Token': csrf_token,
    },
  });
};

export const customPost = (endpoint, data, headers) => {
  return api({
    method: 'post',
    url: endpoint,
    data: JSON.stringify(data),
    headers: headers,
  });
};

export const patchNode = (endpoint, node, csrf_token, customEndpoint) => {
  let url = endpoint;
  if (!customEndpoint) {
    url = endpoint + '?_format=hal_json';
  }

  return api({
    method: 'patch',
    url: url,
    data: JSON.stringify(node),
    headers: {
      Accept: 'application/hal+json',
      'Content-Type': 'application/hal+json',
      'X-CSRF-Token': csrf_token,
    },
  });
};
