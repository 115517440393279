import { React, useEffect, useState, useRef } from "react";

// Modules
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// App
import { serverUrl, presenterTaxonomies } from "../../config";
import { patchNode } from "../../core/postNode";
import { getOnlineNode } from "../../core/getNode";
import PageHeader from "../../partials/pageHeader";
import { alertMessages } from "../../partials/alertMessages";
import AlertModal from "../../partials/alertModal";
import Error from "../../partials/error";
import AccountSkeletonScreen from "./skeleton-screens/accountSkeletonScreen";
import { editProfileEnabled } from "../../config";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  CircularProgress,
  Typography,
} from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import CategorySelector from "../../partials/categorySelector";
import { Link } from "react-router-dom";
import UserImage from "../../partials/userImage";
import Creator from "../../partials/creator";

export default function EditProfile(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [_user, _setUser] = useState("");

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [show_info, setShow_info] = useState("");
  const [field_push_notifications, setField_push_notifications] = useState("");
  const [field_email_notifications, setField_email_notifications] =
    useState("");
  const [field_job_title, setField_job_title] = useState("");
  const [field_url, setField_url] = useState("");
  const [field_address, setField_address] = useState("");
  const [field_email_template, setField_email_template] = useState("");
  const [field_sita, setField_sita] = useState("");
  const [field_cost_centre, setField_cost_centre] = useState("");
  const [field_region, setField_region] = useState("");
  const [profile_picture, setProfile_picture] = useState(null);
  const [isMailChanged, setIsMailChanged] = useState(false);
  const [isPassChanged, setIsPassChanged] = useState(false);

  const [category_email_template, setCategory_email_template] = useState(null);
  const [category_sita, setCategory_sita] = useState(null);
  const [category_region, setCategory_region] = useState(null);

  const [modalVisible, setModalVisible] = useState(false);

  const [isAccountLoading, setIsAccountLoading] = useState(false);
  const [isAccountError, setIsAccountError] = useState(false);
  const [accountErrorStatus, setAccountErrorStatus] = useState("");
  const [accountErrorMessage, setAccountErrorMessage] = useState("");

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [alertType, setAlertType] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertBody, setAlertBody] = useState("");
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);

  const [postModalVisible, setPostModalVisible] = useState(false);

  const user = useSelector((state) => state).authReducer.user;
  const navigate = useNavigate();

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getContent();
  }, [_user]);

  useEffect(() => {
    if (presenterTaxonomies) {
      if (
        category_email_template !== null &&
        category_sita !== null &&
        category_region !== null
      ) {
        getAccount();
      }
    } else {
      getAccount();
    }
  }, [category_email_template, category_sita, category_region]);

  const backToAccount = useRef(null);
  const containerRef = useRef(null);

  const getAccount = () => {
    if (typeof user.current_user === "undefined") {
      navigate("/");
    } else {
      getOnlineNode("custom_user/" + user.current_user.uid)
        .then((response) => {
          _setUser(response.data.rows[0]);
        })
        .catch((_error) => {
          if (_error.response) {
            setIsAccountLoading(false);
            setIsAccountError(true);
            setAccountErrorStatus(200);
            setAccountErrorMessage(alertMessages.noData);
            setError(true, _error.response.status, _error.response.statusText);
          } else if (_error.request) {
            setIsAccountLoading(false);
            setIsAccountError(true);
            setAccountErrorStatus(0);
            setAccountErrorMessage(alertMessages.requestError.message);
            //console.log('@rest request: ', _error.request);
            setError(true, 0, alertMessages.requestError.message);
          } else {
            setIsAccountLoading(false);
            setIsAccountError(true);
            setAccountErrorStatus(0);
            setAccountErrorMessage(alertMessages.unkownError.message);
            //console.log('@rest unknown: ', _error);
            setError(true, 0, alertMessages.unkownError.message);
          }
        });
    }
  };

  const getContent = () => {
    console.log("user: ", _user);
    if (_user) {
      let show_info = true;
      let field_push_notifications = true;
      let field_email_notifications = true;

      if (_user.field_show_info === "false") {
        show_info = false;
      }

      if (_user.field_push_notifications === "false") {
        field_push_notifications = false;
      }

      if (_user.field_email_notifications === "false") {
        field_email_notifications = false;
      }

      let sita_code = category_sita?.find((el) => {
        if (el.value === _user.field_sita) {
          return el;
        }
      });

      let email_template = category_email_template?.find((el) => {
        if (el.label === _user.field_email_template) {
          return el.value;
        }
      });

      setFirst_name(_user.field_first_name);
      setLast_name(_user.field_last_name);
      setPhone_number(_user.phone_number);
      setMail(_user.mail);
      setShow_info(show_info);
      setField_push_notifications(field_push_notifications);
      setField_email_notifications(field_email_notifications);
      setField_job_title(_user.field_job_title);
      setField_address(_user.field_address);
      setField_url(_user.field_url);

      if (presenterTaxonomies) {
        setField_email_template(
          email_template ? email_template.value : category_email_template[0]
        );
        setField_cost_centre(_user.field_cost_centre);
        setField_sita(sita_code ? sita_code : category_sita[0]);
        setField_region(_user.field_region);
      }
      setIsLoading(false);
    }
  };

  const getCategories = () => {
    if (presenterTaxonomies) {
      getOnlineNode("api/categories?vid=email_templates,sita,region")
        .then((response) => {
          let email_template = [
            {
              value: 0,
              label: "Select Email signature style",
            },
          ];

          let sita = [
            {
              value: 0,
              label: "None selected",
            },
          ];

          let region = [
            {
              value: 0,
              label: "Select Subscriber area",
            },
          ];

          response.data.forEach((item) => {
            if (item.has_view_permission === true) {
              if (item.vid === "email_templates")
                email_template.push({
                  value: item.tid,
                  label: item.title,
                });

              if (item.vid === "region")
                region.push({
                  value: item.tid,
                  label: item.title,
                });

              if (item.vid === "sita")
                sita.push({
                  value: item.tid,
                  label: item.title,
                });
            }
          });
          setCategory_email_template(email_template);
          setCategory_sita(sita);
          setCategory_region(region);
        })
        .catch((_error) => { });
    }
  };

  const setError = (_isError, _status, _message) => {
    setIsLoading(false);
    setIsRefreshing(false);
    setIsError(_isError);
    setErrorStatus(_status);
    setErrorMessage(_message);
  };

  const updateUser = () => {
    const uid = user.current_user.uid;

    // if (isPassChanged && newPassword.length < 5) {
    //   setModalVisible(true);
    //   setAlertType('error');
    //   setAlertTitle(alertMessages.editProfilePasswordTooShort.title);
    //   setAlertBody(alertMessages.editProfilePasswordTooShort.message);
    //   setAlertConfirmButton(true);
    //   return false;
    // }
    setIsSubmitting(true);

    // let password = [{ existing: password }];

    // if (isPassChanged && newPassword) {
    //   password = [
    //     { existing: password, value: newPassword },
    //   ];
    // }

    const data = {
      _links: {
        type: {
          href: serverUrl + "/rest/type/user/user",
        },
      },
      uid: {
        value: uid,
      },
      field_first_name: {
        value: first_name,
      },
      field_last_name: {
        value: last_name,
      },
      phone_number: {
        value: phone_number,
      },
      mail: {
        value: mail,
      },
      // pass: password,
      field_job_title: {
        value: field_job_title,
      },
      field_url: {
        value: field_url,
      },
      field_address: {
        value: field_address,
      },
      field_email_template: [{ target_id: field_email_template }],
      mail: [_user.mail],
      // field_cost_centre: {
      //   value: field_cost_centre
      // },
      // field_sita: [{ target_id: field_sita }],
      // field_region: [{ target_id: field_region }],
    };

    patchNode("user/" + uid, data, user.csrf_token)
      .then((response) => {
        backToAccount.current.click();
      })
      .catch((error) => {
        // temporary
        // console.log('@user error: ', error.response);

        // // temp simple_oauth error
        if (error?.response?.status === 500) {
          backToAccount.current.click();
        } else {
          if (error?.response?.data?.message) {
            // setState({
            setIsSubmitting(false);
            setModalVisible(true);
            setAlertType("error");
            setAlertTitle(alertMessages.unkownError.title);
            setAlertBody(error.response.data.message);
            setAlertConfirmButton(true);
            // });
          } else {
            setIsSubmitting(false);
            setModalVisible(true);
            setAlertType("error");
            setAlertTitle(alertMessages.editProfileUpdateFailed.title);
            setAlertBody(alertMessages.editProfileUpdateFailed.message);
            setAlertConfirmButton(true);
          }
        }
      });
  };

  const passwordComponent = (note, label, placeholder) => {
    if (!isMailChanged && !isPassChanged) {
      return null;
    } else {
      return (
        <div className="form-group">
          <Row>
            <Col>
              <p className="text-danger">{note}</p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <label>{label}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                type="password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                placeholder={placeholder}
                className="form-control"
              />
            </Col>
          </Row>
        </div>
      );
    }
  };

  const errorAlerts = () => {
    return (
      <AlertModal
        showAlert={modalVisible}
        showAlertCallback={(condition) => setModalVisible(condition)}
        alertType={alertType}
        alertMessageTitle={alertTitle}
        alertMessageBody={alertBody}
        // cancelButton={alertCancelButton}
        confirmButton={alertConfirmButton}
      // cancelButtonLabel={cancelButtonLabel}
      // confirmButtonLabel={confirmButtonLabel}
      />
    );
  };

  const saveAlerts = () => {
    return (
      <AlertModal
        showAlert={postModalVisible}
        showAlertCallback={() => {
          let tempPostModalVisible = !postModalVisible;
          setPostModalVisible(tempPostModalVisible);
        }}
        alertType={"success"}
        alertMessageTitle={alertTitle}
        alertMessageBody={alertBody}
        cancelButton={false}
        confirmButton={true}
        confirmButtonLabel={"OK"}
        onConfirm={() => {
          navigate("/account");
        }}
      />
    );
  };

  const renderProfilePic = () => {
    let imageURI = undefined;

    if (_user.user_picture) {
      imageURI = _user.user_picture;
    }

    return (
      <Row>
        <Col
          className="d-flex justify-content-center align-content-center"
          xs={12}
          lg={12}
        >
          <UserImage
            name={`${_user?.field_first_name} ${_user?.field_last_name}`}
            image={imageURI}
            // userBrand={"rhg"}
            clientColor={"adminPrimary.main"}
          />
        </Col>
      </Row>
    );
  };

  const renderUserGreeting = () => {
    if (_user.field_first_name) {
      return (
        <Row className={"greeting"}>
          <Col
            className="d-flex justify-content-center align-content-center"
            xs={12}
            lg={12}
          >
            <Typography
              variant="h3"
              owner="admin"
              color="adminText.main"
              className="mb-2"
            >
              Hi, {_user.field_first_name}
            </Typography>
          </Col>
        </Row>
      );
    }
  };

  const renderUsername = () => {
    if (_user.name) {
      return (
        <Row className={"username d-flex justify-content-center my-1"}>
          <Col
            className="d-flex justify-content-center align-content-center pe-0"
            xs={"auto"}
          >
            <Typography
              owner="admin"
              variant="body1"
              color="adminText.main"
              weight="light"
              lh="wide"
            >
              Username:
            </Typography>
          </Col>
          <Col xs={"auto"} className="ps-1">
            <Typography
              owner="admin"
              variant="body1"
              color="adminText.main"
              lh="wide"
            >
              {_user.name}
            </Typography>
          </Col>
        </Row>
      );
    }
  };

  const renderUserRoles = () => {
    if (_user.roles_target_id) {
      return (
        <Row className={"role d-flex justify-content-center my-1"}>
          <Col
            className="d-flex justify-content-center align-content-center pe-0"
            xs={"auto"}
          >
            <Typography
              owner="admin"
              variant="body1"
              color="adminText.main"
              weight="light"
              lh="wide"
            >
              Role:
            </Typography>
          </Col>
          <Col xs={"auto"} className="ps-1">
            <Typography
              owner="admin"
              variant="body1"
              color="adminText.main"
              lh="wide"
            >
              {_user.roles_target_id}
            </Typography>
          </Col>
        </Row>
      );
    }
  };

  const renderAccountDetails = () => {
    return (
      <>
        {renderProfilePic()}
        {renderUserGreeting()}
        {renderUsername()}
        {renderUserRoles()}
      </>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  if (isLoading) {
    return <AccountSkeletonScreen />;
  } else {
    if (isError) {
      return <Error status={errorStatus} message={errorMessage} />;
    } else {
      let note, label, placeholder;

      if (isMailChanged && isPassChanged) {
        note =
          "Note: To change your email or current password you must confirm your existing password below";
        label = "Current Password";
        placeholder = "Enter your current password...";
      } else {
        if (isMailChanged) {
          note =
            "Note: To change your email address you must confirm your password below";
          label = "Password";
          placeholder = "Enter your password...";
        }

        if (isPassChanged) {
          note =
            "Note: To change your password you must confirm your existing password below";
          label = "Current password";
          placeholder = "Enter your current password...";
        }
      }

      return (
        <main
          ref={containerRef}
          className="edit-profile screen d-flex flex-column h-100 justify-content-between"
        >
          <Row>
            <Col xs={12} lg={6} className="mx-lg-auto">
              {renderAccountDetails()}
              {errorAlerts()}
              {saveAlerts()}

              {editProfileEnabled &&
                <>
                  <PageHeader pageName="Edit Profile" filters={false} />

                  {/* First name */}
                  <div className="form-group">
                    <Row className="mt-2">
                      <Col>
                        <Typography
                          owner="admin"
                          variant="form-group-label"
                          lh="wide"
                        >
                          First name
                        </Typography>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <input
                          type="text"
                          onChange={(event) => {
                            setFirst_name(event.target.value);
                          }}
                          placeholder={"Add your first name..."}
                          value={first_name}
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>

                  {/* Last name */}
                  <div className="form-group">
                    <Row className="mt-2">
                      <Col>
                        <Typography
                          owner="admin"
                          variant="form-group-label"
                          lh="wide"
                        >
                          Last name
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          type="text"
                          onChange={(event) => {
                            setLast_name(event.target.value);
                          }}
                          // placeholder={
                          //   user.field_last_name.length > 0
                          //     ? user.field_last_name
                          //     : "Add your last name..."
                          // }
                          placeholder={"Add your last name..."}
                          value={last_name}
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>

                  {/* Job title */}
                  <div className="form-group">
                    <Row className="mt-2">
                      <Col>
                        <Typography
                          owner="admin"
                          variant="form-group-label"
                          lh="wide"
                        >
                          Job title
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          type="text"
                          onChange={(event) => {
                            setField_job_title(event.target.value);
                          }}
                          // placeholder={
                          //   user.field_last_name.length > 0
                          //     ? user.field_last_name
                          //     : "Add your last name..."
                          // }
                          placeholder={"Add job title..."}
                          value={field_job_title}
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>

                  {/* Phone number */}
                  <div className="form-group">
                    <Row className="mt-2">
                      <Col>
                        <Typography
                          owner="admin"
                          variant="form-group-label"
                          lh="wide"
                        >
                          Phone number
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          // type="text"
                          // onChange={(phone_number) => {
                          //   setState({
                          //     phone_number: phone_number,
                          //   });
                          // }}
                          // placeholder={
                          //   user.phone_number.length > 0
                          //     ? user.phone_number
                          //     : "Add a phone number..."
                          // }
                          type="tel"
                          onChange={(event) => {
                            setPhone_number(event.target.value);
                          }}
                          placeholder={"Add your phone number..."}
                          value={phone_number}
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>

                  {/* Email */}

                  {/* Email and password */}
                  {
                    // user.current_user.name !== "demo" &&
                    // (
                    //   <>
                    //     {/* Email */}
                    //     <div className="form-group">
                    //       <Row className="mt-2">
                    //         <Col>
                    //           <label>Email</label>
                    //         </Col>
                    //       </Row>
                    //       <Row>
                    //         <Col>
                    //           <input
                    //             type="email"
                    //             onChange={(event) => {
                    //               let hasMailChanged = false;
                    //               let mail = event.target.value;
                    //               if (mail !== user.mail) {
                    //                 hasMailChanged = true;
                    //               }
                    //               if (mail.length < 1) {
                    //                 hasMailChanged = false;
                    //               }
                    //               setState({
                    //                 mail: mail,
                    //                 isMailChanged: hasMailChanged,
                    //               });
                    //             }}
                    //             // placeholder={
                    //             //   user.mail.length > 0
                    //             //     ? user.mail
                    //             //     : "Add your email..."
                    //             // }
                    //             placeholder={"Add your email..."}
                    //             value={mail}
                    //             className="form-control"
                    //           />
                    //         </Col>
                    //       </Row>
                    //     </div>
                    //     {/* New password */}
                    //     <div className="form-group">
                    //       <Row className="mt-2">
                    //         <Col>
                    //           <label>New Password</label>
                    //         </Col>
                    //       </Row>
                    //       <Row>
                    //         <Col>
                    //           <input
                    //             type="password"
                    //             onChange={(event) => {
                    //               let hasPassChanged = true;
                    //               let newPassword = event.target.value;
                    //               if (newPassword.length < 1) {
                    //                 hasPassChanged = false;
                    //               }
                    //               setState({
                    //                 newPassword: newPassword,
                    //                 isPassChanged: hasPassChanged,
                    //               });
                    //             }}
                    //             placeholder={"New Password..."}
                    //             className="form-control"
                    //           />
                    //         </Col>
                    //       </Row>
                    //     </div>
                    //     {/* Current password */}
                    //     {passwordComponent(note, label, placeholder)}
                    //   </>
                    // )
                  }

                  {/* Web address (URL) */}
                  <div className="form-group">
                    <Row className="mt-2">
                      <Col>
                        <Typography
                          owner="admin"
                          variant="form-group-label"
                          lh="wide"
                        >
                          Web address (URL)
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          type="url"
                          onChange={(event) => {
                            setField_url(event.target.value);
                          }}
                          placeholder={"Add your web address..."}
                          value={field_url}
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>

                  {/* Physical address */}
                  <div className="form-group">
                    <Row className="mt-2">
                      <Col>
                        <Typography
                          owner="admin"
                          variant="form-group-label"
                          lh="wide"
                        >
                          Physical address
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          type="url"
                          onChange={(event) => {
                            setField_address(event.target.value);
                          }}
                          placeholder={"Add your physical address..."}
                          value={field_address}
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>

                  {presenterTaxonomies && (
                    <div className="form-group">
                      <Row className="mt-2">
                        <Col>
                          <Typography
                            owner="admin"
                            variant="form-group-label"
                            lh="wide"
                          >
                            Email signature style
                          </Typography>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CategorySelector
                            label="Email signature style"
                            variant="outlined"
                            size="small"
                            selectedCategory={{
                              value: field_email_template,
                            }}
                            handleCategorySelection={(id) => {
                              setField_email_template(id);
                            }}
                            categories={
                              category_email_template
                                ? category_email_template
                                : [{ value: 0, label: "" }]
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  )}

                  {presenterTaxonomies && (
                    <Accordion className="ca-accordion edit-profile">
                      <AccordionSummary
                        className="ca-accordion-summary"
                        expandIcon={<KeyboardArrowRight />}
                      >
                        <Col xs={12} className="d-flex align-items-center">
                          Subscription information
                          <Divider className="mt-1 ms-2 flex-grow-1" />
                        </Col>
                      </AccordionSummary>
                      <AccordionDetails
                        className="ca-accordion-details"
                        sx={{
                          marginBottom: "10px",
                          paddingRight: "10%",
                          paddingLeft: "10%",
                        }}
                      >
                        <div className="form-group">
                          <Row className="mt-2">
                            <Col xs={4}>
                              <Typography
                                owner="admin"
                                variant="form-group-label"
                                lh="wide"
                              >
                                Subscriber area :
                              </Typography>
                            </Col>
                            <Col xs={8}>
                              <Typography
                                owner="admin"
                                variant="body2"
                                color="adminText.main"
                                lh="wide"
                                weight="light"
                              >
                                {field_region ? field_region : "None selected"}
                              </Typography>
                            </Col>
                          </Row>
                        </div>

                        {/* SITA */}
                        <div className="form-group">
                          <Row className="mt-2">
                            <Col xs={4}>
                              <label>SITA code :</label>
                            </Col>
                            <Col xs={8}>
                              <Typography
                                owner="admin"
                                variant="body2"
                                color="adminText.main"
                                lh="wide"
                                weight="light"
                              >
                                {field_sita ? field_sita?.label : "None selected"}
                              </Typography>
                            </Col>
                          </Row>
                        </div>

                        {/* Cost Centre */}
                        <div className="form-group">
                          <Row className="mt-2">
                            <Col xs={4}>
                              <Typography
                                owner="admin"
                                variant="form-group-label"
                                lh="wide"
                              >
                                Cost centre :
                              </Typography>
                            </Col>
                            <Col xs={8}>
                              <Typography
                                owner="admin"
                                variant="body2"
                                color="adminText.main"
                                lh="wide"
                                weight="light"
                              >
                                {field_cost_centre
                                  ? field_cost_centre
                                  : "None selected"}
                              </Typography>
                            </Col>
                          </Row>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )}

                  <div className={`form-group ${!presenterTaxonomies ? 'mt-3' : ''}`}>
                    <Row className="align-items-center justify-content-between">
                      <Col>
                        <Link to={"/"}>
                          <Button
                            variant="outlined"
                            sx={{
                              textTransform: "none",
                            }}
                          >
                            <Typography
                              owner="admin"
                              variant="subtitle1"
                              weight="medium"
                              lh="wide"
                            >
                              Cancel
                            </Typography>
                          </Button>
                        </Link>
                      </Col>
                      <Col xs={"auto"}>
                        {isSubmitting ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            className="submit-btn"
                            onClick={updateUser}
                            sx={{
                              textTransform: "none",
                              backgroundColor: "secondary.main",
                              color: "solid.light",
                              "&:hover": {
                                backgroundColor: "secondary.dark",
                              },
                            }}
                          >
                            <Typography
                              owner="admin"
                              variant="subtitle1"
                              weight="medium"
                              lh="wide"
                            >
                              Save profile
                            </Typography>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </>}

            </Col>
          </Row>


          <Creator />

          <div style={{ display: "none" }}>
            <a
              id="back-to-account"
              href="/"
              ref={(link) => (backToAccount.current = link)}
            >
              Account
            </a>
          </div>
        </main>
      );
    }
  }
}
