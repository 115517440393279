import React, { useState, useEffect, useRef } from 'react';

// Modules
import { useSelector } from 'react-redux';

// App
import { getOnlineNode } from '../../../../core/getNode';
import { paginator } from '../../../../core/paginator';
import PresentationFilters from '../filters';
import NoData from '../../../../partials/noData';
import Presentation from '../presentation/view/presentation';
import SlideThumbnail from '../slide/view/thumbnail';
import Pagination from '../../../../partials/pagination';
import { jsonParser } from '../../../../utils/helpers';
import { getFavoritesByContentType } from '../../../../utils/helpers';

// UI components
import { Container, Row, Col } from 'react-bootstrap';
import {
  AppBar,
  Dialog,
  DialogContent,
  Stack,
  Toolbar,
  Typography,
  Button,
  DialogActions,
  Box,
  Pagination as MuiPagination
} from '@mui/material';
import CompanyappLoader from '../../../../partials/companyappLoader';

export default function AddLibrarySlide(props) {
  const [isLoading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isError, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isPaginating, setPaging] = useState(false);
  const slidesContainer = useRef();

  /**
   * Pagination
   */
  const pageSize = 12;
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * Data
   */
  const [presentations, setPresentations] = useState([]);
  const [selectedPresentation, setSelectedPresentation] = useState(null);
  const [selectedSlides, setSelectedSlides] = useState([]);
  const [presentationJson, setPresentationJson] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);

  /**
   * Filters Data
   */
  // const [title, setTitle] = useState('');
  // const [selectedCategory, setSelectedCategory] = useState(0);
  // const [author, setAuthor] = useState('');
  // const [selectedLibrary, setSelectedLibrary] = useState(0);
  // const [selectedStatus, setSelectedStatus] = useState(0);
  // const [selectedLanguage, setSelectedLanguage] = useState(0);
  // const [selectedTheme, setSelectedTheme] = useState(0);
  // const [selectedIDSita, setSelectedIDSita] = useState('');

  const [filters, setFilters] = useState({
    title: '',
    language: 0,
    category: 0,
    author: '',
    idSita: '',
    status: 0,
    library: 0,
  })

  const favorites = useSelector((state) => state).favoritesReducer;
  const contentType = "presentation-item"
  const favStrings = getFavoritesByContentType(favorites, contentType, true);

  useEffect(() => {
    getContent();
  }, [currentPage, props.type, filters]);

  useEffect(() => {
    if (activeIndex === 1 && favStrings === "") {
      setLoading(false);
      setPresentations([]);
      setTotalItems(0);
    }
    else {
      getContent();
    }
  }, [activeIndex]);

  useEffect(() => {
    if (activeIndex === 1) {
      if (favStrings !== "") {
        getContent();
      }
      else {
        setPresentations([]);
        setLoading(false);
      }
    }
  }, [favStrings])

  const getContent = () => {
    let path = `api/user_presentations?&items_per_page=${pageSize}&page=${currentPage - 1}`;
    path = path + '&presentation_status=' + ["published", "modified"];
    path = path + '&all_slides=true&live=true';

    if (filters.category && filters.category !== 0) {
      path = path + '&category=' + filters.category;
    }

    if (filters.title && filters.title !== '') {
      path = path + '&id_or_title=' + filters.title;
    }

    if (filters.author && filters.author !== '') {
      if (typeof filters.author === 'string') {
        path = path + '&author_name=' + filters.author;
      } else {
        if (filters.author.value) {
          path = path + '&author_name=' + filters.author.value;
        }
      }
    }

    if (filters.language && filters.language !== 0) {
      path = path + '&language=' + filters.language;
    }

    if (filters.library && filters.library !== 0) {
      path = path + '&library=' + filters.library;
    }

    if (filters.theme && filters.theme !== 0) {
      path = path + '&theme=' + filters.theme;
    }

    if (filters.idSita && filters.idSita !== '') {
      path = path + '&id_or_sita=' + filters.idSita;
    }

    if (presentations.length > 0) {
      const lastElement = presentations[presentations.length - 1];

      if (lastElement && lastElement.id) {
        path += `&lastId=${lastElement.id}`;
      }
    }

    if (activeIndex === 1 && favStrings !== "") {
      path += `&id=${favStrings}`
    }

    getOnlineNode(path)
      .then((response) => {
        setPresentations(response.data.rows);
        setTotalItems(response.data.pager.total_items);
        setPaging(false);
        setLoading(false);
      })
      .catch((_error) => {
        setError(true);
      });
  }

  const onPaginate = (event, value) => {
    setPaging(true);
    setCurrentPage(value);
  };

  const onClose = () => {
    setSelectedPresentation(null);
    setSelectedSlides([]);
    props.onLayoutClose();
  }

  const onPresentationSelected = (presentation) => {
    setSelectedSlides([]);
    setSelectedPresentation(presentation);
    setPresentationJson({ ...jsonParser(presentation.json) })

    slidesContainer?.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const onSlideToggle = (slide) => {
    let newSelectedSlides = [...selectedSlides];
    if (!newSelectedSlides.includes(slide.id)) {
      newSelectedSlides.push(slide.id);
    } else {
      newSelectedSlides.splice(newSelectedSlides.indexOf(slide.id), 1);
    }

    setSelectedSlides(newSelectedSlides);
  }

  const selectAllSlides = () => {
    let allSlides = selectedPresentation.slides.map((slide) => {
      if (props.type === 'copy') {
        return slide.id
      }
      else {
        if (slide.prevent_duplicate !== "1" && slide.published) {
          return slide.id
        }
      }
    });
    setSelectedSlides(allSlides);
  }

  const processNotes = () => {
    let notesObj = {}
    if (selectedSlides.length > 0) {
      selectedSlides.forEach((e) => {
        if (presentationJson?.notes?.hasOwnProperty(e)) {
          notesObj[e] = presentationJson.notes[e];
        }
      });
    }
    if (Object.keys(notesObj).length > 0) {
      return notesObj;
    }
    else return null;
  }

  const processAudio = () => {
    let slideAudioObj = {}
    if (selectedSlides.length > 0) {
      selectedSlides.forEach((e) => {
        if (presentationJson?.slideAudioObj?.hasOwnProperty(e)) {
          slideAudioObj[e] = presentationJson.slideAudioObj[e];
        }
      });
    }
    if (Object.keys(slideAudioObj).length > 0) {
      return slideAudioObj;
    }
    else return null;
  }

  let pagination = paginator(
    totalItems,
    currentPage,
    pageSize,
    3,
  );

  return (
    <Dialog
      fullScreen={true}
      open={props.fromLibraryOpen}
      onClose={props.onLayoutClose}
      className="presentation-library-modal"
    >
      <AppBar sx={{ position: 'relative', backgroundColor: 'primary.light', color: 'solid.dark' }} >
        <DialogContent sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Row className='w-100'>
              <Col>
                {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}> */}
                <Typography variant='h3' owner='admin' color='adminText.dark'>
                  {props.title}
                </Typography>
              </Col>
              <Col
                className='d-flex justify-content-end align-items-center'
              >
                <Row>
                  <Col className='d-flex justify-content-end'>
                    <Stack direction='row' spacing={2}>
                      <Button
                        variant='outlined'
                        owner='admin'
                        dialogbtn="true"
                        dialogvariant='cancel'
                        onClick={() => {
                          onClose();
                        }}
                      >
                        <Typography
                          variant="button"
                          owner="admin"
                          className="lh"
                        >
                          Cancel
                        </Typography>
                      </Button>
                      <Button
                        variant="contained"
                        owner="admin"
                        dialogbtn="true"
                        dialogvariant="save"
                        className="lh"
                        onClick={() => {
                          props.onAddSlides(selectedSlides, processNotes(), processAudio());
                          setSelectedPresentation(null);
                          setSelectedSlides([]);
                        }}
                      >
                        <Typography
                          variant="button"
                          owner="admin"
                          className="lh"
                        >
                          Insert
                        </Typography>
                      </Button>
                    </Stack>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                onClose();
              }}
              aria-label="close"
            >
              <Close />
            </IconButton> */}
          </Toolbar>
        </DialogContent>
      </AppBar>

      <DialogContent sx={{ textAlign: isLoading ? 'center' : '' }}>

        <>
          <Container fluid className='add-library-container'>
            <PresentationFilters
              selector
              pageTitle='Select presentation'
              advanced={true}
              categories={props.categories}
              languages={props.languages}
              themes={props.themes}
              hideStatus={true}
              isAddLibrary={true}
              filters={filters}
              onApply={(filters) => {
                setFilters(filters);
                setCurrentPage(1)
                setLoading(true)
                setSelectedPresentation(null)
              }}
              activeTabIndex={activeIndex}
              getFavourites={() => {
                setActiveIndex(activeIndex === 0 ? 1 : 0);
                setLoading(true);
              }}
            />
            {isLoading ? (
              <div className='circular-container fs t'>
                {/* <CircularProgress color='primary' /> */}
                <CompanyappLoader />
              </div>
            ) : (
              <>
                <Row className='mt-4' style={{ position: 'relative' }}>
                  {presentations.length > 0 ? (
                    <>
                      {isPaginating && (
                        <Box className='w-100 h-100' sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                          zIndex: 5,
                          background: 'rgba(255, 255, 255, 1)'
                        }}>
                          <CompanyappLoader />
                        </Box>
                      )}
                      {presentations.map((presentation) => {
                        if (props.type === 'copy') {
                          if (presentation.id === props.presentation.id) {
                            return null;
                          }
                        }

                        return (
                          <Presentation
                            isLibrary={true}
                            key={`presentation-${presentation.id}`}
                            user={props.user}
                            item={presentation}
                            selector
                            onSelected={() => {
                              onPresentationSelected(presentation);
                            }}
                            isSelected={selectedPresentation?.id === presentation.id ? true : false}
                          />
                        );


                      })}
                    </>
                  ) : (
                    <NoData activeIndex={0} />
                  )}
                </Row>
                {presentations.length > 0 && (
                  <Row className='mt-1 mb-5'>
                    <Col className='d-flex justify-content-center'>
                      <Pagination
                        owner="admin"
                        containerClasses={`creator-pagination d-flex justify-content-center align-items-center`}
                        previousDisabled={currentPage === 1 ? true : false}
                        nextDisabled={presentations.length < pageSize ? true : false}
                        currentPage={currentPage}
                        onPrevious={() => {
                          setPaging(true);
                          const current = currentPage - 1;
                          setCurrentPage(current);
                        }}
                        onNext={() => {
                          setPaging(true);
                          const current = currentPage + 1;
                          setCurrentPage(current);
                        }}
                      />
                      {/* <MuiPagination
                        className='creator-pagination'
                        count={pagination.totalPages}
                        page={pagination.currentPage}
                        siblingCount={2}
                        boundaryCount={2}
                        showFirstButton
                        showLastButton
                        color='primary'
                        onChange={onPaginate}
                      /> */}
                    </Col>
                  </Row>
                )}
                {selectedPresentation &&
                  <Box sx={{ height: '50%', width: 1, backgroundColor: 'primary.light', padding: '0 0.75rem' }}>
                    <Row className='mt-2 pt-4'>
                      {selectedPresentation && (
                        <>
                          <Col>
                            <h5>{`Select slides to ${props.type === 'copy' ? 'COPY' : 'DUPLICATE'} from: `} <Typography variant='span' color='primary' className='presentation-info'>{`${selectedPresentation.title}  (#${selectedPresentation.id})`}</Typography></h5>
                          </Col>
                          <Col xs={"auto"}>
                            <Stack direction={'row'} spacing={2} justifyContent='flex-end'>
                              <Button
                                variant='text'
                                className='library-btn slides clear'
                                onClick={() => {
                                  setSelectedSlides([]);
                                }}
                              >
                                Clear All
                              </Button>
                              <Button
                                variant='text'
                                className='library-btn slides'
                                onClick={() => {
                                  selectAllSlides();
                                }}
                              >
                                Select All
                              </Button>
                            </Stack>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row ref={slidesContainer} className='mt-4'>
                      {selectedPresentation?.slides && (
                        <>
                          {selectedPresentation?.slides?.map((slide, index) => {
                            let doesExist = false;

                            for (var i = 0; i < props.presentation.slides.length; i++) {
                              if (parseInt(props.presentation.slides[i].id) === parseInt(slide.id)) {
                                doesExist = true;
                                break;
                              }
                            }

                            let isSelected = false;
                            if (selectedSlides.includes(slide.id)) {
                              isSelected = true;
                            }
                            if (slide.published) {
                              return (
                                <SlideThumbnail
                                  isAddLibrary={true}
                                  key={`slide-${slide.id}-${index}`}
                                  user={props.user}
                                  presentation={selectedPresentation}
                                  slide={slide}
                                  selector
                                  preventDuplicate={props.type !== 'copy' ? slide.prevent_duplicate : false}
                                  onSelected={() => {
                                    onSlideToggle(slide)
                                  }}
                                  isSelected={isSelected}
                                  isDisabled={props.type === 'copy' ? doesExist : false}
                                />
                              )
                            }
                          })}
                        </>
                      )}
                    </Row>
                  </Box>
                }
              </>
            )}
          </Container>
        </>
      </DialogContent>

      <DialogActions>
      </DialogActions>
    </Dialog>
  );
};
