import { Superscript } from "../../../icons/icons";

export default class SuperscriptTool {
  static get isInline() {
    return true;
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;

    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
  }

  static get sanitize() {
    return {
      sup: {}
    };
  }

  constructor({api}) {
    this.api = api;
    this.button = null;
    this._state = false;

    this.tag = 'SUP';
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    // this.button.innerHTML = '<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="m12.55 17.94-1.09-3.62v-.12L8.88 5.71a.63.63 0 0 0-1.17 0L5.16 14.2v.12L4 17.94a.61.61 0 1 0 1.17.34l1-3.23h4.23l1 3.23a.6.6 0 0 0 .58.44h.18a.61.61 0 0 0 .39-.78Zm-6-4.11L8.29 8 10 13.83Zm11.75-4.2-.54-1.81v-.06l-1.3-4.25a.31.31 0 0 0-.58 0l-1.27 4.25a.11.11 0 0 0 0 .06L14 9.63a.31.31 0 0 0 .59.17l.48-1.62h2.12l.48 1.62a.32.32 0 0 0 .33.2h.08a.3.3 0 0 0 .22-.37Zm-3-2.06.87-2.91.88 2.91Z"/></svg>';
    this.button.innerHTML = Superscript;
    this.button.classList.add(this.api.styles.inlineToolButton);

    return this.button;
  }

  surround(range) {
    if (this.state) {
      this.unwrap(range);
      return;
    }

    this.wrap(range);
  }

  wrap(range) {
    const selectedText = range.extractContents();
    const sub = document.createElement(this.tag);


    sub.appendChild(selectedText);
    range.insertNode(sub);

    this.api.selection.expandToTag(sub);
  }

  unwrap(range) {
    const sub = this.api.selection.findParentTag(this.tag, this.class);
    const text = range.extractContents();

    sub.remove();

    range.insertNode(text);
  }


  checkState() {
    const sub = this.api.selection.findParentTag(this.tag);

    this.state = !!sub;
  }
}