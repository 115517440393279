import React, { useState } from 'react';

// Modules

// App

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function BackgroundImageSize(props) {
  const [backgroundSize, setBackgroundSize] = useState(props.slideBackgroundSize ? props.slideBackgroundSize : 'cover');

  const onBackgroundImageSize = size => {
    setBackgroundSize(size);
    props.onBackgroundImageSize(size);
  }

  return (
    <div className='background-size'>
      <Row>
        <Col>
          <input
            type={'text'}
            id={'background_size'}
            placeholder={'Background size'}
            value={backgroundSize}
            onInput={(e) => {
              onBackgroundImageSize(e.target.value);
            }}
            className='form-control'
          />
        </Col>
      </Row>
    </div>
  );
};
