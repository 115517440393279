import React, { useState, useEffect, useMemo } from 'react';

// Modules

// App
import ColorPickerNew from './presentations/colorPickerNew';

// UI components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

export default function IconSelector(props) {
  const icons = useMemo(() => props.icons ? props.icons : [], [props.icons]);
  const colors = useMemo(() => props.iconColors ? props.iconColors : [], [props.iconColors]);
  const colorsSecondary = useMemo(() => props.iconColorsSecondary ? props.iconColorsSecondary : [], [props.iconColorsSecondary]);

  const [selectedIcon, setSelectedIcon] = useState(icons[0] ? icons[0] : null);
  const [selectedColor, setSelectedColour] = useState(colors[0] ? colors[0] : '#222');

  useEffect(() => {
    icons.forEach(icon => {
      if (icon.name === props.currentBlock?.data?.name) {
        setSelectedIcon(icon);
      }
    });

    colors.forEach(color => {
      if (color === props.currentBlock?.data?.color) {
        setSelectedColour(color);
      }
    });

    colorsSecondary.forEach(color => {
      if (color === props.currentBlock?.data?.color) {
        setSelectedColour(color);
      }
    });
  }, [colors, colorsSecondary, icons, props.currentBlock]);

  const handleIconSelection = icon => {
    setSelectedIcon(icon);
  }

  const handleColorSection = color => {
    setSelectedColour(color);
  }

  return (
    <Dialog
      className='icon-selector-modal'
      open={props.showIconSelector}
      // animation={false}
      onClose={() => {
        props.setShowIconSelector(false);
        setSelectedIcon(null);
        setSelectedColour(null);
      }}>
      <DialogTitle>
        <Container>
          <Row>
            <Col xs={11} className='d-flex align-items-center'>
              <div className='d-flex'>
                Icons
              </div>
            </Col>
            <Col xs={1}>
              <Button
                className='modal-close-btn'
                onClick={() => {
                  props.setShowIconSelector(false);
                  setSelectedIcon(null);
                  setSelectedColour(null);
                }}>
                <CloseIcon />
              </Button>
            </Col>
          </Row>
        </Container>
      </DialogTitle>
      <DialogContent>
        <Container>
          <div className='icon-selector'>
            <div className='icon-color-container'>
              <h6 className='mb-3'>Icon Colour</h6>
              <ColorPickerNew
                colors={colors}
                colorsSecondary={colorsSecondary}
                slideBackgroundColor={selectedColor}
                onColorSelected={(color => {
                  handleColorSection(color);
                })} />
            </div>
            <div className='icon-container'>
              <h6 className='mb-3'>Icon</h6>
              <Row>
                {icons.map((icon, index) => {
                  return (
                    <Col key={`svg-picker-${index}`} xs={'auto'} className='pe-0'>
                      <div className={`svg-item d-flex justify-content-center align-items-center ${icon.name === selectedIcon?.name ? 'selected' : ''}`}>
                        <Button
                          className='svg-btn'
                          style={{ color: icon.name === selectedIcon?.name ? selectedColor : '#222' }}
                          onClick={() => handleIconSelection(icon)}
                        >
                          <span dangerouslySetInnerHTML={{ __html: icon.icon }} />
                        </Button>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </div>
        </Container>
      </DialogContent>
      <DialogActions>
        <Container>
          <Row>
            <Col xs={12} className='d-flex text-end'>
              <div className='save w-100'>
                <Button onClick={() => {
                  props.onIconSelected(selectedIcon, selectedColor);
                  props.setShowIconSelector(false);
                }}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </DialogActions>
    </Dialog>
  )
};