import { useEffect } from "react";
import { useLocation } from "react-router";

/**
 * @credit https://reactrouter.com/web/guides/scroll-restoration/scroll-to-top
 */
export default function ScrollToTop(props) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return null;
}
