// Dependencies
import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

// App
import { serverUrl, apiEndpoints } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import { postNode } from "../../core/postNode";
import { deleteRequest } from "../../core/delete";
import PostTitle from "../../partials/postTitle";
import PostBody from "../../partials/postBody";
import CommentForm from "../../partials/commentForm";
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import Comment from "../../partials/comment";
import SkeletonSingleScreen from "../../partials/skeleton-screens/skeletonSingleScreen";
import CommentSection from "../../partials/comments/commentSection";
import { formatTime, buildReactions } from "../../utils/helpers";
import PostImage from "../../partials/postImage";

// UI components
import SinglesHeader from "../../partials/singlesHeader";

export default function NewsSingle(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState(null);
  const [categoryName, setCategoryName] = useState(null);

  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();

  useEffect(() => {
    loadNode();
  }, []);

  useEffect(() => {
    if (post && post.nid) {
      // getComments();
    }
  }, [post]);

  const loadNode = () => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
      // getComments(nid)
    } else {
      setErrorStatus(404);
      setErrorMessage("Not found");
      setIsError(true);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = `${apiEndpoints.news}/all/${nid}?_format=json`;

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setPost(response.data.rows[0]);
          setIsError(false);
          setIsLoading(false);
        } else {
          setErrorStatus(404);
          setErrorMessage("No data");
          setIsError(true);
        }
      })
      .catch((_error) => {
        setErrorStatus(500);
        setErrorMessage("Failed to get data");
        setIsError(true);
        setIsLoading(false);
      });
  };

  // const getComments = (nid) => {
  //   if (currentPage > total_pages) {
  //     return;
  //   }
  //   let path =
  //     'api/comments/' + nid +
  //     '?items_per_page=' + pageSize +
  //     '&page=' + currentPage;

  //   getOnlineNode(path, user.access_token)
  //     .then((response) => {
  //       setIsPaginating(false);
  //       setComments(currentPage === 0 ? response.data.rows : [...comments, ...response.data.rows]);
  //       setIsCommentsLoading(false);
  //       setTotal_Pages(response.data.pager.total_pages - 1);
  //     })
  //     .catch((_error) => {
  //       // failed to get comments
  //       setIsCommentsLoading(false);
  //     });
  // };

  // const getReactions = () => {
  //   let nid = false;

  //   if (post) {
  //     nid = post.nid;
  //   } else {
  //     nid = props.route.params?.nid;
  //   }

  //   if (!nid) {
  //     return false;
  //   }

  //   if (post.field_allow_reactions === 'false') {
  //     return false;
  //   }

  //   const path = 'reactions/' + nid;

  //   getOnlineNode(path, user.access_token)
  //     .then(response => {
  //       if (response.data.rows.length > 0) {
  //         let likes = [];
  //         let love = [];
  //         let surprised = [];
  //         let hasReacted = false;
  //         let reactionType = 'none';
  //         let reactionId = 'none';

  //         response.data.rows.forEach(reaction => {
  //           if (
  //             reaction.user_id ===
  //             user.current_user.uid
  //           ) {
  //             hasReacted = true;
  //             reactionType = reaction.type;
  //             reactionId = reaction.id;
  //           }

  //           if (reaction.type === 'reaction_like') {
  //             likes.push(reaction);
  //           }

  //           if (reaction.type === 'reaction_love') {
  //             love.push(reaction);
  //           }

  //           if (reaction.type === 'reaction_surprised') {
  //             surprised.push(reaction);
  //           }
  //         });
  //         setReactions({
  //           hasReacted: hasReacted,
  //           reactionType: reactionType,
  //           reactionId: reactionId,
  //           likes: likes.length,
  //           love: love.length,
  //           surprised: surprised.length,
  //         })
  //         setIsReactionsLoading(false);
  //       } else {
  //         setReactions({
  //           hasReacted: false,
  //           likes: 0,
  //           love: 0,
  //           surprised: 0,
  //         })
  //         setIsReactionsLoading(false);
  //       }
  //     })
  //     .catch(_error => {
  //       setReactions({
  //         hasReacted: false,
  //         likes: 0,
  //         love: 0,
  //         surprised: 0,
  //       })
  //       setIsReactionsLoading(false);
  //     });
  // };

  // const addReaction = type => {
  //   const data = {
  //     _links: {
  //       type: {
  //         href: serverUrl + '/rest/type/vote/reaction_' + type,
  //       },
  //     },
  //     entity_id: [
  //       {
  //         target_id: post.nid,
  //       },
  //     ],
  //     entity_type: [
  //       {
  //         value: 'node',
  //       },
  //     ],
  //   };

  //   postNode(
  //     'entity/vote',
  //     data,
  //     user.csrf_token,
  //     user.access_token,
  //   )
  //     .then(response => {
  //       getReactions();
  //     })
  //     .catch(_error => {
  //       setIsLoading(false)
  //       // this.setState({
  //       //   isLoading: false,
  //       //   modalVisible: true,
  //       //   alertType: 'error',
  //       //   alertTitle: alertMessages.cannotReact.title,
  //       //   alertBody: alertMessages.cannotReact.message,
  //       //   alertConfirmButton: true,
  //       // });
  //     });
  // };

  // const removeReaction = eid => {
  //   deleteRequest(
  //     'entity/vote/' + eid,
  //     user.csrf_token,
  //     user.access_token,
  //   )
  //     .then(response => {
  //       getReactions();
  //     })
  //     .catch(_error => {
  //       console.log('@_error: ', _error);
  //     });
  // };

  // const loadMore = () => {
  //   setIsCommentsLoading(true);
  //   setIsPaginating(true);
  //   setCurrentPage(currentPage + 1);
  //   getComments();
  // };

  // const renderLoadMore = () => {
  //   if (currentPage < total_pages) {
  //     return (
  //       <div className="d-flex justify-content-center">
  //         <Button onClick={() => {
  //           if (!isPaginating) {
  //             loadMore();
  //           }
  //         }}>
  //           {'Load more'}
  //         </Button>
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  const renderPostImage = (post) => {
    if (post.field_featured_image) {
      return (
        <PostImage
          imgSrc={post.field_featured_image}
          nid={post.nid}
        />
      );
    }
  };

  // const renderReactions = () => {
  //   if (post.field_allow_reactions === 'false') {
  //     return false;
  //   }

  //   const hasReacted = reactions.hasReacted;
  //   const reactionType = reactions.reactionType;
  //   const reactionId = reactions.reactionId;

  //   const reactionFontSize = 'lg';

  //   /**
  //    * Logic here
  //    * Design and tweak as needed
  //    * I haven't applied anything as you may apply these styles to other elements
  //    */
  //   let likesReactionStyles = 'node_reactions_icon_container node_reactions_icon_container_like'
  //   let loveReactionStyles = 'node_reactions_icon_container node_reactions_icon_container_love'
  //   let surprisedReactionStyles = 'node_reactions_icon_container node_reactions_icon_container_surprised'

  //   /**
  //    * Reaction counter style logic
  //    */
  //   let reactionCounterLikeStyles = 'node_reactions_counter node_reactions_like_counter'
  //   let reactionCounterLoveStyles = 'node_reactions_counter node_reactions_love_counter'
  //   let reactionCounterSurprisedStyles = 'node_reactions_counter node_reactions_surprised_counter'

  //   if (hasReacted && reactionType === 'reaction_like') {
  //     reactionCounterLikeStyles += ' reacted';
  //   }

  //   if (hasReacted && reactionType === 'reaction_love') {
  //     reactionCounterLoveStyles += ' reacted';
  //   }

  //   if (hasReacted && reactionType === 'reaction_surprised') {
  //     reactionCounterSurprisedStyles += ' reacted';
  //   }

  //   return (
  //     <Row>
  //       <Col className='text-center'>
  //         <div className='node_reactions'>
  //           <Button
  //             className={hasReacted && reactionType === 'reaction_like' ? 'like reacted' : 'like'}
  //             onClick={() => {
  //               if (hasReacted) {
  //                 if (reactionType === 'reaction_like') {
  //                   removeReaction(reactionId);
  //                 }
  //               } else {
  //                 addReaction('like');
  //               }
  //             }}>
  //             <span className={likesReactionStyles}>
  //               {hasReacted && reactionType === 'reaction_like' ? (
  //                 <FontAwesomeIcon size={reactionFontSize} icon={faThumbsUpSolid} />
  //               ) : (
  //                 <FontAwesomeIcon size={reactionFontSize} icon={faThumbsUpRegular} />
  //               )}
  //               <span className={reactionCounterLikeStyles}>{reactions.likes}</span>
  //             </span>
  //           </Button>

  //           <Button
  //             className={hasReacted && reactionType === 'reaction_love' ? 'love reacted' : 'love'}
  //             onClick={() => {
  //               if (hasReacted) {
  //                 if (reactionType === 'reaction_love') {
  //                   removeReaction(reactionId);
  //                 }
  //               } else {
  //                 addReaction('love');
  //               }
  //             }}>
  //             <span className={loveReactionStyles}>
  //               {hasReacted && reactionType === 'reaction_love' ? (
  //                 <FontAwesomeIcon size={reactionFontSize} icon={faHeartSolid} />
  //               ) : (
  //                 <FontAwesomeIcon size={reactionFontSize} icon={faHeartRegular} />
  //               )}
  //               <span className={reactionCounterLoveStyles}>{reactions.love}</span>
  //             </span>
  //           </Button>

  //           <Button
  //             className={hasReacted && reactionType === 'reaction_surprised' ? 'surprised reacted' : 'surprised'}
  //             onClick={() => {
  //               if (hasReacted) {
  //                 if (reactionType === 'reaction_surprised') {
  //                   removeReaction(reactionId);
  //                 }
  //               } else {
  //                 addReaction('surprised');
  //               }
  //             }}>
  //             <span className={surprisedReactionStyles}>
  //               {hasReacted && reactionType === 'reaction_surprised' ? (
  //                 <FontAwesomeIcon size={reactionFontSize} icon={faSurpriseSurpriseSolid} />
  //               ) : (
  //                 <FontAwesomeIcon size={reactionFontSize} icon={faSurpriseSurpriseRegular} />
  //               )}
  //               <span className={reactionCounterSurprisedStyles}>{reactions.surprised}</span>
  //             </span>
  //           </Button>
  //         </div>
  //       </Col>
  //     </Row>
  //   );
  // };

  // const renderComments = () => {
  //   if (post.field_allow_comments === 'false') {
  //     return false;
  //   }

  //   return (
  //     <section className="comments">
  //       {comments.length > 0 ? (
  //         <>
  //           <div className="comments__header  d-flex align-items-center">
  //             <MessageSquare size={35} />
  //             <h3 className="mb-0">Comments</h3>
  //           </div>
  //           {user.current_user && (
  //             <CommentForm nid={post.nid} />
  //           )}
  //           <div className="comments__content">
  //             <div className="comments__header  d-flex align-items-center">
  //               <Clock size={25} />
  //               <h5 className="mb-0">Most Recent</h5>
  //             </div>
  //             {comments.map((_comment, __index) => {
  //               return (
  //                 <Comment
  //                   comment={_comment}
  //                   key={'comment-' + __index}
  //                   getComments={getComments()}
  //                 />
  //               )
  //             })}
  //           </div>

  //         </>
  //       ) : (
  //         <>
  //           <div className="comments__header  d-flex align-items-center">
  //             <MessageSquare />
  //             <h3 className="mb-0">Comments</h3>
  //           </div>
  //           <div className="comments__header  d-flex align-items-center">
  //             <Clock />
  //             <h5 className="mb-0">
  //               {user.current_user ? (
  //                 'Be the first to comment'
  //               ) : (
  //                 'Login to comment'
  //               )}
  //             </h5>
  //           </div>
  //           {user.current_user && (
  //             <CommentForm nid={post.nid} />
  //           )}
  //         </>
  //       )}
  //       {renderLoadMore()}
  //     </section>
  //   )
  // }

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return <Error status={errorStatus} message={errorMessage} />;
    } else {
      let category = "Uncategorised";

      if (categoryName) {
        category = categoryName;
      }

      if (post.category_labels) {
        category = post.category_labels;
      }

      return (
        <main className="news  single">
          <article className="article">
            <SinglesHeader
              route={"/news"}
              backBtnText={"Latest News"}
              category={category}
              timestamp={post.created}
              title={post.title}
            />
            {renderPostImage(post)}
            <div className="article__body">
              <PostBody body={post.body} />
            </div>
            <CommentSection
              nid={post.nid}
              field_allow_reactions={post.field_allow_reactions}
              field_allow_comments={post.field_allow_comments}
            />
          </article>
        </main>
      );
    }
  }
}
