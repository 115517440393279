export const createPoster = (videoElement, url, timestamp) => {
  if (parseInt(timestamp) !== 0) {
    let canvas = document.createElement('canvas');
    let video = document.createElement('video');
    video.setAttribute('src', url);
    video.setAttribute('crossOrigin', 'use-credentials');
    video.currentTime = timestamp;

    video.addEventListener('seeked', function () {
      canvas.width = 1366;
      canvas.height = 768;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      videoElement.setAttribute('poster', canvas.toDataURL('image/jpeg'));
    });
  }
}