import React, { useState } from 'react';

// Modules
import moment from 'moment';

// App
import { patchNode } from '../../core/postNode';
import CategorySelector from '../categorySelector';
import BackButton from './backButton';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Checkbox, CircularProgress, Alert, Box, Button, Stack, Typography } from '@mui/material/';
import { Done } from '@mui/icons-material';


export default function SelectedAudio(props) {
  const [isError, setError] = useState(null);
  const [isPatching, setPatching] = useState(false);

  // core
  const [coreLibrary, setCoreLibrary] = useState(props.selectedMedia.core_library === '1' ? true : false);

  // category
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(props.selectedMedia ? { value: props.selectedMedia?.category?.tid ? props.selectedMedia?.category?.tid : 0 } : { value: 0 });

  const handleCoreLibrary = (checked) => {
    setPatching(true);
    setCoreLibrary(checked ? true : false);
    syncData(selectedCategoryItem.value, checked);
  };

  const syncData = (category, core_library) => {
    const data = {
      mid: props.selectedMedia.mid,
      category: category,
      core_library: core_library,
    };

    patchNode(
      `media_library/${props.selectedMedia.mid}`, data, props.user.csrf_token)
      .then(_response => {
        setPatching(false);
        // props.onTimestampUpdate();
      })
      .catch(error => {
        setPatching(false);
      });
  }

  const handleCategorySelection = (tid) => {
    let catItem = props.categories.filter(item => {
      return item.value === tid
    });

    setSelectedCategoryItem(
      {
        label: catItem[0].label,
        value: catItem[0].value
      }
    );

    setPatching(true);
    setSelectedCategory(parseInt(catItem[0].value, 10));
    syncData(catItem[0].value, coreLibrary);
  };

  return (
    <>

      <Row className='mt-3 mb-3 justify-content-center'>
        <Col>
          <div className={`crop-actions ${props.isNotForInsert ? 'singular' : ''}`}>
            <BackButton onCancel={props.onCancel} />
            <>
              {!props.isNotForInsert && (
                <Stack direction='row' spacing={2} textAlign='center'>
                  <Button variant='contained' color='secondary' endIcon={<Done />} onClick={() => {
                    props.onMediaInsert(props.selectedMedia);
                  }} >Select</Button>
                </Stack>
              )}
            </>
          </div>
        </Col>
      </Row>
      {isError ? (
        <Row>
          <Col>
            <Alert
              variant='filled'
              severity='error'
              onClose={() => {
                setError(null);
              }}
            >
              <p>{isError}</p>
            </Alert>
          </Col>
        </Row>
      ) : (
        <Row className='selected-audio'>
          <Col sm={12} md={6} className='controls-container'>
            <div className='text-center'>
              <audio controls
                src={props.selectedMedia.field_media_audio}
                onError={() => {
                  setError(`Failed to load audio.`);
                }}>
                <source type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          </Col>

          <Col sm={12} lg={6} className={'mb-3 info-container'}>
            <div className='wrapper'>
              {isPatching && (
                <Box className='info-loader'>
                  <CircularProgress size={20} />
                </Box>
              )}
              <div className='form-group'>
                <Row className='mb-2 align-items-center info-row'>
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin">
                      File name
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="regular"
                      color="adminText.main"
                    >
                      {props.selectedMedia.name}
                    </Typography>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center info-row'>
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin">
                      MID
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="regular"
                      color="adminText.main"
                    >
                      {props.selectedMedia.mid}
                    </Typography>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center info-row'>
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin">
                      Created
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="regular"
                      color="adminText.main"
                    >
                      {moment.unix(props.selectedMedia.created).format("h:mm:ssa - DD/MM/YYYY")}
                    </Typography>
                  </Col>
                </Row>
                <Row className="mb-2 align-items-center info-row">
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin">
                      Author
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="regular"
                      color="adminText.main"
                      break='break-all'
                    >
                      {props.selectedMedia.username}
                    </Typography>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center info-row'>
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin">
                      Category
                    </Typography>
                  </Col>
                  <Col>
                    <CategorySelector
                      className='info-category-select'
                      size='small'
                      name='mediaCategory'
                      variant='outlined'
                      categories={props.categories}
                      selectedCategory={selectedCategoryItem}
                      handleCategorySelection={(tid) => {
                        handleCategorySelection(tid);
                      }}
                    />
                  </Col>
                </Row>
                {props.permissions.access_media_promote_to_library && (
                  <Row className='mb-2 align-items-center info-row'>
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        Corporate library
                      </Typography>
                    </Col>
                    <Col>
                      <Checkbox
                        sx={{
                          marginLeft: '-13px'
                        }}
                        size='small'
                        id='core-lib-checkbox'
                        checked={coreLibrary}
                        onChange={(event) => {
                          handleCoreLibrary(event.target.checked ? true : false);
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
      )}

    </>
  );
};