import React, { useState, useRef, useEffect } from 'react';

// Modules
import { debounce } from 'lodash';

// App
import MediaLibrary from '../mediaLibrary';

// UI components
import { Container, Row, Col } from 'react-bootstrap';
import { Button, FormControlLabel, Checkbox, Typography, Slider, FormGroup } from '@mui/material';

export default function AddAudio(props) {
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [volume, setVolume] = useState(props.audioData?.volumeValue !== "" && props.audioData?.volumeValue !== null && typeof props.audioData?.volumeValue !== "undefined" ? props.audioData?.volumeValue : 1.0);
  const audioRef = useRef(null);
  // const [audio, setAudio] = useState(props.audioData);

  const handleVolumeChange = debounce((value) => {
    props.onAudioChange({
      ...props?.audioData,
      volumeValue: value
    })
  }, 500);


  useEffect(() => {
    if (props?.audioData?.customVolume) {
      if (audioRef.current) {
        console.log('volume state', { type: typeof volume, value: volume })
        if (volume !== "" && volume !== null && typeof volume !== "undefined") {
          audioRef.current.volume = volume
          handleVolumeChange(volume);
        }
      }
    }
  }, [volume, props?.audioData?.customVolume]);

  return (
    <>
      <MediaLibrary
        user={props.user}
        mediaType='audio'
        setShowMediaLibrary={(condition => {
          setShowMediaLibrary(condition);
        })}
        showMediaLibrary={showMediaLibrary}
        onMediaInsert={(media) => {
          props.onAudioChange({
            ...media,
            autoPlay: false,
            loop: false
          })
        }}
        selectedMid={props.audioData?.mid}
      />

      {props.audioData ?
        <Container className={`${props.presentationSetting ? 'adv-presentation-settings-sub-container' : 'adv-slide-settings-sub-container'}  ${props?.audioData.src ? 'audio' : ''} px-0`}>
          <Row className='mb-4'>
            <Col xs={6} className='d-flex flex-column pe-4'>
              <Row>
                <Col xs={12}>
                  <audio ref={audioRef} controls src={props?.audioData.src} className='w-100' />
                </Col>
              </Row>
              <Row className='add-audio-row'>
                <Col xs={12}>
                  <FormGroup className='form-group mb-0'>
                    <FormControlLabel
                      label='Autoplay'
                      control={
                        <Checkbox
                          size='small'
                          checked={props?.audioData.autoPlay}
                          onChange={(event) => {
                            props.onAudioChange({
                              ...props?.audioData,
                              autoPlay: event.target.checked,
                            })
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='add-audio-row'>
                <FormGroup className='form-group mb-0'>
                  <FormControlLabel
                    label='Loop audio'
                    control={
                      <Checkbox
                        size='small'
                        checked={props?.audioData?.loop ? props?.audioData?.loop : false}
                        onChange={(event) => {
                          props.onAudioChange({
                            ...props?.audioData,
                            loop: event.target.checked,
                          })
                        }}
                      />
                    }
                  />
                </FormGroup>
              </Row>
              <Row className='d-flex align-items-center add-audio-row'>
                <Col xs={'auto'}>
                  <FormGroup className='form-group mb-0'>
                    <FormControlLabel
                      label='Volume'
                      control={
                        <Checkbox
                          size='small'
                          checked={props?.audioData?.customVolume ? props?.audioData?.customVolume : false}
                          onChange={(event) => {
                            props.onAudioChange({
                              ...props?.audioData,
                              customVolume: event.target.checked,
                            })
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className='d-flex align-items-center pe-3'>
                  <Slider
                    disabled={!props?.audioData?.customVolume}
                    marks
                    step={0.1}
                    defaultValue={1.0}
                    min={0}
                    max={1.0}
                    value={volume}
                    valueLabelDisplay='auto'
                    valueLabelFormat={value => <div>{value * 100}%</div>}
                    onChange={(e, value) => { setVolume(value); }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={6} className='d-flex flex-column ps-4'>
              <Row className='add-audio-row'>
                <Col xs={3} className='pe-0'>
                  <Typography
                    variant='form-group-label'
                    owner='admin'
                  >
                    File Name:
                  </Typography>
                </Col>
                <Col>
                  <Typography
                    variant='form-group-label'
                    owner='admin'
                    color='adminText.dark'
                    weight='light'
                  >
                    {props?.audioData?.name}
                  </Typography>
                </Col>
              </Row>
              <Row className='add-audio-row'>
                <Col xs={3} className='pe-0'>
                  <Typography
                    variant='form-group-label'
                    owner='admin'
                  >
                    MID:
                  </Typography>
                </Col>
                <Col>
                  <Typography
                    variant='form-group-label'
                    owner='admin'
                    color='adminText.dark'
                    weight='light'
                  >
                    {props?.audioData?.mid}
                  </Typography>
                </Col>
              </Row>
              <Row className='add-audio-row'>
                <Col xs={3} className='pe-0'>
                  <Typography
                    variant='form-group-label'
                    owner='admin'
                  >
                    File size:
                  </Typography>
                </Col>
                <Col>
                  <Typography
                    variant='form-group-label'
                    owner='admin'
                    color='adminText.dark'
                    weight='light'
                  >
                    45mb
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='btn-grp justify-content-between'>
            <Col xs={'auto'}>
              <Button
                className='change-btn'
                variant='contained'
                color='primary'
                onClick={() => {
                  setShowMediaLibrary(true)
                }}
              >
                Change audio
              </Button>
            </Col>
            <Col xs={'auto'}>
              <Button
                className='remove-btn'
                variant='contained'
                color='error'
                onClick={() => {
                  props.onAudioChange(null)
                }}
              >
                Remove audio
              </Button>
            </Col>
          </Row>
        </Container> :
        <Container className='adv-slide-settings-sub-container audio-modal'>
          <Row className='hint'>
            <Typography
              variant='body2'
              owner='admin'
            >
              To attach a sound to the {props.slideSetting ? 'slide' : 'presentation'}, please select or upload an audio file via the media library
            </Typography>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col xs={'auto'}>
              <Button
                className='media-lib-btn'
                variant='contained'
                color='primary'
                onClick={() => {
                  setShowMediaLibrary(true)
                  // setSlideAudio(true)
                }}
              >
                <Typography
                  variant='button'
                  owner='admin'
                  className='lh'
                >
                  Media library
                </Typography>
              </Button>
            </Col>
          </Row>
        </Container>
      }
    </>
  )
}