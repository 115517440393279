import { FormControl, InputLabel, Select, Input, MenuItem } from "@mui/material";
import ClientTheme from "../core/clientTheme";
import { decodeHtmlEntities } from "../utils/helpers";

export default function ScreenCategories(props) {
  const clientTheme = { ...ClientTheme() };

  return (
    <FormControl variant="standard" fullWidth size="small" >
      <InputLabel sx={{ fontSize: 14 }} color="clientCategory" >{props.title}</InputLabel>
      <Select
        labelId={`${props.categoryName}-category-select-label`}
        id={`${props.categoryName}-category-select`}
        value={props.selectedCategoryItem}
        label={props.title}
        sx={{
          "& .MuiSelect-select": {
            fontSize: clientTheme.font.sizes.button,
            fontWeight: 500,
            lineHeight: "0.8rem",
            paddingTop: "5px",
          }
        }}
        open={props.categoryOpen}
        onClose={() => props.setCategoryOpen(false)}
        onOpen={() => props.setCategoryOpen(true)}
        input={
          <Input
            sx={{
              fontSize: 14,
              "&.MuiInput-underline:before": {
                borderBottomColor: "#d2d0cf",
              },
            }}
            label={props.title}
          />
        }
      >
        {props.categories.map((category, index) => {
          return (
            <MenuItem
              variant="singleHeader"
              key={`news-category-${category.value}-${index}`}
              override={`${clientTheme.components.menuItem.override}`}
              value={category.value}
              onClick={() => {
                props.handleCategorySelection(category.value);
              }}
            >
              {decodeHtmlEntities(category.label)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  )
}