import React from "react";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function NoData(props) {
  const message1 = props.message1;
  const message2 = props.message2;

  return (
    <Row className="no-data-row">
      <Col className="d-flex text-center">
        {props.activeIndex === 0 && (
          <p>
            {message1 ? message1 : `There is nothing to display.`}
          </p>
        )}

        {props.activeIndex === 1 && (
          <p>
            {message2 ? message2 : `There is nothing to display. You haven't chosen any favourites yet.`}
          </p>
        )}
      </Col>
    </Row>
  )
}
