import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverUrl } from "../../config";
import { useWindowSize } from "../../partials/windowSize";

import GridItem from "../../partials/gridItem";

export default function NewsItem(props) {
  const [colSize, setColSize] = useState({ xs: 12, sm: 6, md: 3, });
  const [variant, setVariant] = useState(props.variant);
  const navigate = useNavigate();
  const { width } = useWindowSize();


  useEffect(() => {
    if (width < 768) {
      setVariant("");
      setColSize({ xs: 12, sm: 6, md: 3, });
    }
    else {
      handleColSize();
    }
  }, [width]);


  const handleColSize = () => {
    if (props.index === 0) {
      setVariant("sticky");
      setColSize({ xs: 12, sm: 12, md: 12, });
    }
    // if (props.item?.sticky === "True" || props.item?.sticky === "true") {
    //   variant = "sticky";
    //   colSize = {
    //     xs: 12,
    //     sm: 12,
    //     md: 12,
    //   };
    // } 
    else if (props.index === 7) {
      setVariant("featured");
      setColSize({ xs: 12, sm: 6, md: 6, });
    }
  };

  return (
    <GridItem
      xs={colSize.xs}
      sm={colSize.sm}
      md={colSize.md}
      itemClass="news"
      nid={props.item?.nid}
      title={props.item?.title}
      field_body={props.item?.summary ? props.item?.summary : props.item?.body}
      category_label={props.item.category_labels}
      created={props.item?.created}
      hasImg={true}
      imgSrc={`${serverUrl}${props.item.field_featured_image}`}
      newsVariant={variant}
      cardAction={true}
      cardActionCallBack={() => navigate(`/news/${props.item?.nid}`)}
      contentType={props.contentType}
    >
    </GridItem>
  );
}
