// Dependencies
import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

// App
import { serverUrl, apiEndpoints, client } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import Error from "../../partials/error";
import SkeletonSingleScreen from "../../partials/skeleton-screens/skeletonSingleScreen";
import PostImage from "../../partials/postImage";

// UI components
import { CircularProgress } from "@mui/material";
import SinglesHeader from "../../partials/singlesHeader";

export default function FormSingle(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [scrollHeight, setScrollHeight] = useState(null);

  const [post, setPost] = useState(null);

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();

  useEffect(() => {
    loadNode();
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "setHeight") {
        if (event.data.scrollHeight) {
          setScrollHeight(event.data.scrollHeight);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const loadNode = () => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
    } else {
      setErrorStatus(404);
      setErrorMessage("Not found");
      setIsError(true);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = `${apiEndpoints.forms}/all/${nid}?_format=json`;

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setPost(response.data.rows[0]);
          setIsError(false);
          setIsLoading(false);
        } else {
          setErrorStatus(404);
          setErrorMessage("No data");
          setIsError(true);
          setIsLoading(false);
        }
      })
      .catch((_error) => {
        setErrorStatus(500);
        setErrorMessage("Failed to get data");
        setIsError(true);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return <Error status={errorStatus} message={errorMessage} />;
    } else {
      let category = "Uncategorised";

      if (post.category_labels) {
        category = post.category_labels;
      }

      return (
        <main className="forms single">
          <article className="article">
            <SinglesHeader
              route={"/forms"}
              backBtnText={"Surveys & Forms"}
              category={category}
              timestamp={post.created}
              hideBack={client === "equans" ? true : false}
            />
            <div className="article__body">
              {iframeLoading && (
                <div className="w-100 h-100 text-center">
                  <CircularProgress />
                </div>
              )}
              <div className="ratio ratio-4x3">
                <iframe
                  className={`${iframeLoading && "invisible"}`}
                  src={`${serverUrl}/node/${post.nid}?app=true`}
                  title={post.title}
                  frameBorder="0"
                  style={{
                    height: scrollHeight ? scrollHeight + 50 : "",
                  }}
                  onLoad={(iframe) => {
                    setIframeLoading(false);
                  }}
                />
              </div>
            </div>
          </article>
        </main>
      );
    }
  }
}
