import React, { useState, useEffect, createRef, useMemo } from 'react';

// Modules

// App

// UI components
// UI components
import {Row, Col, Pagination } from 'react-bootstrap';
import { Button, Tooltip } from '@mui/material';

export default function LayoutPicker(props) {
  const [layoutFilter, setLayoutFilter] = useState(props.slideLayout ? props.slideLayout.category : 'all');
  const [slideLayout, setSlideLayout] = useState(props.slideLayout ? props.slideLayout : {
    "name": "Blank",
    "id": "blank",
    "category": "Custom",
    "colors": [
      "#000000",
      "#ffffff",
      "#4286f4",
      "#ea4435",
      "#fbbc04",
      "#34a853",
      "#ff6d01",
      "#46bdc6"
    ],
    "alignment": [
      "left",
      "center",
      "right"
    ]
  });
  const [slideContentPosition, setSlideContentPosition] = useState(props.slideContentPosition ? props.slideContentPosition : 'left');

  const layouts = useMemo(() => props.layouts ? props.layouts : [''], [props.layouts]);
  const [catRefs, setCatRefs] = useState([]);
  const showContentPosition = slideLayout.id === 'one-column-full-height-image' || slideLayout.id === 'one-row-image' ? true : false;
  // const [layoutRefs, setLayoutRefs] = useState([]);
  // const innerWrapperRef = React.useRef();

  useEffect(() => {
    setCatRefs((catRefs) =>
      Array(Object.keys(layouts).length + 1)
        .fill()
        .map((_, i) => catRefs[i] || createRef()),
    );
  }, [layouts]);

  useEffect(() => {
    if (props.slideLayout) {
      // eslint-disable-next-line array-callback-return
      Object.entries(layouts).map(item => {
        const category = item[0];
        const itemLayouts = item[1];

        if (itemLayouts.length > 0) {
          itemLayouts?.forEach(element => {
            if (element.id === slideLayout.id) {
              setLayoutFilter(category);
            }
          });
        } else {
          setLayoutFilter('all');
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLayoutSelected = layout => {
    setSlideLayout(layout);
    props.onLayoutSelected(layout);
  }

  const onContentPositionSelected = position => {
    setSlideContentPosition(position);
    props.onContentPositionSelected(position);
  }

  const renderFilters = () => {
    let items = [];
    items.push(
      <Pagination.Item ref={catRefs[0]} key={`flt-all`} active={'all' === layoutFilter} onClick={() => {
        setLayoutFilter('all');
        catRefs[0].current.scrollIntoView({behavior: 'smooth', inline: 'center'});
      }}>
        {'All'}
      </Pagination.Item>,
    )

    // eslint-disable-next-line array-callback-return
    Object.entries(layouts).map((item, index) => {
      items.push(
        <Pagination.Item ref={catRefs[index]} key={`flt-${index}`} active={item[0] === layoutFilter} onClick={() => {
          setLayoutFilter(item[0]);
          catRefs[index].current.scrollIntoView({behavior: 'smooth', inline: 'center'});
        }}>
          {item[0]}
        </Pagination.Item>,
      );
    });

    return (
      <Pagination>{items}</Pagination>
    );
  }

  const renderLayouts = () => {
    let data = [];

    if (Object.keys(layouts).length > 0) {
      for(var key in layouts) {
        const itemKey = key;
        const group = layouts[itemKey];

        if (group.length > 0) {
          group.forEach((layout, index) => {
            const isSelected = layout.id === slideLayout.id ? true : false;
            const layoutGroup = itemKey;
            let buttonClass = isSelected ? 'selected' : '';
    
            data.push(
              <div
                key={`layout-col-${itemKey}-${index}`}
                className={`layout-wrap ${buttonClass} ${layoutGroup} ${layoutFilter !== 'all' && layoutFilter !== layoutGroup ? 'd-none' : ''}`}>
                {props.showTooltip ? (
                  // <OverlayTrigger
                  //   overlay={
                  //     <Tooltip id={`tooltip-${itemKey}-${index}`}>
                  //       {layout.name}
                  //     </Tooltip>
                  //   }
                  // >
                  <Tooltip id={`tooltip-${itemKey}-${index}`} title={layout.name} placement="top">
                    <Button onClick={() => {
                      onLayoutSelected(layout);
                    }}>
                      <span>{layout.name}</span>
                    </Button>
                  </Tooltip>
                  // </OverlayTrigger>
                ) : (
                  <Button onClick={() => {
                    onLayoutSelected(layout);
                  }}>
                    <span>{layout.name}</span>
                  </Button>
                )}
              </div>
            )
          });
        }
      }
    }

    return data;
  }

  const renderContentPosition = () => {
    let data = [];

    if(showContentPosition) {
      // eslint-disable-next-line array-callback-return
      slideLayout?.contentPosition?.map((position, index) => {
        const isSelected = slideContentPosition === position ? true : false;
        let buttonClass = isSelected ? 'selected' : '';

        let label = position;

        if (slideLayout.id === 'one-row-image') {
          if (position === 'left') {
            label = 'top';
          }

          if (position === 'right') {
            label = 'bottom';
          }
        }

        data.push(
          <div
            key={`position-col-${index}`}
            className={`position-wrap ${buttonClass}`}>
            {props.showTooltip ? (
              // <OverlayTrigger
              //   overlay={
              //     <Tooltip id={`tooltip-${index}`}>
              //       {label}
              //     </Tooltip>
              //   }
              // >
              <Tooltip id={`tooltip-${index}`} title={label} placement="top">
                <Button onClick={() => {
                  onContentPositionSelected(position);
                }}>
                  <span>{label}</span>
                </Button>
              </Tooltip>
              // </OverlayTrigger>
            ) : (
              <Button onClick={() => {
                onContentPositionSelected(position);
              }}>
                <span>{label}</span>
              </Button>
            )}
          </div>
        )
      });

      return data;
    } 
  }

  return (
    <div className='settings-wrap layouts'>
      <h6>{props.title}</h6>
      <Row>
        <Col>
          <div className='main-wrapper'>
            <Row>
              <Col>
                <h6>{props.categoryTitle}</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='layout-ct'>
                  {renderFilters()}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>{props.layoutTitle}</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='inner-wrapper'>
                  {renderLayouts()}
                </div>
              </Col>
            </Row>
            {showContentPosition && (
              <>
                <Row>
                  <Col>
                    <h6>Content Position</h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='inner-wrapper'>
                      {renderContentPosition()}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
