import React from "react";

import { serverUrl } from "../../config";
import { useNavigate } from "react-router";

import GridItem from "../../partials/gridItem";

export default function EventItem(props) {

  const navigate = useNavigate();
  const formatDate = (strDate) => {

    if (strDate !== "") {
      const date = new Date(parseInt(strDate.replace('\n', '').trim()) * 1000);
      const day = date.toLocaleString("en-GB", { day: "2-digit", timeZone: "Europe/London"});
      const month = date.toLocaleString("en-GB", { month: "short", timeZone: "Europe/London" });
      const weekday = date.toLocaleString("en-GB", { weekday: "short", timeZone: "Europe/London" });
      return {
        weekday: weekday.toUpperCase(),
        date: {
          day: day,
          month: month.toUpperCase()
        }
      }
    }
    else return {
      weekday: "",
      date: {
        day: "",
        month: ""
      }
    }
  }

  let startDate = formatDate(props.item.field_start_date);
  let endDate = formatDate(props.item.field_end_date);

  return (
    <GridItem
      xs={12} md={6}
      itemClass="event"
      cardAction={true}
      cardActionCallBack={() => navigate(`/events/${props.item?.nid}`)}
      route="events"
      nid={props.item.nid}
      title={props.item.title}
      category_label={props.item.category_labels}
      hasImg={true}
      imgSrc={`${serverUrl}/${props.item.field_featured_image}`}
      event={true}
      eventStartDate={startDate}
      eventEndDate={endDate}
      contentType={props.contentType}
    />
  )
}