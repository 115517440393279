
// Modules
import { useSelector } from "react-redux";

// App
import Creator from "../../partials/creator";

// UI
import { Row } from "react-bootstrap";


export default function Training(props) {
  const content = useSelector((state) => state).contentReducer;

  return (
    <>
      <div className="training screen d-flex flex-column justify-content-between h-100 mx-lg-auto">
        <Row className="gx-0">
          <div className='dynamic-content training' dangerouslySetInnerHTML={{ __html: content.training ? content.training : '' }} />
        </Row>
        <Creator />
      </div>
    </>
  );
}