import React, { useState } from "react";

// Modules
// App

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from '@mui/material';


export default function Transition(props) {
  const transitions = [
    'none',
    'fade',
    'slide',
    'convex',
    'concave',
    'zoom'
  ];

  const [selectedTransition, setTransition] = useState(props.selectedTransition ? props.selectedTransition : transitions[2]);

  const onSetTransition = transition => {
    setTransition(transition);
    props.onTransitionSelected(transition)
  }

  return (
    <div className="settings-wrap transitions">
      <h6 className="ss-subtitle">{props.title}</h6>
      <Row>
        {transitions.map((transition, index) => {
          return (
            <Col key={`transition-${index}`} className={`transition py-2 transition-${transition} ${transition === selectedTransition ? 'selected' : ''}`}>
              <Button
                className="btn"
                sx={{height: '40px', width: '60px', perspective: '400px'}}
                onClick={() => {
                  onSetTransition(transition)
                }}
                >
                <div className="t-slide ts-1"><span>1</span></div>
                <div className="t-slide ts-2"><span>2</span></div>
              </Button>
              <span>{transition}</span>
            </Col>
          );
        })}
      </Row>
    </div>
    
  );
};
