/**
 * Build styles
 */
require('./index.css').toString();
const { make } = require('./util');

export default class FontColorTool {
    /**
     * Default colour
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_COLOR() {
        return '';
    }

    static get isTune() {
        return true;
    }

    getColor() {
        if (!!this.settings?.blocks && this.settings.blocks.hasOwnProperty(this.block.name)) {
            return this.settings.blocks[this.block.name]
        }
        if (!!this.settings?.default) {
            return this.settings.default
        }
        return FontColorTool.DEFAULT_COLOR
    }

    constructor({ api, data, config, block }) {
        this.api = api;
        this.block = block;
        this.settings = config;
        this.data = data || { color: this.getColor() };
        this.colors = config.options;
        this._CSS = {
            wrapper: 'cdx-color-picker-settings',
            button: 'cdx-color-picker-button',
            span: 'cdx-color-picker',
            tuneWrapper: 'editor-block-tune',
            tuneTitle: 'editor-block-tune-title',
            tuneText: 'editor-block-tune-text',
            tuneInput: 'editor-block-tune-input',
            tuneContent: 'editor-block-tune-content-container'
        };
    }

    wrap(blockContent) {
        this.wrapper = make("div", "cdx-font-color-tune-wrapper");
        this.wrapper.append(blockContent);

        const target = this.wrapper.querySelector(".cdx-block") || this.wrapper.querySelector("[data-placeholder]");
        if (this.data.color !== "") {
            const secondaryTarget = this.wrapper.querySelector("blockquote");
            const listMainTarget = this.wrapper.querySelector('.cdx-nested-list')
            const pointTargets = this.wrapper.querySelectorAll('.cdx-point-input')
            if (secondaryTarget) {
                secondaryTarget.classList.add("quote-custom")
                secondaryTarget.style.color = this.data.color
            }
            if (listMainTarget) {
                listMainTarget.classList.add('list-custom')
            }
            if (pointTargets.length > 0) {
                pointTargets.forEach((e) => {
                    e.style.color = this.data.color;
                })
            }
        }

        target.style.color = this.data.color;
        return this.wrapper;
    }

    render() {
        const wrapper = make("div", [this._CSS.wrapper, this._CSS.tuneWrapper]);
        const label = make("label",[this._CSS.tuneTitle]);
        const content = make("div",[this._CSS.tuneContent]);

        label.innerText = 'Text colour:'
        this.colors.map(color => {
            const button = document.createElement('button');
            const span = document.createElement('span');

            button.classList.add(this._CSS.button)
            //  button.style.backgroundColor = color
            button.type = 'button';
            span.classList.add(this._CSS.span);

            span.style.boxShadow = `${color} 0px 0px 0px 15px inset`;
            button.classList.toggle('colorCircleActive', color === this.data.color);
            button.appendChild(span);
            content.appendChild(button);
            return button
        }).forEach((element, index, elements) => {
            element.addEventListener('click', () => {
                this.data = {
                    color: this.colors[index]
                }
                elements.forEach((el, i) => {
                    el.classList.toggle('colorCircleActive', this.colors[i] === this.data.color);
                    const target = this.wrapper.querySelector(".cdx-block") || this.wrapper.querySelector("[data-placeholder]");
                    const secondaryTarget = this.wrapper.querySelector("blockquote");
                    const listMainTarget = this.wrapper.querySelector('.cdx-nested-list')
                    const pointTargets = this.wrapper.querySelectorAll('.cdx-point-input')
                    if (secondaryTarget) {
                        secondaryTarget.classList.add("quote-custom")
                        secondaryTarget.style.color = this.data.color
                    }
                    if (listMainTarget) {
                        listMainTarget.classList.add('list-custom')
                    }
                    if (pointTargets.length > 0) {
                        pointTargets.forEach((e) => {
                            e.style.color = this.data.color;
                        })
                    }
                    target.style.color = this.data.color;
                });
            });
        });
        wrapper.append(label);
        wrapper.append(content);

        return wrapper;
    }

    save() {
        return this.data;
    }
}
