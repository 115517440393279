import { DeleteIcon, ImageIcon, AccordionArrow, PillarIcon } from '../../icons/icons';
import { decodeHtmlEntities } from '../../../utils/helpers';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

export default class Pillar {

  static get toolbox() {
    return {
      icon: PillarIcon,
      title: 'Pillar',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get DEFAULT_HEADING_PLACEHOLDER() {
    return 'Column heading'
  }

  static get DEFAULT_SUBHEADING_PLACEHOLDER() {
    return 'Column sub-heading'
  }

  static get DEFAULT_BODY_PLACEHOLDER() {
    return 'Column body'
  }

  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      br: true,
      strong: true,
      a: true,
      i: true,
      sub: true,
      sup: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-media-container',
      wrapper: 'cdx-media-wrapper',
      btn: 'cdx-media-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
      /**
       * Text section classes
       */
      textContainer: 'cdx-text-container',
      textRedactor: 'cdx-text-redactor',
      headerContainer: 'cdx-header-container',
      headerRedactor: 'cdx-header-redactor',
      headingMain: 'cdx-heading-container',
      heading: 'cdx-heading-input',
      subHeadingMain: 'cdx-sub-heading-container',
      subHeading: 'cdx-sub-heading-input',
      bodyMain: 'cdx-body-container',
      bodyRedactor: 'cdx-body-redactor',
      body: 'cdx-body-input',
      chevron: 'cdx-accord-toggle',
      modeToggle: 'cdx-mode-toggle',
      colorToggle: 'cdx-color-toggle',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
      heading: data.heading || '',
      subHeading: data.subHeading || '',
      body: data.body || '',
    };

    this._headingPlaceholder = config.headingPlaceholder ? config.headingPlaceholder : Pillar.DEFAULT_HEADING_PLACEHOLDER;
    this._subPlaceholder = config.subPlaceholder ? config.subPlaceholder : Pillar.DEFAULT_SUBHEADING_PLACEHOLDER;
    this._bodyPlaceholder = config.bodyPlaceholder ? config.bodyPlaceholder : Pillar.DEFAULT_BODY_PLACEHOLDER;

    this._element = this.getElement();
    this._heading = this.getHeadingElement();
    this._subHeading = this.getSubHeadingElement();
    this._body = this.getBodyElement();
    this._accordion = null;
    this._modeContainer = null;
    if (!this.readOnly) {
      this._onKeyUp = this.onKeyUp.bind(this);
      this._onKeyDown = this.onKeyDown.bind(this);
    }
  }



  getElement() {
    let img;

    if (this._data.url) {
      img = document.createElement('img');
      img.classList.add(this._CSS.wrapper);
      img.setAttribute('src', decodeHtmlEntities(this._data.url));
      img.setAttribute('data-mid', this._data.mid);
      img.setAttribute('data-x', this._data.x);
      img.setAttribute('data-y', this._data.y);
      img.setAttribute('data-width', this._data.width);
      img.setAttribute('data-height', this._data.height);
      img.setAttribute('data-maxwidth', this._data.maxWidth);
      img.setAttribute('data-maxheight', this._data.maxHeight);
    }

    return img;
  }

  getHeadingElement() {
    let tag = document.createElement('h4');

    tag.dataset.placeholder = this.api.i18n.t(this._headingPlaceholder);
    tag.innerHTML = this._data.heading || '';
    tag.classList.add(this._CSS.heading);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._heading);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getSubHeadingElement() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._subPlaceholder);
    tag.innerHTML = this._data.subHeading || '';
    tag.classList.add(this._CSS.subHeading);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._subHeading);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getBodyElement() {
    let tag = document.createElement('p');

    tag.dataset.placeholder = this.api.i18n.t(this._bodyPlaceholder);
    tag.innerHTML = this._data.body || '';
    tag.classList.add(this._CSS.body);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._body);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  toggleAccordion() {
    if (this._accordion.classList.contains('active')) {
      this._accordion.classList.remove('active');
    } else {
      this._accordion.classList.add('active');
    }
  }




  onKeyUp(e, element) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = element;

    if (textContent === '') {
      element = '';
    }
  }

  onKeyDown(e, element) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.addLineBreak(element);
    }
  }

  addLineBreak(element) {
    const selection = window.getSelection(); // get the current selection.
    const range = selection.getRangeAt(0); // get the current range of the selection.
    const brTags = element.querySelectorAll('br');

    // we need to create 2x tags initially, 1x tag will not do anything
    // this is the default browser behavior with Enter + Shift as well
    if (brTags.length === 0) {
      const br1 = document.createElement('br');
      const br2 = document.createElement('br');
      range.insertNode(br1); // inserts the <br> element at the current cursor position.
      range.insertNode(br2); // inserts the <br> element at the current cursor position.
      range.setStartAfter(br2); // set the start of the range to the position after the <br> element.
    } else {
      const br = document.createElement('br');
      range.insertNode(br);
      range.setStartAfter(br); // set the start of the range to the position after the <br> element.
    }

    range.collapse(true); // collapse the range to the position after the <br> element.
    selection.removeAllRanges(); // remove any existing ranges from the selection.
    selection.addRange(range); // add the modified range to the selection.
  }

  render() {
    let container = document.createElement('div');
    container.classList.add(this._CSS.container);
    let img = this._element;
    let accordToggle = document.createElement('button');

    let textContainer = document.createElement('div');
    let textRedactor = document.createElement('div');
    let headerContainer = document.createElement('div');
    let headerRedactor = document.createElement('div');
    let headingContainer = document.createElement('div');
    let subHeadingContainer = document.createElement('div');
    let bodyContainer = document.createElement('div');
    let bodyRedactor = document.createElement('div');

    textContainer.classList.add(this._CSS.textContainer);
    textContainer.classList.add("active");
    textRedactor.classList.add(this._CSS.textRedactor);
    headerContainer.classList.add(this._CSS.headerContainer);
    headerRedactor.classList.add(this._CSS.headerRedactor);
    headingContainer.classList.add(this._CSS.headingMain);
    subHeadingContainer.classList.add(this._CSS.subHeadingMain);
    bodyContainer.classList.add(this._CSS.bodyMain);
    bodyRedactor.classList.add(this._CSS.bodyRedactor);
    accordToggle.classList.add(this._CSS.chevron);

    accordToggle.innerHTML = `${AccordionArrow}`

    if (!this.readOnly) {
      let btn = document.createElement('button');
      let btnRemove = document.createElement('button');
      BtnTooltip(btn, EdjsTooltips.image.main);
      BtnTooltip(btnRemove, EdjsTooltips.image.remove);


      // if(this._settings.bgColor){
      //   colorToggle.classList.add(this._CSS.colorToggle, this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.removeButton);
      //   colorToggle.innerHTML = `${PaletteIcon}`;
      //   this._accordion = textContainer;
      //   colorToggle.onclick = () => {
      //     if(this._accordion.querySelector('.colors-container')){
      //       this._colors.remove();
      //     }
      //     else{
      //       this._accordion.appendChild(this.showColors());
      //     }
      //   };
      //   textContainer.appendChild(colorToggle);
      // }

      if (this._data.url) {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon);
        btn.classList.add(this._CSS.replaceButton);
        btn.innerHTML = `${ImageIcon}`;
        btnRemove.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall);
        btnRemove.classList.add(this._CSS.removeButton, this._CSS.clear, this._CSS.xxlIcon);
        btnRemove.innerHTML = `${DeleteIcon}`;
      } else {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon);
        btn.innerHTML = `${ImageIcon}`;
      }

      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        const block = this.api.blocks.getBlockByIndex(blockIndex);
        this._settings.showMediaLibrary({
          details: block,
          data: this._data,
          bundle: 'image',
        });
      };

      btnRemove.onclick = () => {
        this._data.url = '';
        this._data.mid = '';
        this._data.width = '';
        this._data.height = '';
        this._data.x = '';
        this._data.y = '';
        this._data.maxWidth = '';
        this._data.maxHeight = '';

        this._element.remove();
        container.classList.remove('has-img')
        btnRemove.remove();
      }

      if (typeof img !== 'undefined') {
        container.appendChild(img);
        container.appendChild(btn);
        container.appendChild(btnRemove);
        container.classList.add('has-img')
      } else {
        // hidden input hack
        // prevents empty blocks being created
        let input = document.createElement('input');
        input.setAttribute('style', 'visibility: hidden; width: 0; height: 0; padding: 0; margin: 0; border: 0; position: absolute');

        container.appendChild(input);
        container.appendChild(btn);
      }
    } else {
      if (typeof img !== 'undefined') {
        container.appendChild(img);
        container.classList.add('has-img')
      }
    }

    headingContainer.appendChild(this._heading);
    subHeadingContainer.appendChild(this._subHeading);
    bodyRedactor.appendChild(this._body);
    bodyContainer.appendChild(bodyRedactor);

    // default heading
    headerRedactor.classList.add('heading-title');
    // default text container color
    textContainer.classList.add('blue');

    headerRedactor.appendChild(headingContainer);
    headerRedactor.appendChild(subHeadingContainer);
    headerRedactor.appendChild(accordToggle);
    headerContainer.appendChild(headerRedactor);
    textRedactor.appendChild(headerContainer);
    textRedactor.appendChild(bodyContainer);
    textContainer.appendChild(textRedactor);

    this._accordion = textContainer;

    accordToggle.addEventListener('click', () => this.toggleAccordion())

    container.appendChild(textContainer);

    return container;
  }

  save() {
    this._data.heading = this._heading.innerHTML;
    this._data.subHeading = this._subHeading.innerHTML;
    this._data.body = this._body.innerHTML;

    return this._data;
  }
}